import React, { createContext, useState } from "react";
import _R from "../../components/BaseUrl"


export const FilterContext = createContext(false)

export const FilterProvider = ({ children }) => {
    const [filter, setFilter] = useState('');


    const initialize = () => {
        setFilter('')
    }

    return (
        <FilterContext.Provider value={{ filter, setFilter, initialize }}>
            {children}
        </FilterContext.Provider>
    );
};


