import React, { useContext, useState } from 'react';
import TitleSection from '../../utils/TitleSection';
import FormCompleteStudent1 from './FormCompleteStudent1';
import FormCompleteStudent from './FormCompleteStudent';
import _R from '../../../../components/api';
import { AuthContext } from '../../../context/authContext';



export default function FormComplete(props) {

  const { setUser } = useContext(AuthContext)
  const [step, setStep] = useState(1)
  const [typeUser, setTypeUser] = useState('student')
  const [langue, setLangue] = useState(1)
  const [passTest, setPassTest] = useState(0)
  const [status, setStatus] = useState(0)
  const [times, setTimes] = useState(null)
  const [isUniv, setIsUniv] = useState(0)
  const [error, setError] = useState('')

  const handleConfirm = async () => {
    let form = {
      typeUser, status, langue, passTest, times, isUniv
    }
    const token = localStorage.getItem('ceil_token')

    try {
      const res = await _R.post('/complete-registration', form, {
        headers: {
          "X_CEIL_TOKEN": token
        }
      })

      console.log(form)
      localStorage.setItem('ceil_token', res.data._token)
      setUser(res.data.userInfo)
      props.setFinich()
    } catch (error) {
      setError(error.response.data.message)
      console.log(error.response.data)
    }

  }

  const handleChange = (type) => {
    console.log(type)
    switch (type.label) {
      case 'typeUser':
        setTypeUser(type.value)
        break;
      case 'langue':
        setLangue(type.value)
        break;
      case 'passTest':
        setPassTest(type.value)
        break;
      case 'status':
        setStatus(type.value)
        break;
      case 'times':
        setTimes(type.value)
        break;
      case 'isUnivStudent':
        setIsUniv(type.value)
        break;
      default:
        break;
    }
  }

  return (
    <>
      <div className=" sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          {/* title */}
          <TitleSection
            title={'Complete Registration'}
            desc={'Please choose carefully .'}
            titleTextColor="text-gray-600"
            descTextColor="text-gray-500"
          />
          <div className="mt-5 md:col-span-2 md:mt-0 text-gray-800">
            <form action="#" method="POST">
              <div className=" sm:rounded-sm mx-4">
                {
                  (step == 1) && (
                    <FormCompleteStudent onChange={handleChange} />
                  )}
                {
                  (step == 2) &&
                  (
                    <FormCompleteStudent1 error={error} onChange={handleChange} />
                  )
                }
                <div className={`px-4 py-3 text-right sm:px-6 flex ${step > 1 ? 'justify-between' : 'justify-end'}`}>
                  {(step > 1) && (<button
                    type="button"
                    onClick={() => setStep(step - 1)}
                    className="inline-flex justify-center rounded-md border border-transparent bg-yellow-400 py-3 px-10 text-sm font-medium text-white shadow-sm hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Prev
                  </button>)}
                  <button
                    type="button"
                    onClick={() => {
                      (step == 1) ? setStep(2) : handleConfirm()
                    }
                    }
                    className="inline-flex justify-center rounded-md border border-transparent bg-green-600 py-3 px-10 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Next
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
