import React, { createContext, useState } from "react";
import _R from "../../components/BaseUrl"


export const NotificationContext = createContext(false)

export const NotificationProvider = ({ children }) => {
    const [notification, setNotification] = useState(null);


    return (
        <NotificationContext.Provider value={{ notification, setNotification }}>
            {children}
        </NotificationContext.Provider>
    );
};


