import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useParams} from 'react-router-dom';
import FormComplete from './components/FormComplete';
import { AuthContext } from '../context/authContext';
import ReactToPrint from 'react-to-print';
import Ticket from './components/Ticket';
import { EditContext } from "../context/editContext"
import _R from '../../components/api';

function CompletePage() {
    
    const {user}=useContext(AuthContext)
    const componentRef = useRef()
    const [finich, setFinich] = useState(false)
    const [lastSession, setLastSession] = useState(null)
    const { me } = useContext(EditContext)
    const [thisInsc, setThisInsc] = useState()


    const {id} = useParams()

    useEffect(()=>{
        console.log(finich)
        _R.get('/getLastInscription',{params:{id}}).then((res)=>{
            setLastSession(res.data)
            //console.log(res.data)
        })
    },[])
    useEffect(()=>{
        if (user.student)
        _R.get('/actuInscription',{params:{id:user.user.student_id}}).then((res)=>{
            setThisInsc(res.data)
            //console.log(res.data)
        })
        },[])

    return (
        <main className="text-gray-800 px-2 py-5 max-w-5xl mx-auto">
            <div className=" text-center  p-4 border-b bg-gradient-to-r from-green-500 from-40% to-green-800 rounded-md ">
                <h1 className="h3 mb-2 text-white">Welcome Back ! </h1>
                <h1 className="text-md   text-gray-200 px-5">We has always accompanied students in their university course</h1>
            </div>
            <div className='text-gray-800 py-5'>
                {
                    !thisInsc && !finich ?
                        <FormComplete lastSession={lastSession}  typeDemand={true} setFinich={() => setFinich(true)} />
                        :
                        <div className='flex flex-col justify-center'>
                            <h1 className='text-4xl font-bold text-gray-800 text-center pb-5'>Congratulations!</h1>
                            <div className='text-center pb-5'>
                            <ReactToPrint
                            trigger={() => <button className=' btn-sm text-white bg-blue-800 hover:bg-blue-900'>Print</button>}
                            content={() => componentRef.current}
                           
                        />
                        {
                            <Ticket
                                ref={componentRef}
                                inscription_id={user.user.inscription_id}
                            />
                        }
                            </div>
                            <div >
                                <h4 className='text-xl font-bold text-gray-600 pb-5'>Students are required to provide the following documents:</h4>
                                <ul className='text-lg  text-gray-500 list-decimal'>
                                    <li className='pb-3 ml-5'>the registration voucher (Print it by the button above or check your email)</li>
                                    <li className='pb-3 ml-5'>02 Photos</li>
                                    <li className='pb-3 ml-5'>A copy of the registration certificate or student card</li>
                                    <li className='pb-3 ml-5'>A copy of the identity card.</li>
                                    <li className='pb-3 ml-5'>Registration fees</li>
                                </ul>
                            </div>
                        </div>
                }
            </div>
        </main>
    );
}

export default CompletePage;