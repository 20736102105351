import React, { useEffect, useRef, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import _R from "../../components/api";
import { GiArchiveRegister } from "react-icons/gi";
import {EditContext} from '../context/editContext'


const ShowCourse = () => {
    const { id } = useParams();
    const [course, setCourse] = useState(null);
    const {langues} = useContext(EditContext);


    useEffect(() => {
        _R.get('getCourse', { params: { id } }).then(c => {
            setCourse(c.data);
            console.log(course)
            console.log(langues)
        })
    }, [])

    return (

        course ?
            <div>
                <div className="section m-2 border border-green-300 rounded-lg"
                    style={{ background: 'linear-gradient(138deg, #0e9932 52%, #63e584 71%, #1ef956 100%)' }}>
                    <h5 className=" p-2 text-white font-bold text-lg mb-4">My group</h5>
                    <div className=" p-2 text-brown-300 rounded-tr-full	 bg-[#00000055] w-[80%] ">
                        <div className="text-sm">
                            <span className="font-bold">Name: </span>{course.group.name}
                        </div>
                        <div className="text-sm">
                            <span className="font-bold">Langue: </span>{langues.find(l=>l.value==course.group.langue_id)[0].label}
                        </div>
                        <div className="text-sm">
                            <span className="font-bold">Level: </span>{course.group.level_id}
                        </div>
                        <div className="text-sm">
                            <span className="font-bold">Time: </span>{course.group.time_id}
                        </div>
                    </div>

                </div>
                <div className="section m-2 border border-brown-300 rounded-lg "
                    style={{ background: 'linear-gradient(138deg, #99680e 52%, #e59963 71%, #f9831e 100%)' }}>
                    <h5 className="p-2 text-white font-bold text-lg mb-4">Teacher</h5>
                    <div className=" p-2 text-brown-300 rounded-tr-full	 bg-[#00000055] w-[80%] ">
                        <div className="text-sm">
                            <span className="font-bold">First Name: </span>{course.infoTeacher.firstname}
                        </div>
                        <div className="text-sm">
                            <span className="font-bold">Last Name: </span>{course.infoTeacher.lastname}
                        </div>
                        <div className="text-sm">
                            <span className="font-bold">Email: </span>{course.infoTeacher.email}
                        </div>
                        <div className="text-sm">
                            <span className="font-bold">Phone: </span>{course.infoTeacher.phone}
                        </div>
                    </div>

                </div>
                <div className="section m-2 border border-sky-300 rounded-lg p-2"
                    style={{ background: 'linear-gradient(138deg, #0e6699 52%, #63a3e5 71%, #1e8af9 100%)' }}>
                    <h5 className="text-white font-bold text-lg mb-4">Course</h5>
                    <div className="text-gray-700 flex p-2">
                        {
                            course.course.map(c => {
                                return (
                                    <div className="group text-sm w-44 border border-black rounded-md bg-white border-sky-500 overflow-hidden shadow-[0_0_10px_1px_#00000030] hover:shadow-[0_0_10px_2px_#00000080] transition-all">
                                        <div className="text-centre bg-red-800 group-hover:bg-red-800 p-4 mb-2 h-36 ease-out duration-300 transition-all "><GiArchiveRegister className='ease-out duration-300 transition-all text-[6rem] text-white text-center m-auto group-hover:text-[6.5rem]' /></div>
                                        <div className="p-2">
                                            <p className="font-bold text-xl mb-2"> {c.course.title}</p>
                                            <p className="font-bold text-right">{new Date(c.course.date).toLocaleDateString()}</p>
                                        </div>

                                    </div>
                                )
                            })
                        }
                    </div>

                </div>
            </div>
            :
            
            <div>
                Will be ready soon
            </div>
    )
}

export default ShowCourse