import React from "react"
import { Link } from "react-router-dom"
export const Block_course = ({ demand }) => {
    return (
        <div
            className="group md:w-48 w-full m-1 p-2 flex py-2 px-1 border border-gray-300 flex-col items-center shadow-[0_0_10px_-1px_#00000030] overflow-hidden bg-gradient-to-b from-sky-200 to-sky-700 rounded-lg hover:shadow-[0_0_10px_1px_#00000060] transition-all hover:cursor-pointer"
        >
            <Link to={`/en/me/${demand.id}`} className="w-full">
                <div className="h-32 relative w-full">
                    <div className="animate-wiggle rounded-2xl border-2 border-sky-400 p-1   absolute  right-0 top-6">
                        <div className="w-32 h-32 grid place-content-center overflow-hidden rounded-xl">
                            <img src={`./images/flags/${demand.langue}.png`} alt="" className="h-32" />
                        </div>
                    </div>
                </div>
                <div className="text-2xl font-bold text-white mt-[-35px] w-full pl-2 group-hover:text-lime-500 ">
                    {demand.level}
                </div>
            </Link>
        </div>
    )
}

export const Block_demand = ({ demand }) => {
    return (
        <div className="relative group md:w-48 w-full m-1 p-2 flex py-2 px-1 border border-gray-300 flex-col items-center shadow-[0_0_10px_-1px_#00000030] overflow-hidden bg-gradient-to-b from-yellow-300 to-pink-500 rounded-lg">
            <div className="h-32 relative w-full">
                <div className="rounded-2xl border-2 border-sky-400 p-1 rotate-[20deg] translate-y-[-65px] translate-x-[40px]  absolute  right-0">
                    <div className="w-32 h-32 grid place-content-center overflow-hidden rounded-xl">
                        <img src={`./images/flags/${demand.langue}.png`} alt="" className="h-32" />
                    </div>
                </div>
                <div className={`p-1 pl-2 rounded-[50px] text-sm font-bold text-white ${demand.status == '0' ? 'bg-green-500' : demand.status == '1' ? 'bg-red-500' : demand.status == '2' ? 'bg-yellow-500' :'bg-sky-500'}`}>
                    {demand.status == '0' ? 'Accepted' : demand.status == '1' ? 'Rejected' :demand.status == '2' ? 'Pending' : 'Completed'}
                </div>
            </div>
            <div className="text-3xl font-bold text-white mt-[-35px]">
                {demand.session}
            </div>
            {
                !(demand.status == '1') ?
                    <div className="hidden absolute top-0 left-0 w-full h-full group-hover:grid place-content-center bg-[#00000095] transition-all ">
                        <div className="p-2 text-white ">
                            <p>Date: <span>{demand.date}</span></p>
                            <p>Time: <span>{demand.time}</span></p>
                            {demand.details && <p>Level: <span>{demand.details.level}</span></p>}
                        </div>
                    </div>
                    :

                    <div className="hidden absolute top-0 left-0 w-full h-full group-hover:grid place-content-center bg-[#00000095] transition-all ">
                           {/* <Link to={`/me/${demand.id}`} className="w-full px-4 py-2 text-sm text-white bg-sky-600 hover:bg-sky-700">
                                more details...
                            </Link>*/}
                    </div>
            }
        </div>
    )
}