import React, { useState, useEffect,useContext } from "react";
import Dropd from "./utils/Dropd";
import Select from 'react-select';
import _R from "../../../components/BaseUrl";
import { EditContext } from '../../context/editContext'

const optionsTest = [
    {
        value: 0,
        label: 'No',
    }, {
        value: 1,
        label: 'Yes',
    }
]
const optionsTime = [
    {
        value: 1,
        label: '08:00 - 10:00',
    }, {
        value: 2,
        label: '10:00 - 12:00',
    }, {
        value: 3,
        label: '12:00 - 14:00',
    }, {
        value: 4,
        label: '14:00 - 16:00',
    }, {
        value: 5,
        label: '17:00 - 19:00',
    }, {
        value: 6,
        label: 'Saturday 08:00 - 15:30',
    }, {
        value: 7,
        label: 'Thursday 08:00 - 15:30',
    }, {
        value: 8,
        label: 'Saturday and Thursday 08:00 - 12:00',
    },
]

const options = [
    {
        value: 'student',
        label: 'Student',
    }
]

const optionsIsUnivS = [
    {
        value: 0,
        label: 'No',
    },
    {
        value: 1,
        label: 'Yes',
    }
]

const optionsStatus = [
    {
        value: 0,
        label: 'New Registration',
    },
    {
        value: 1,
        label: 'Re-registration',
    }
]

const FormCompleteStudent = (props) => {
    const { me,langues, setData } = useContext(EditContext);
    const [selectedOption, setSelectedOption] = useState(null);
    const [optionsLangue, setOptionsLangue] = useState([]);
    const handleSelection = (t) => {
        let _times = []
        t.forEach(element => {
            _times.push(element.value)
        });
        setSelectedOption(_times)
        setData({
            fields: [{
                name: 'times',
                value: _times
            }],
            model: 'demand'
        })
    }
    return (
        <>
            <div className="px-4 py-5 sm:p-6">
                <div className="grid grid-cols-6 gap-6">
                    <Dropd
                        label={'Are you a university student?'}
                        id="isUnivStudent"
                        name="isUnivStudent"
                        class={'sm:col-span-6'}
                        options={optionsIsUnivS}
                        //onChange={props.onChange}
                    />
                    <div class="col-span-6 sm:col-span-6 ">
                        <label class="mb-2 text-sm text-gray-400 ">* copy of registration certificate </label>
                        <input type="file" name="file" id="file" className="" onChange={(e) => {
                            setData({
                                fields: [{
                                    name: 'file',
                                    value: e.target.files[0]
                                }],
                                model: 'demand'
                            })
                            
                        }} />
                    </div>
                    {/*
                    <Dropd
                        label={'You are?'}
                        id="typeUser"
                        name="typeUser"
                        class={'sm:col-span-6'}
                        options={options}
                        onChange={props.onChange}
                    />
                    */
                        
                        <Dropd
                        label={'Type of registration'}
                        id="status"
                        name="status"
                        class={'sm:col-span-6'}
                        options={optionsStatus}
                        //onChange={props.onChange}
                        />
                        
                    }
                </div>
            </div>
            <div className="px-4 py-5 sm:p-6">
                <div className="grid grid-cols-6 gap-6">
                    {
                        !props.typeDemand &&
                        <>
                            <Dropd
                                label={'Choose a Langage'}
                                id="langue"
                                name="langue_id"
                                class={'sm:col-span-6'}
                                options={langues}
                                //onChange={props.onChange}
                            />
                            <Dropd
                                label={'Pass test'}
                                id="pessTest"
                                name="passTest"
                                class={'sm:col-span-6'}
                                options={optionsTest}
                                //onChange={props.onChange}
                            />
                        </>
                    }


                    <div className=" col-span-6 sm:col-span-6 relative">
                        <label htmlFor="" className="block text-sm font-medium text-gray-400">
                            Set Times ordered :
                        </label>
                        <Select
                            isMulti
                            className=" mt-1 rounded-sm  border-gray-200 bg-white  shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-md"
                            defaultValue={selectedOption}
                            onChange={handleSelection}
                            options={optionsTime}
                        />
                        <label htmlFor="" className=" absolute text-red-400 text-sm font-bold -bottom-5">{props.error}</label>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FormCompleteStudent;