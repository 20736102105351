import React from "react";

const Dropd = (props) => {
    return (<>
        <div className={` col-span-6 ${props.class} `}>
            {(props.options).length > 0 ?
                <>
                    <label htmlFor={props.name} className="block text-sm font-medium text-gray-400">
                        {props.label}
                    </label>
                    <select
                        name={props.name}
                        className="mt-1 block w-full rounded-sm  border-gray-200 bg-white  shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-md"
                        onChange={(e) => props.onChange({ label: props.name, value: e.target.value })}
                        // defaultValue={props.def}
                    >
                        <option key={10000} disabled selected value={null}>
                            choose one
                        </option>
                        {
                            props.options.map((option, index) => {
                                return (
                                    <option key={index} value={option.value}>
                                        <div className="flex text-gray-800">
                                            <div className="basis-2/3">
                                                {option.label}
                                            </div>
                                            <div className="basis-1/3">
                                                
                                            </div>

                                        </div>

                                    </option>
                                )
                            })
                        }
                    </select>
                </>
                :
                <p className="text-md font-medium text-gray-200">No results</p>
            }

        </div>
    </>
    )
}
export default Dropd;