import React from 'react'

function FooterAdmin() {
    return (
        <footer className='p-2 bg-slate-900 border-t border-slate-800'>
            <div className='text-center my-3 text-slate-700 text-sm'>
                <p className=' '>Copyright : <span className=' text-slate-600'>Ceil - univ - Msila</span></p>
                <p className=' '>Developed by: <span className=' text-slate-600 ' >Samir MAMMERI</span></p>
            </div>
        </footer>
    )
}

export default FooterAdmin