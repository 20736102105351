import { Box } from "@mui/material";
import { Modal } from "flowbite-react";
import React, { useEffect, useRef, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import _R from "../../components/api";
import Header from "../../components/Header";
import TextInfo from "../../components/TextInfo";
import StudentCard from "./pdf/StudentCard";
import Dropd from "../landing_page/utils/Dropd";
import Loading from "../layouts/Loading"
import { AuthContext } from "../context/authContext"

const ShowStudent = () => {
    const { user } = useContext(AuthContext);
    //const { Editable_data,setEdit } = useContext(AuthContext);
    const componentRef = useRef()
    const markRef = useRef()
    const { id } = useParams();
    const [student, setStudent] = useState(null)
    const [showModel, setShowModel] = useState(false)
    const [optionsLevel, setOptionsLevel] = useState([]);
    const [loading, setLoading] = useState(false);
    const [level, setLevel] = useState(1);
    const [edit, setEdit] = useState(false)


    useEffect(() => {
        _R.get('/alllevels').then((res) => {
            setOptionsLevel(res.data)
            console.log(res.data)
        })

    }, [])

    useEffect(() => {
        _R.get('/getStudent', { params: { id } }).then(response => {
            console.log(response.data)
            setStudent(response.data)
        })
    }, [])

    const setMarkk = () => {
        console.log(markRef.current.value)
        //setMark(markRef.current.value)
    }
    const handleEdit = () => {
        setEdit(false)
    }
    const handleSave = () => {
        setEdit(false)
    }

    const handleSetGroup = async () => {
        setLoading(true)
        const form = {
            inscription_id: id,
            value: student.inscription.inscription_type ? level : markRef.current.value,
            type: student.inscription.inscription_type ? 're' : 'new'
        }
        try {
            const result = await _R.post('/setGroup', form)
            window.location.reload()
        } catch (error) {
            console.log(error.response.data)
        }
    }

    return (
        student && !loading ?
            <div className="m-5">
                <div className=" bg-slate-800 p-3 border-l border-t border-slate-700 shadow-[2px_2px_5px_1px_#00000040]">
                    <Header title={'Student Information'} subtitle={`student information`} />
                    <div className="text-right">
                        <ReactToPrint
                            trigger={() => <button className=' btn-sm text-white bg-blue-800 hover:bg-blue-900'>Print</button>}
                            content={() => componentRef.current}
                        />
                        {
                            edit ?
                                <button className='ml-4 btn-sm text-white bg-red-800 hover:bg-red-900' onClick={() => handleSave()}>Save</button>
                                :
                                <button className='ml-4  btn-sm text-white bg-green-800 hover:bg-green-900' onClick={() => handleEdit()}>Edit</button>
                        } 
                    </div>
                </div>

                <div className="  mt-10 px-3 overflow-auto max-w-7xl mx-auto">
                    <div className="m-2">

                        {student &&
                            <StudentCard
                                ref={componentRef}
                                student={
                                    {
                                        name: `${student.user.firstname} ${student.user.lastname}`,
                                        picture: student.user.picture,
                                        language: student.student.langue,
                                        address: student.user.address,
                                        birthday: student.user.birthday,
                                        qr: student.user.email
                                    }
                                }
                            />
                        }
                    </div>
                    <h6 className="text-lg text-green-500 m-2 mt-4 font-bold">General informations </h6>

                    <div className="flex-1">
                        <div className="flex flex-col  lg:flex-row ">
                            <TextInfo model={'student'} name={'firstname'} edit={edit} label={'First Name'} value={student && student.user.firstname} flex={'basis-1/4'} />
                            <TextInfo model={'student'} name={'lastname'} edit={edit} label={'Last Name'} value={student && student.user.lastname} flex={'basis-1/4'} />
                            <TextInfo label={'Email'} value={student && student.user.email} flex={'basis-2/4'} />
                        </div>
                        <div className="flex flex-col lg:flex-row">
                            <TextInfo label={'Birthday'} value={student && student.user.birthday} flex={'basis-1/3'} />
                            <TextInfo label={'Birthplace'} value={student && student.user.birthplace} flex={'basis-1/3'} />
                            <TextInfo label={'Phone'} value={student && student.user.phone} flex={'basis-1/3'} />
                        </div>
                        <div className="flex flex-col lg:flex-row">
                            <TextInfo model={'student'} name={'address'} edit={edit} label={'Address'} value={student && student.user.address} flex={' basis-1/1 w-full'} />
                        </div>
                    </div>
                    <h6 className="text-lg text-green-500 m-2 mt-5 font-bold">Student informations </h6>
                    <div className="flex flex-col pb-3 lg:flex-row">
                        <div className="flex-1">
                            <div className="flex flex-col lg:flex-row  items-center">
                                <TextInfo label={'Langue'} value={student && student.student.langue} flex={'basis-1/4'} />
                                {
                                    student && student.student.level ? (
                                        <>
                                            <TextInfo label={'Level'} value={student.student.level} flex={'basis-1/4'} />
                                            <TextInfo label={'Time'} value={student.student.time} flex={'basis-2/4'} />
                                            <TextInfo label={'Group'} value={student.student.group} flex={'basis-1/4'} />
                                        </>
                                    ) :
                                        student && student.inscription.inscription_type ?
                                            (
                                                <div className={` p-1 flex-initial`}>
                                                    <button onClick={() => setShowModel(true)} className="btn bg-green-600 hover:bg-green-700  basis-1/5">Set Level</button>
                                                </div>
                                            ) : (
                                                <div className={` p-1 flex-initial`}>
                                                    <button onClick={() => setShowModel(true)} className="btn bg-green-600 hover:bg-green-700  basis-1/5">Set Test Mark</button>
                                                </div>
                                            )
                                }
                            </div>
                        </div>
                    </div>
                    {student && student.result && student.result.written_pass_test == 1 &&
                        <div>
                            <h6 className="text-lg text-green-500 m-2 mt-5 font-bold">Results </h6>
                            <div className="flex flex-col pb-3 lg:flex-row">
                                <div className="flex-1">
                                    <div className="flex flex-col lg:flex-row ">
                                        <TextInfo label={'Oral mark'} value={student.result.oral_mark} flex={'basis-1/4'} />
                                        <TextInfo label={'Written mark'} value={student.result.written_mark} flex={'basis-1/4'} />
                                        <TextInfo label={'Avis'} value={student.result.avis} flex={'basis-1/4'} />
                                        <TextInfo label={'Observation'} value={student.result.observation} flex={'basis-1/4'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {user.role == 'admin' &&
                        <div>
                            <h6 className="text-lg text-green-500 m-2 mt-5 font-bold">Track operations on this user </h6>
                            <div>
                                <table className="tache">
                                    <thead className="bg-slate-800 text-left text-slate-500">
                                        <th className="m-1 p-2">N</th>
                                        <th className="m-1 p-2">Description</th>
                                        <th className="m-1 p-2">Firstname</th>
                                        <th className="m-1 p-2">Lastname</th>
                                        <th className="m-1 p-2">Email</th>
                                        <th className="m-1 p-2">Operation date</th>
                                        <th className="m-1 p-2">Operation time</th>
                                    </thead>
                                    <tbody>
                                        {

                                            student && student.taches &&
                                            student.taches.map((tache, index) => {
                                                return (
                                                    <tr className="bg-slate-900">
                                                        <td className="m-1 p-2">{index + 1}</td>
                                                        <td className="m-1 p-2">{tache.description}</td>
                                                        <td className="m-1 p-2">{tache.user.firstname}</td>
                                                        <td className="m-1 p-2">{tache.user.lastname}</td>
                                                        <td className="m-1 p-2">{tache.user.email}</td>
                                                        <td className="m-1 p-2">{new Date(tache.created_at).toLocaleDateString()}</td>
                                                        <td className="m-1 p-2">{new Date(tache.created_at).toLocaleTimeString()}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>}
                </div>
                <>
                    <Modal
                        show={showModel}
                        size="md"
                        popup={true}
                        onClose={() => setShowModel(false)}
                    >
                        {<Modal.Header className="bg-slate-800" />}
                        <Modal.Body className="bg-slate-700 text-gray-400">
                            <div className=" space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8">

                                <h3 className="text-lg font-medium pt-5 ">
                                    {
                                        student && student.inscription.inscription_type ?
                                            `Select the level and let the application determine the group and time according to the chosen time.`
                                            :
                                            `Set mark and let the application determine the level ,the group and the time according to the chosen time.`
                                    }
                                </h3>
                                <div>
                                    {
                                        student && student.inscription.inscription_type ?
                                            <Dropd
                                                label={'Choose a Langage'}
                                                id="langue"
                                                name="langue"
                                                class={'sm:col-span-6 text-gray-400'}
                                                options={optionsLevel}
                                                onChange={(e) => setLevel(e.value)}
                                            />
                                            :
                                            <input
                                                type="text"
                                                ref={markRef}
                                                onChange={setMarkk}
                                                className="p-2 w-full border border-slate-200 "
                                                name="mark"
                                                id="mark"
                                                placeholder="Mark"
                                            />
                                    }

                                </div>
                                <div className="w-full text-right">
                                    <button onClick={() => handleSetGroup()} className="btn bg-green-600 text-gray-100 hover:bg-green-700 ">
                                        Validate
                                    </button>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </>
            </div>
            :

            <div>
                <Loading />
            </div>
    )
}
export default ShowStudent; 