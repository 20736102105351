import React from "react";
const TitleSection = (props)=>{
    return (
        <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className={ ` text-2xl font-medium leading-6 ${props.titleTextColor}`}>{props.title}</h3>
                <p className={ ` mt-1 text-l py-2 ${props.descTextColor}`}>{props.desc}</p>
              </div>
            </div>
    )
}
export default TitleSection;