import React, { useState, useContext } from 'react'
import Header from "../../components/Header";
import { SettingsContext } from "../context/settingsContext"
import _R from "../../components/BaseUrl"


const UpdateElementor = () => {

  const { settings, _setSettings } = useContext(SettingsContext)
  const [h_eader, setH_eader] = useState([])
  const [main, setMain] = useState([])

  const handlesave = () => {
    console.log(JSON.stringify(settings))
    console.log(settings)

    _R.post('/setSettings', settings).then((res) => {
      console.log(res.response)
    })
  }
  const handleChange = (e) => {
    let inp = {
      name: e.target.name,
      value: e.target.files ? e.target.files[0] : e.target.value
    }
    _setSettings(e.target.getAttribute('data-model'), inp.name, inp.value)

  }

  return (
    <div className='m-5'>
      <Header title="Appearance" subtitle="Edit officiel website .." />
      {
        //--------------------------------------------------------------------
      }
      <div>
        <h6 className="text-lg text-green-500 m-2 mt-4 font-bold">Header</h6>
        <div className='flex text-black'>
          <div>
            <input onChange={(e) => { handleChange(e) }} type="file" name="header-logo" data-model='header' />
          </div>
          <div>
            <input onChange={(e) => { handleChange(e) }} type="text" name="header-text1" data-model='header' />
          </div>
          <div>
            <input onChange={(e) => { handleChange(e) }} type="text" name="header-text2" data-model='header' />
          </div>
        </div>
      </div>
      {
        //--------------------------------------------------------------------
      }
      <div>
        <h6 className="text-lg text-green-500 m-2 mt-4 font-bold">Main</h6>
        <div>
          <h6 className="text-md text-green-600 m-4 mt-4 font-bold">Video</h6>
          <input type="file" name="logo" id="logo" />
        </div>
        <div>
          <h6 className="text-md text-green-600 m-4 mt-4 font-bold">Sliders</h6>

          <input type="file" name="logo" id="logo" />
        </div>
        <div>
          <h6 className="text-md text-green-600 m-4 mt-4 font-bold">FeaturesBlocks</h6>
          <input type="text" name="titleBlock" id="titleBlock" />
          <input type="text" name="contentBlock" id="contentBlock" />
        </div>
        <div>
          <h6 className="text-md text-green-600 m-4 mt-4 font-bold">FeaturesZigZag</h6>
          <input type="text" name="titleZigZag" id="titleZigZag" />
          <input type="text" name="contentZigZag" id="contentZigZag" />
        </div>
        <div>
          <h6 className="text-md text-green-600 m-4 mt-4 font-bold">Testimonials</h6>
          <input type="file" name="pictureTestimonials" id="pictureTestimonials" />
          <input type="text" name="titleTestimonials" id="titleTestimonials" />
          <input type="text" name="contentTestimonials" id="contentTestimonials" />
        </div>
      </div>
      {
        //--------------------------------------------------------------------
      }
      <div className='p-5 m-4'>
        <button
          className='py-2 px-10 bg-green-600 hover:bg-green-700 text-white text-sm shadow-sm rounded-sm'
          type="button"
          onClick={() => handlesave()}>
          Update
        </button>
      </div>
    </div>
  )
}

export default UpdateElementor