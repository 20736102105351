import React, { useEffect, useState,useContext } from 'react'
import Header from "../../components/Header";
import _R from '../../components/BaseUrl'
import { useParams } from "react-router-dom";
import Loading from "../layouts/Loading"
import { AuthContext } from "../context/authContext"

const criteres = [
    {
        speaking_mark: 20,
        reading_mark: 10,
        listening_mark: 0,
        written_mark: 70
    },
    {
        speaking_mark: 20,
        reading_mark: 10,
        listening_mark: 20,
        written_mark: 50
    }
]



const ShowExamain = () => {
    const { user } = useContext(AuthContext);

    const { id } = useParams();
    const [examain, setExamain] = useState(null)
    const [loading, setLoading] = useState(true)
    const [update, setUpdate] = useState([])
    const [max, setMax] = useState(0)
    const [error, setError] = useState('')

    useEffect(() => {
        _R.get('/getExamain', { params: { id } }).then(resp => {
            setExamain(resp.data)
            setMax(resp.data.level_id == 1 ? criteres[0] : criteres[1])
            setLoading(false)
        })
    }, [])

    const handleChange = (e) => {

        console.log(e.target.value)
        let up = [...update]
        up[e.target.name] = e.target.value
        setUpdate(up)

    }

    const handleSave = (type) => {
        if (max[type] < update[type]) {
            setError('error !')
        } else {
            let url = "/setExamain"
            let data = update[type]
            try {
                _R.post(url, { id: examain.id, type, data }).then(resp => {
                    setExamain(resp.data)
                })
            } catch (error) {
                console.log(error)
            }
        }


    }

    return (
        examain && !loading ?
            <div className='m-10 p-8 overflow-auto h-[calc(100vh_-_150px)] '>
                <Header title={'Examain Information'} />
                <div>
                    <h3 className='text-xl font-[600]'>{examain.user.firstname} {examain.user.lastname}</h3>
                </div>
                <div className="p-6 bg-[#00000030]">
                    <div className="p-4 ">
                        <div className='flex'>
                            <div className="child p-2">
                                <p className='mb-2'><span className='font-bold text-2xl'>Oral Mark: </span><span className='text-2xl font-[900] text-yellow-500'>{examain.oral_mark ? examain.oral_mark : 'not yet'}</span></p>
                                <div className='pl-6'>
                                    <div className="child p-2">
                                        <p className='mb-2'><span className=''>Speaking Mark: </span><span className='text-2xl font-[900] text-yellow-500'>{examain.speaking_mark ? examain.speaking_mark : 'not yet'}</span></p>
                                        <div className="flex">
                                            <input className='text-green-800' type="text" name="speaking_mark" id="speaking-mark" onChange={(e) => handleChange(e)} max={max.speaking_mark} />
                                            <button className='p-2 bg-green-500 border-none text-white hover:bg-green-600' onClick={() => handleSave('speaking_mark')}>Set mark</button>
                                        </div>
                                    </div>
                                    <div className="child p-2">
                                        <p className='mb-2'><span className=''>reading Mark: </span><span className='text-2xl font-[900] text-yellow-500'>{examain.reading_mark ? examain.reading_mark : 'not yet'}</span></p>
                                        <div className="flex">
                                            <input className='text-green-800' type="text" name="reading_mark" id="reading-mark" onChange={(e) => handleChange(e)} />
                                            <button className='p-2 bg-green-500 border-none text-white hover:bg-green-600' onClick={() => handleSave('reading_mark')}>Set mark</button>
                                        </div>
                                    </div>
                                    <div className="child p-2">
                                        <p className='mb-2'><span className=''>Listening Mark: </span><span className='text-2xl font-[900] text-yellow-500'>{examain.listening_mark ? examain.listening_mark : 'not yet'}</span></p>
                                        <div className="flex">
                                            <input className='text-green-800' type="text" name="listening_mark" id="listening-mark" onChange={(e) => handleChange(e)} />
                                            <button className='p-2 bg-green-500 border-none text-white hover:bg-green-600' onClick={() => handleSave('listening_mark')}>Set mark</button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="child p-2">
                                <p className='mb-2'><span className='font-bold text-2xl'>Written Mark: </span><span className='text-2xl font-[900] text-yellow-500'>{examain.written_mark ? examain.written_mark : 'not yet'}</span></p>
                                <div className='pl-6'>
                                    <div className="child p-2">
                                        <p className='mb-2'><span className=''>Written Mark: </span></p>
                                        <div className="flex">
                                            <input className='text-green-800' type="text" name="written_mark" id="written-mark" onChange={(e) => handleChange(e)} />
                                            <button className='p-2 bg-green-500 border-none text-white hover:bg-green-600' onClick={() => handleSave('written_mark')}>Set mark</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="child p-2">
                                <p className='mb-2'><span className='font-bold text-2xl'>Evaluation Mark: </span><span className='text-2xl font-[900] text-yellow-500'>{examain.evaluation_mark ? examain.evaluation_mark : 'not yet'}</span></p>
                                <div className='pl-6'>
                                    <div className="child p-2">
                                        <p className='mb-2'><span className=''>Evaluation Mark: </span></p>
                                        <div className="flex">
                                            <input className='text-green-800' type="text" name="evaluation_mark" id="evaluation-mark" onChange={(e) => handleChange(e)} />
                                            <button className='p-2 bg-green-500 border-none text-white hover:bg-green-600' onClick={() => handleSave('evaluation_mark')}>Set mark</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex'>
                            <p className='mb-2'><span className='font-bold text-2xl'>Total: </span><span className='text-2xl font-[900] text-yellow-500'>{examain.result ? examain.result : 'not yet'}</span></p>
                            <p className='mb-2'><span className='font-bold text-2xl'>Result: </span><span className={`text-2xl font-[900] ${examain.result >= 50 ? 'text-green-400' : 'text-red-400'}`}>{examain.result >= 50 ? 'Pass' : 'not pass'}</span></p>
                            <p className='mb-2'><span className='font-bold text-2xl'>Observation: </span><span className='text-2xl font-[900] text-yellow-500'>{examain.observation ? examain.observation : 'not yet'}</span></p>
                        </div>
                    </div>
                    
                </div>
                {user.role == 'admin' &&
                        <div>
                            <h6 className="text-lg text-green-500 m-2 mt-5 font-bold">Track operations on this user </h6>
                            <div>
                                <table className="tache">
                                    <thead className="bg-slate-800 text-left text-slate-500">
                                        <th className="m-1 p-2">N</th>
                                        <th className="m-1 p-2">Description</th>
                                        <th className="m-1 p-2">Email</th>
                                        <th className="m-1 p-2">Operation date</th>
                                        <th className="m-1 p-2">Operation time</th>
                                    </thead>
                                    <tbody>
                                        {

                                            examain && examain.traces &&
                                            examain.traces.map((tache, index) => {
                                                return (
                                                    <tr className="bg-slate-900">
                                                        <td className="m-1 p-2">{index + 1}</td>
                                                        <td className="m-1 p-2">{tache.description}</td>
                                                        <td className="m-1 p-2">{tache.email}</td>
                                                        <td className="m-1 p-2">{tache.date}</td>
                                                        <td className="m-1 p-2">{tache.time}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>}

            </div>
            :
            
        <div>
        <Loading />
        </div>
    )
}

export default ShowExamain