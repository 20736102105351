import React, { useState, useContext, useEffect } from 'react'
import Header from "../../components/Header";
import _R from '../../components/api'
import { SettingsContext } from '../context/settingsContext';
import { EditContext } from "../context/editContext"
import { sliders_path, app_path, images_path } from '../../paths';


const Elementor = () => {
  const { items, setItems } = useContext(SettingsContext)
  const { editable_data, initialize, setData } = useContext(EditContext)
  useEffect(() => {
    _R.get('/settings'/*, { params: { parsed } }*/).then((response) => {
      setItems(response.data)
      //setLoading(false)
    })
    initialize()
  }, [])
  //------------
  const createForm = () => {
    return new Promise(res => {
      let formData = new FormData()

      let promises = []
      if (editable_data) {
        editable_data.fields.forEach(f => {
          promises.push(
            new Promise((resolve, reject) => {
              formData.append(f.name, f.value)
              resolve()
            })
          )
        })
      }
      Promise.all(promises).then(() => {
        res(formData)
      })
    })
  }
  //------------
  const handlesave = async () => {
    let form = await createForm()
    form.append('section', "slider")
    console.log(form)
    let res = await _R.post('/updateAct', form)
  }
  const handleChange = (e, type = "text") => {
    setData({
      fields: [{
        name: e.target.name,
        value: type == "text" ? e.target.value : e.target.files[0]
      }]
    })
  }
  return (
    <div className='m-5'>
      <Header title="Appearance" subtitle="Edit officiel website .." />
      {
        //--------------------------------------------------------------------
        items &&
        <div style={{
          height: 'calc(100vh - 227px)',
          overflow: 'auto',
          boxShadow: 'inset 0px 20px 23px 10px #00000020'
        }}>
          <div className=''>
            <h6 className="text-lg text-green-500 m-2 mt-4 font-bold">Header</h6>
            <div className='flex'>
              <div className='border p-4 m-1 border-slate-800 basis-1/3 bg-slate-800'>
                <h6 className='text-md text-green-600  mt-4 font-bold'>Logo:</h6>
                <img src={`/en/images/${items.header[2].content}`} alt="" className='max-w-xs bg-black m' />
                <input onChange={(e) => { handleChange(e, "file") }} type="file" name="header-logo" />
              </div>
              <div className='border p-4 m-1 border-slate-800  basis-1/3 bg-slate-800 grid'>
                <h6 className='text-md text-green-600 mt-4 font-bold'>Text1:</h6>
                <h6 className='text-md  mt-2 font-bold'>{items.header[0].content}</h6>
                <p className='text-gray-300 mt-2'>update :</p>
                <input onChange={(e) => { handleChange(e) }} type="text" name="header-text1" className='text-black' />
              </div>
              <div className='border p-4 m-1 border-slate-800  basis-1/3 bg-slate-800 grid'>
                <h6 className='text-md text-green-600 mt-4 font-bold'>Text2:</h6>
                <h6 className='text-md mt-2 font-bold'>{items.header[1].content}</h6>
                <p className='text-gray-300 mt-2'>update :</p>
                <input onChange={(e) => { handleChange(e) }} type="text" name="header-text2" className='text-black w-full' />
              </div>
            </div>
          </div>
          {
            //--------------------------------------------------------------------
          }
          <div className=''>
            <h6 className="text-lg text-green-500 m-2 mt-4 font-bold">Main</h6>
            <div className='border p-2 m-1 border-slate-800'>
              <h6 className="text-md text-green-600 m-4 mt-4 font-bold">Video</h6>
              <video style={{
                height: '100px',
                background: 'black'
              }} controls>
                <source src={`${images_path}${items.main.video[0].content}`} type="video/mp4" />
              </video>
              <input onChange={(e) => { handleChange(e, "file") }} type="file" name="video" id="video" />
            </div>
            <div className='border p-2 m-1 border-slate-800'>
              <h6 className="text-md text-green-600 m-4 mt-4 font-bold">Sliders</h6>
              <div className='flex mb-2'>
                {
                  items.main.slider.map(item => {
                    return (
                      <img className='m-1 basis-1/6 border border-slate-700' src={`${sliders_path}${item.content}`} alt="" width='150' />

                    )
                  }
                  )
                }
              </div>
              <div className='m-4 max-w-2xl bg-slate-800 p-4'>
                <h6 className="text-xs text-green-700 font-bold">New Slider:</h6>
                <div>
                  <div className='flex justify-between '>
                  <h6 className="text-xs text-gray-400  mt-4 font-bold">Description</h6>
                  <h6 className="text-xs text-gray-400  mt-4 font-bold">الوصف</h6>
                  </div>
                  <input onChange={(e) => { handleChange(e) }} type="text" name="slider_description" 
                  className=' w-full bg-transparent border border-slate-600 rounded mt-2' />
                </div>
                <div>
                  <h6 className="text-xs text-gray-400  mt-4 font-bold">New Post</h6>
                  <input onChange={(e) => { handleChange(e) }} type="text" name="slider_post" className=' w-full bg-transparent border border-slate-600 rounded mt-2asdsdsdf' />
                </div>
                <div>
                  <h6 className="text-xs text-gray-400  mt-4 font-bold">Image</h6>
                  <input onChange={(e) => { handleChange(e, "file") }} type="file" name="slider_logo" id="logo"
                  className='hidden' />
                  <button className='mt-2 p-3 bg-green-800 text-white border border-green-600 mb-8 hover:bg-green-500 transition-all' onClick={()=>{
                    document.getElementById('logo').click();
                  }}>Upload file</button>
                </div>
              </div>

            </div>
            {/*
        <div>
          <h6 className="text-md text-green-600 m-4 mt-4 font-bold">FeaturesBlocks</h6>
          <input type="text" name="titleBlock" id="titleBlock" />
          <input type="text" name="contentBlock" id="contentBlock" />
        </div>
        <div>
          <h6 className="text-md text-green-600 m-4 mt-4 font-bold">FeaturesZigZag</h6>
          <input type="text" name="titleZigZag" id="titleZigZag" />
          <input type="text" name="contentZigZag" id="contentZigZag" />
        </div>
        <div>
          <h6 className="text-md text-green-600 m-4 mt-4 font-bold">Testimonials</h6>
          <input type="file" name="pictureTestimonials" id="pictureTestimonials" />
          <input type="text" name="titleTestimonials" id="titleTestimonials" />
          <input type="text" name="contentTestimonials" id="contentTestimonials" />
        </div>
*/}
          </div>
          {
            //--------------------------------------------------------------------
          }
          <div className='m-1 text-center'>
            <button className=' max-w-sm w-full p-3 bg-green-800 text-white border border-green-600 mb-8 hover:bg-green-500 transition-all' onClick={() => handlesave()}>Update</button>
          </div>
        </div>
      }
    </div>
  )
}

export default Elementor