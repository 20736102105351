

import { createContext, useState, useMemo, useContext } from "react";
import { createTheme } from '@mui/material/styles'


export const tokens = (mode) => ({
    ...(mode === 'dark' ?
        {

            primary: {
                100: "#d4d6dc",
                200: "#a9adb9",
                300: "#7e8596",
                400: "#535c73",
                500: "#283350",
                600: "#202940",
                700: "#181f30",
                800: "#101420",
                900: "#080a10"
            },

        }
        :
        {

            primary: {
                100: "#080a10",
                200: "#101420",
                300: "#181f30",
                400: "#202940",
                500: "#283350",
                600: "#535c73",
                700: "#7e8596",
                800: "#a9adb9",
                900: "#d4d6dc"
            }
        })
})

export const themeSettings = (mode) => {
    const colors = tokens(mode)

    return {
        palette: {
            ...(mode === "dark" ? {
                text: {
                    header: colors.primary[200],
                    main: colors.primary[400],
                    sidebar: colors.primary[200],
                    sidebarSub: colors.primary[400],
                    footer: colors.primary[400],
                },
                background: {
                    header: colors.primary[600],
                    main: colors.primary[500],
                    sidebar: colors.primary[600],
                    footer: colors.primary[400],
                    sidebarHead:colors.primary[800],
                    menuItem:colors.primary[800]
                }
            } : {
                text: {
                    header: colors.primary[200],
                    main: colors.primary[400],
                    sidebarSub: colors.primary[500],
                    sidebar: colors.primary[400],
                    footer: colors.primary[400],
                },
                background: {
                    header: colors.primary[900],
                    main: colors.primary[800],
                    sidebar: colors.primary[900],
                    footer: colors.primary[800],
                    sidebarHead:colors.primary[800],
                    menuItem:colors.primary[800]
                }
            })
        }
    }
}

const ColorModeContext = createContext()

export const ColorModeProvider = ({ children }) => {
    const [mode, setMode] = useState("dark")
    const toggleColorMode = () => setMode((prev) => (prev === "light" ? "dark" : "light"))
    const colorMode = useMemo(
        () => ({
            toggleColorMode: () => setMode((prev) => (prev === "light" ? "dark" : "light"))
        }), []
    )

    return (
        <ColorModeContext.Provider value={{ mode, toggleColorMode }}>
            {children}
        </ColorModeContext.Provider>)
}

export const useMode = () => useContext(ColorModeContext)
