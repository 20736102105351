import { Box } from "@mui/material";
import Header from "../../components/Header";
import { Link } from "react-router-dom"
import React, { useEffect, useState, useCallback, useContext } from "react";
import Table from "../../components/Table";
import _R from "../../components/api";
import Loading from "../layouts/Loading";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { FilterContext } from "../context/filterContext"
import { NotificationContext } from "../context/notificationContext"
import Notification from "../global/Notification";

const Users = (props) => {
    const { notification, setNotification } = useContext(NotificationContext)
    const { filter } = useContext(FilterContext)
    const [noode, setNoode] = useState(null)
    const [filterUsers, setFilterUsers] = useState(null)
    const columns = [
        { field: "id", use: "ID", type: "integer" },
        { field: "firstname", use: "First Name", type: "string" },
        { field: "lastname", use: "Last Name", type: "string" },
        { field: "email", use: "Email", type: "string", color: "text-yellow-300" },
        //{ field: "phone", use: "Phone", type: "string" },
        //{ field: "birthday", use: "Birthday", type: "date" },
        //{ field: "birthplace", use: "Birthplace", type: "string" },
        //{ field: "address", use: "Address", type: "string" },
        { field: "idc1", use: "Facade1", type: "file" },
        { field: "idc2", use: "Facade2", type: "file" },
        { field: "document_status", use: "Account?", type: "list" },
        { field: "role", use: "Role", type: "string" },
        { field: "created_at", use: "Last UPDATE", type: "string" },
        { field: "action", use: "Action", type: "button" }
    ]
    const measuredRef = useCallback(node => {
        if (node !== null) {
            setNoode(node);
        }
    }, []);
    useEffect(() => {
        let isMounted = true
        let controller = new AbortController()
        _R.get('/getUsers', { signal: controller.signal }).then((res) => {
            console.log(res.data)
            isMounted && setFilterUsers(res.data)
        })
        return () => {
            isMounted = false
            controller.abort()

        }
    }, [])


    return (
        filterUsers ?
            <Box m="20px">
                <Header title="USERS" subtitle="Manage all users .." />
                <Box textAlign="right">
                    <Link to="/en/admin/users/add" className="btn bg-green-600 hover:bg-green-700 p-1 text-green-100 text-md mx-2">
                        Add user
                    </Link>
                    <DownloadTableExcel
                        filename="users table"
                        sheet="users"
                        currentTableRef={noode}
                    >
                        <button className=" btn bg-blue-600 hover:bg-blue-700 p-1 text-blue-100 text-md"> Export excel </button>
                    </DownloadTableExcel>
                </Box>
                <div ref={measuredRef}>
                    <Table
                        model='users'
                        rows={filterUsers}
                        columns={columns}
                        setRows={setFilterUsers}
                        filter={filter}
                        action={[
                            {
                                "button": "view",
                                "link": '/en/admin/users/show/'
                            }, {
                                "button": "approuve",
                                "link": '/en/admin/users/show/'
                            }

                        ]}
                    />
                </div>
                {
                    notification &&
                    <Notification
                        info={notification}
                    />
                }
            </Box> :

            <div>
                <Loading />
            </div>
    )
}

export default Users
