import { IconButton } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { BsFillTicketPerforatedFill, BsFillSendFill } from "react-icons/bs";

const criteres = [
    {
        speaking_mark: 20,
        reading_mark: 10,
        listening_mark: 0,
        written_mark: 70
    },
    {
        speaking_mark: 20,
        reading_mark: 10,
        listening_mark: 20,
        written_mark: 50
    }
]

export default class Table extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            filterType: 1,
            setPrint: false
        }
    }


    render() {
        const set_rows = (row, column, index) => {
            if (this.props.model && this.props.model == 'examain' && !row['examin'])
                return
            if (this.props.model && this.props.model == 'examain') {
                if (column.field === 'action') {
                    return (
                        <td className="border border-slate-600 px-2 py-1 text-left">
                            {
                                this.props.action.map((act,index) =>
                                (
                                    <Link key={index}  to={`${act.link}${row['inscription_id']}`} target="_blank">
                                        <IconButton
                                            color={`info`} >
                                            <VisibilityOutlinedIcon />
                                        </IconButton>
                                    </Link>
                                )
                                )
                            }
                        </td>
                    )
                }
                if (column.subfield != "") {
                    if (row[column.field]) {
                        if (column.subfield == "avis") {
                            return <td className={`border border-slate-500 px-4 py-1 text-left text-md ${row['examin']['result'] >= 50 ? 'text-green-400' : 'text-red-400'}`}>
                                {
                                    (row['examin']['result'] >= 50 ? 'pass' : 'Not pass')
                                }
                            </td>
                        }
                        return <td className={`border border-slate-500 px-4 py-1 text-left text-md ${column.color ? column.color : 'text-slate-300'}`} contentEditable={['speaking_mark', 'reading_mark', 'listening_mark', 'written_mark', 'evaluation_mark'].includes(column.subfield)} onBlur={(e) => this.props.change(e, row['examin']['id'], column.subfield, index)}>
                            {
                                (row['examin'][column.subfield])
                            }
                        </td>
                    }
                    return
                }
                if (column.field === 'inscription_id')
                    return
                return <td className={`border border-slate-600 px-4 py-1 text-left ${column.color ? column.color : 'text-slate-300'}   text-md `}>
                    {
                        column.field == 'id' ? (index + 1) : (row[column.field] ? row[column.field] : 'Not yet')
                    }
                </td>
            }
            if (column.type === 'list') {
                return <td className={`border border-slate-600 px-4 py-1 text-left ${row[column.field] == 'Pending' ? 'text-yellow-500' : row[column.field] == 'Rejected' ? 'text-red-500' : 'text-green-500'} text-md `}>
                    {
                        row[column.field]
                    }
                </td>
            }
            if (column.type === 'boolean') {
                return <td className="border border-slate-600 px-4 py-1 text-left text-slate-400 text-lg">
                    {
                        row[column.field] ?
                            <span className=" text-xs bg-green-700 border-green-500 rounded-lg px-2 py-1 bg-opacity-60">Yes</span>
                            :
                            <span className=" text-xs bg-red-700 border-red-500 rounded-lg px-2 py-1 bg-opacity-60">No</span>
                    }
                </td>
            }
            if (column.field === 'action') {
                if (this.props.model == 'users') {
                    return (
                        <td className="border border-slate-600 px-2 py-1 text-left l">
                            {
                                this.props.action.map((act,index) =>
                                (
                                    <Link key={index}  to={`${act.link}${row.id}`} target="_blank">
                                        <IconButton color={(act.button == 'approuve') ? `success` : `info`} >
                                            {//act.button == 'approuve' && <BsFillSendFill />
                                            }
                                            {act.button == 'view' && <VisibilityOutlinedIcon />}
                                        </IconButton>
                                    </Link>
                                )
                                )
                            }
                        </td>
                    )
                }
                return (
                    <td className="border border-slate-600 px-2 py-1 text-left">
                        {
                            this.props.action.map((act,index) =>
                            (
                                <Link key={index} to={`${act.link}${this.props.model && ['groups', 'users'].includes(this.props.model) ? row.id : row.inscription_id}`} target="_blank">
                                    <IconButton
                                        color={act.button == 'view' ? `info` : (act.button == 'update' ? `success` : `error`)} >
                                        {act.button == 'view' && <VisibilityOutlinedIcon />}
                                        {act.button == 'update' && <BsFillTicketPerforatedFill />}
                                        {act.button == 'delete' && <DeleteOutlineOutlinedIcon />}
                                    </IconButton>
                                </Link>
                            )
                            )
                        }
                    </td>
                );
            }
            if (this.props.model && this.props.model == 'users') {

                if (column.field === 'role') {
                    return <td className={`border border-slate-600 px-4 py-1 text-left text-md `}>
                        <span class={`text-white text-xs font-medium mr-2 px-2.5 py-0.5 rounded ${row[column.field] == 'admin' ? 'bg-green-500' : row[column.field] == 'agent' ? 'bg-blue-500' : row[column.field] == 'student' ? 'bg-yellow-500' : 'bg-gray-500'} `}>{row[column.field] ? row[column.field] : 'undefined'}</span>
                    </td>
                }
                if (column.type === 'file') {
                    if (row[column.field]) {
                        return (
                            <td className={`border border-slate-600 px-4 py-1 text-left ${column.color ? column.color : 'text-slate-300'}   text-md `}>
                                <Link className="text-green-600 font-bold" to={`/en/images/idc/${row[column.field]}`} target="_blank">View</Link>
                            </td>
                        )
                    } else {
                        return (
                            <td className={`border border-slate-600 px-4 py-1 text-left ${column.color ? column.color : 'text-slate-300'}   text-md `}>
                                <p className="text-red-300 text-sm">Not found!</p>
                            </td>
                        )
                    }


                }

                if (column.field == 'created_at') {
                    return <td className={`border border-slate-600 px-2 py-1 text-left ${column.color ? column.color : 'text-slate-300'} text-sm `}>
                    {
                        
                                new Date(row[column.field] ).toLocaleDateString()
                                
                            
                    }
                </td>
                }

                return <td className={`border border-slate-600 px-2 py-1 text-left ${column.color ? column.color : 'text-slate-300'} text-sm `}>
                    {
                        column.field != 'id' ?
                            (row[column.field] ?
                                row[column.field] :
                                'Not set') :
                            (index + 1)
                    }
                </td>
            }
            if (this.props.model && this.props.model == 'groups') {

                return <td className={`border border-slate-600 px-4 py-1 text-left ${column.color ? column.color : 'text-slate-300 text-sm'}  `}>
                    {
                        column.field != 'id' ?
                            (column.field == 'room' ?
                                row['room'] :
                                row[column.field]) :
                            (index + 1)
                    }
                </td>
            }
            if (column.type === 'file' && this.props.model == 'demands') {
                if (row[column.field]) {
                    return (
                        <td className={`border border-slate-600 px-4 py-1 text-left ${column.color ? column.color : 'text-slate-300'}   text-md `}>
                            <Link className="text-green-600 font-bold" to={`/en/images/isUnivS/${row[column.field]}`} target="_blank">View</Link>
                        </td>
                    )
                } else {
                    return (
                        <td className={`border border-slate-600 px-4 py-1 text-left ${column.color ? column.color : 'text-slate-300'}   text-md `}>
                            <p className="text-red-300 text-sm">Not found!</p>
                        </td>
                    )
                }


            }
            return <td className={`${(column.type === 'hidden') ? 'hidden ' : ''} border border-slate-600 px-4 py-1 text-left ${column.color ? column.color : 'text-slate-300'} ${column.field == 'group' && row['group'] == 'pending' ? 'bg-red-500 bg-opacity-20' : ''}  text-md ${column.field == 'inscription_id' ? 'hidden' : ''}`}>
                {
                    column.field != 'id' ? (row[column.field] ? row[column.field] : 'Not yet') : (index + 1)
                }
            </td>
        }
        const handleFilter = (rows) => {
            //
            const new_columns = this.props.model == 'users' ? [...this.props.columns.filter(column => !['id', 'action'].includes(column.field))] :
                this.props.model == 'demands' ? [...this.props.columns.filter(column => !['id', 'action', 'inscription_id'].includes(column.field))] :
                    [...this.props.columns.filter(column => !['id', 'action', 'inscription_id'].includes(column.field))]

            console.log(new_columns)
            return (
                rows.filter(
                    row => (
                        new_columns.some((colum) => (
                            row[colum.field] && row[colum.field].toString().toLowerCase().indexOf(this.props.filter.toLowerCase()) > -1
                        )
                        )
                    )
                )
            )
        }
        const handleSort = (field) => {
            this.setState({ filterType: this.state.filterType == -1 ? 1 : -1 })
            if (field == 'created_at') {
                console.log(field)
                this.props.rows.sort((a, b) => {
                    const date1 = new Date(a['created_at']);
                    const date2 = new Date(b['created_at']);

                    if (date2 < date1) {
                        return -this.state.filterType;
                    }
                    if (date2 > date1) {
                        return this.state.filterType;
                    }
                    return 0;
            })
        } else {
            this.props.rows.sort((a, b) => {
                
                    if (a[field] < b[field]) {
                        return -this.state.filterType;
                    }
                    if (a[field] > b[field]) {
                        return this.state.filterType;
                    }
                    return 0;
                

            })
        }
            this.props.setRows([...this.props.rows])
        }
        return (
            <>
                <div className={`my-5 transition-all`}>
                    <table className="table w-full " >
                        <thead className=" bg-slate-700  text-left sticky top-16 text-slate-400 z-10">
                            <tr >
                                {
                                    this.props.columns.map((column, index) => (
                                        <th
                                            key={index}
                                            scope="col"
                                            className={`${column.type == 'hidden' ? 'hidden ' : ' '} px-2 py-3 text-left text-sm font-medium text-slate-400 uppercase tracking-wider hover:cursor-pointer border border-slate-600 `}
                                            onClick={() => handleSort(column.field)}
                                        >
                                            {column.use}
                                        </th>
                                    ))
                                }

                            </tr>
                        </thead>
                        <tbody className=" text-sm text-slate-100">
                            {
                                handleFilter(this.props.rows).map((row, index) => (
                                    <tr key={index} className='hover:bg-[#ffffff20]'>
                                        {
                                            this.props.columns.map((column) => (
                                                set_rows(row, column, index)
                                            ))
                                        }
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </>
        )
    }


}
