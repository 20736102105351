import React, { useEffect, useState } from "react";
import Dropd from "../../utils/Dropd";
import Select from 'react-select';
import _R from "../../../../components/BaseUrl";


const optionsTest = [
    
    {
        value: 0,
        label: 'No',
    },{
        value: 1,
        label: 'Yes',
    }
]
const optionsTime = [
    {
        value: 1,
        label: '08:00 - 10:00',
    }, {
        value: 2,
        label: '10:00 - 12:00',
    }, {
        value: 3,
        label: '12:00 - 14:00',
    }, {
        value: 4,
        label: '14:00 - 16:00',
    }, {
        value: 5,
        label: '17:00 - 19:00',
    }, {
        value: 6,
        label: 'Saturday 08:00 - 15:30',
    }, {
        value: 7,
        label: 'Thursday 08:00 - 15:30',
    }, {
        value: 8,
        label: 'Saturday and Thursday 08:00 - 12:00',
    },
]

const FormCompleteStudent1 = (props) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [optionsLangue, setOptionsLangue] = useState([]);
    useEffect(()=>{
        _R.get('/alllanguages').then((res) => { 
            setOptionsLangue(res.data)
            console.log(res.data)
        })
        
    },[])

    const handleSelection = (t) => {
        let _times = []
        //
        t.forEach(element => {
            _times.push(element.value)
           
        });
        setSelectedOption(_times)
        props.onChange({ label: 'times', value: (_times) })
    }
    return (
        <>
            <div className="px-4 py-5 sm:p-6">
                <div className="grid grid-cols-6 gap-6">
                    <Dropd
                        label={'Choose a Langage'}
                        id="langue"
                        name="langue"
                        class={'sm:col-span-6'}
                        options={optionsLangue}
                        onChange={props.onChange}
                    />
                    <Dropd
                        label={'Pass test'}
                        id="pessTest"
                        name="passTest"
                        class={'sm:col-span-6'}
                        options={optionsTest}
                        onChange={props.onChange}
                    />
                    <div className=" col-span-6 sm:col-span-6 relative">
                        <label htmlFor="" className="block text-sm font-medium text-gray-800">
                            Set Times ordered :
                        </label>
                        <Select
                            isMulti
                            className=" mt-1 rounded-sm  border-gray-200 bg-white  shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-md"
                            defaultValue={selectedOption}
                            onChange={handleSelection}
                            options={optionsTime}
                        />
                        <label htmlFor="" className=" absolute text-red-400 text-sm font-bold -bottom-5">{props.error}</label>
                    </div>
                </div>
            </div>

        </>
    )
}

export default FormCompleteStudent1;