import React from "react";
import { Link } from "react-router-dom";
import { BsFillPencilFill, BsEyeFill, BsFillTrashFill, BsClipboardDataFill } from "react-icons/bs";


export default class TableStudents extends React.Component {
    constructor(props) {
        super(props);
        this.componentRef = React.createRef()
        this.state = {
            filterType: 1,
            setPrint: false
        }
    }
    render() {
        const set_rows = (row, column, index) => {
            if (column.type === 'boolean') {
                return <td className="border border-slate-500 px-4 py-1 text-left text-slate-300 text-lg">
                    {
                        row[column.field] ?
                            <span className=" text-xs bg-green-700 border-green-500 rounded-md px-2 py-1 bg-opacity-80">{column.field == 'validate' ? 'Validate' : 'Yes'}</span>
                            :
                            <span className=" text-xs bg-red-700 border-red-500 rounded-md px-2 py-1 bg-opacity-80">{column.field == 'validate' ? 'Not validate' : 'No'}</span>
                    }
                </td>
            }
            if (column.field === 'action') {
                return (
                    <td className="border border-slate-500 px-2 py-1 text-left relative">

                        <div className="invisible flex top-[35px] left-[-40px] bg-[#00000050] px-4 py-2 group-hover:visible">
                            {
                                this.props.action.map(act =>
                                    act.button == 'update' ?
                                        (
                                            <button className={`rounded-full p-3 mx-1 text-green-500 hover:bg-green-600/40`}
                                                onClick={() => act.edit(row.inscription_id, row.times)}
                                            >
                                                {<BsFillPencilFill />}
                                            </button>
                                        )
                                        :
                                        act.button == 'delete' ?
                                            (
                                                <button className={`rounded-full p-3 mx-1 text-red-500 hover:bg-red-600/40`}
                                                    onClick={() => act.delete(row.inscription_id)}
                                                >
                                                    {<BsFillTrashFill />}
                                                </button>
                                            )
                                            :
                                            act.button == 'test' ?
                                                (
                                                    <Link to={`${act.link}${row.inscription_id}`}>
                                                        <button className={`rounded-full p-3 mx-1 text-yellow-500 hover:bg-yellow-600/40`}>
                                                            {<BsClipboardDataFill />}
                                                        </button>
                                                    </Link>
                                                )
                                                :
                                                (
                                                    <Link to={`${act.link}${row.inscription_id}`}>
                                                        <button className={`rounded-full p-3 mx-1 text-cyan-500 hover:bg-cyan-600/40`}>
                                                            {<BsEyeFill />}
                                                        </button>
                                                    </Link>
                                                )
                                )
                            }
                        </div>
                    </td>
                );
            }
            
            return <td className={`border border-slate-500 px-4 py-1 text-left text-md ${column.color ? column.color : 'text-slate-300'}`}>
                {
                    column.field != 'id' ? (row[column.field] ? row[column.field]: 'Not yet') : (index + 1)
                }
            </td>
        }
        const handleFilter = (rows) => {
            const new_columns = [...this.props.columns.filter(column => !['id', 'action'].includes(column.field))]
            return (
                rows.filter(
                    row => (
                        new_columns.some((colum) => (
                            row[colum.field] && row[colum.field].toString().toLowerCase().indexOf(this.props.filter.toLowerCase()) > -1
                        )
                        )
                    )
                )
            )
        }
        const handleSort = (field) => {
            this.setState({ filterType: this.state.filterType == -1 ? 1 : -1 })
            this.props.rows.sort((a, b) => {
                if (a[field] < b[field]) {
                    return -this.state.filterType;
                }
                if (a[field] > b[field]) {
                    return this.state.filterType;
                }
                return 0;
            })
            this.props.setRows([...this.props.rows])

        }
        return (
            <div className={`p-5 my-5 border border-cyan-700 h-[calc(100vh-470px)] overflow-auto transition-all`}>
                <table className="table w-full ">
                    <thead className=" bg-slate-700  text-left sticky -top-5 text-slate-400 z-10">
                        <tr >
                            {
                                this.props.columns.map((column, index) => (
                                    <th
                                        key={index}
                                        scope="col"
                                        className={`px-2 py-3 text-left text-sm font-medium text-slate-400 uppercase tracking-wider hover:cursor-pointer border border-slate-600 `}
                                        onClick={() => handleSort(column.field)}
                                    >
                                        {column.use}
                                    </th>
                                ))
                            }
                        </tr>
                    </thead>
                    <tbody className=" text-sm text-slate-100">
                        {
                            handleFilter(this.props.rows).map((row, index) => (
                                <tr key={index} className="group">
                                    {
                                        this.props.columns.map((column, indexColumn) => (
                                            set_rows(row, column, index)
                                        ))
                                    }
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}
