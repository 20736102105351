import React, { useEffect, useContext, useState, useCallback } from "react";
import Header from "../../components/Header";
import Table from "../../components/Table";
import _R from "../../components/BaseUrl";
import { tokens, useMode } from "../../theme";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import Loading from "../layouts/Loading";
import { FilterContext } from "../context/filterContext";
import HeaderSection from "../global/headerSection";
const Demand = (props) => {

    const measuredRef = useCallback(node => {
        if (node !== null) {
            setNoode(node);
        }
    }, []);
    const { filter } = useContext(FilterContext)
    const [noode, setNoode] = useState(null)
    const [filterStudents, setFilterStudents] = useState(null)

    useEffect(() => {
        _R.get('/getDemands').then((res) => {
            console.log(res.data)
            setFilterStudents(res.data)
        })
    }, [])

    const theme = useMode()
    const colors = tokens(theme.mode)

    const [columns, setColumns] = useState([
        { field: "id", use: "ID", type: 'integer', origin: 'integer' },
        { field: "firstname", use: "First Name", type: 'string', origin: 'string' },
        { field: "lastname", use: "Last Name", type: 'string', origin: 'string' },
        { field: "email", use: "Email", type: 'hidden', origin: 'hidden' },
        { field: "langue", use: "Langue", type: 'string', origin: 'string' },
        //{ field: "passTest", use: "pass Test?", type: 'boolean' },
        { field: "isUnivStudent", use: "student?", type: 'boolean', origin: 'boolean' },
        { field: "isUnivS", use: "Certificat", type: 'file', origin: 'file' },
        { field: "document_status", use: "Status?", type: 'list', origin: 'list' },
        { field: "created_at", use: "Date", type: 'date', origin: 'date' },
        { field: "action", use: "Action", type: 'button', origin: 'button' }
    ])

    const showHideColumns = [['FirstName', 1, true], ['LastName', 2, true], ['Language', 4, true]]


    return (
        filterStudents ?
            <div className="m-5">
                <HeaderSection
                    table={{
                        name: "demands",
                        headerTitle: "Demands",
                        headerSubTitle: "Manage all demands .."
                    }}
                    download={true}
                    noode={noode ? [{ node: noode, title: "Export Excel", sheet: "demands", filename: "demands" }] : null}
                    showHideColumns={{
                        showHideColumns: showHideColumns,
                        setColumns: setColumns,
                        columns: columns
                    }}
                    count={{ label: 'Demand', filterStudents }}
                />
                <div ref={measuredRef}>
                    <Table
                        model='demands'
                        rows={filterStudents}
                        columns={columns}
                        setRows={setFilterStudents}
                        filter={filter}
                        action={[
                            {
                                "button": "view",
                                "link": './show/'
                            }, {
                                "button": "update",
                                "link": './ticket/'
                            },
                        ]}
                    />
                </div>

            </div>

            :

            <div className="h-[calc(100vh_-_200px)]">
                <Loading />
            </div>
    )
}

export default Demand;