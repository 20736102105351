import React, { useState, useRef } from "react";

const NewInput = (props) => {
  const pass = useRef(null)
  const [show, setShow] = useState(true)

  const showpass = () => {
    //console.log(pass.current)
    pass.current.type = pass.current.type == "password" ? "text" : "password"
    setShow(!show)
  }

  let error = props.error
  if (error != null) {
    let keys = Object.keys(error)
    let values = Object.values(error)
    let newKey = keys.indexOf((props.name).toLowerCase())
    error = values[newKey]
  } else {
    error = null
  }
  return (
    <>

      <div className={` col-span-6 ${props.class} relative min-w-full`}>
        <div className="w-full">
          <label className="block text-md font-semibold text-gray-800  mb-1" htmlFor={props.name}>{props.label}</label>
          <div className='relative'>
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none z-20 text-teal-700">
              {props.icon}
            </div>
            {
              error && <div className="absolute text-red-500 -bottom-4 z-20 text-xs font-semibold">{error}</div>
            }
            <input
              ref={pass}
              name={props.name}
              onChange={(e) => props.onChange({ 'label': props.name, 'value': e.target.value })}
              id={props.id}
              type={props.type}
              className="appearance-none rounded-lg  block w-full px-3 py-3 pl-10 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder={props.placeholder ? props.placeholder : props.label}
            />
            {
              props.icon_show &&
              <div className="absolute inset-y-0 right-2.5 pl-3 flex items-center  z-20 text-teal-700">
                <button type="button" onClick={() => showpass()}>
                  {show ? props.icon_show : props.icon_hide}
                </button>

              </div>
            }
          </div>
        </div>
      </div>
    </>

  )
}
export default NewInput;