import React from 'react';
import NewInput from '../../utils/NewInput';
import { Link } from 'react-router-dom';
import { BsFillEnvelopeAtFill,BsEyeFill, BsEyeSlashFill, BsFillTelephoneFill, BsFillPersonFill, BsFillCalendarDateFill, BsFillCalendar2CheckFill, BsFillHouseGearFill, BsFillLockFill } from 'react-icons/bs';


export default function Form(props) {

  return (
    <>
      <div className="md:grid md:grid-cols-2 md:gap-3">
        <div className='col-span-2'>
          <div className="relative  rounded-lg shadow-[0_0_20px_1px_rgba(0,0,0,0.07)]">
            <form action="#" method="POST">
              <div className="overflow-hidden sm:rounded-sm">
                <div className="px-4 py-5 sm:p-6">
                  <div className="grid grid-cols-6 gap-5">
                    <NewInput
                      type="text"
                      label={'First name'}
                      id="firstName"
                      name="firstName"
                      class={'sm:col-span-3'}
                      icon={<BsFillPersonFill className="h-4 w-4 text-teal-500" aria-hidden="true" />}
                      onChange={props.onChange}
                      error={props.error}

                    />
                    <NewInput
                      type="text"
                      label={'Last name'}
                      id="lastName"
                      name="lastName"
                      class={'sm:col-span-3'}
                      icon={<BsFillPersonFill className="h-4 w-4 text-teal-500" aria-hidden="true" />}
                      onChange={props.onChange}
                      error={props.error}

                    />
                    <NewInput
                      type="text"
                      label={'Phone'}
                      id="phone"
                      name="phone"
                      class={'sm:col-span-3'}
                      icon={<BsFillTelephoneFill className="h-4 w-4 text-teal-500" aria-hidden="true" />}
                      onChange={props.onChange}

                      error={props.error}
                    />
                    <NewInput
                      type="text"
                      label={'Email address'}
                      id="email"
                      name="email"
                      class={'sm:col-span-3'}
                      icon={<BsFillEnvelopeAtFill className="h-4 w-4 text-teal-500" aria-hidden="true" />}
                      onChange={props.onChange}
                      error={props.error}
                    />
                    <NewInput
                      type="date"
                      label={'Birth day'}
                      id="birthDay"
                      name="birthDay"
                      class={'sm:col-span-3'}
                      icon={<BsFillCalendarDateFill className="h-4 w-4 text-teal-500" aria-hidden="true" />}
                      onChange={props.onChange}
                      error={props.error}
                    />
                    <NewInput
                      type="text"
                      label={'Birth place'}
                      id="birthPlace"
                      name="birthPlace"
                      class={'sm:col-span-3'}
                      icon={<BsFillCalendar2CheckFill className="h-4 w-4 text-teal-500" aria-hidden="true" />}
                      onChange={props.onChange}
                      error={props.error}
                    />
                    <NewInput
                      type="text"
                      label={'Address'}
                      id="adress"
                      name="address"
                      placeholder="Exp. bp:17 m'sila"
                      class={'sm:col-span-6'}
                      icon={<BsFillHouseGearFill className="h-4 w-4 text-teal-500" aria-hidden="true" />}
                      onChange={props.onChange}
                      error={props.error}
                    />
                    <NewInput
                      type="password"
                      label={'Password'}
                      id="password"
                      name="password"
                      class={'sm:col-span-6'}
                      icon={<BsFillLockFill className="h-4 w-4 text-teal-500" aria-hidden="true" />}
                      icon_show={<BsEyeFill className="h-4 w-4 text-teal-500" aria-hidden="true" />}
                      icon_hide={<BsEyeSlashFill className="h-4 w-4 text-teal-500" aria-hidden="true" />}
                      onChange={props.onChange}
                      error={props.error}
                    />
                    <NewInput
                      type="password"
                      label={'Confirm password'}
                      id="confirm_password"
                      name="confirm_password"
                      class={'sm:col-span-6'}
                      icon={<BsFillLockFill className="h-4 w-4 text-teal-500" aria-hidden="true" />}
                      icon_show={<BsEyeFill className="h-4 w-4 text-teal-500" aria-hidden="true" />}
                      icon_hide={<BsEyeSlashFill className="h-4 w-4 text-teal-500" aria-hidden="true" />}
                      onChange={props.onChange}
                      error={props.error}
                    />
                  </div>
                  {
                    props.localError &&
                    <h3 className='text-sm bg-red-400 font-bold text-white py-2 px-2 text-center mt-1'>{props.localError}</h3>
                  }

                </div>
                <div className="px-4 py-3 text-center sm:px-6">
                  <button
                    type="button"
                    className="w-full md:w-1/2 justify-center rounded-md bg-green-600 py-3 px-10 text-lg font-medium text-white hover:bg-green-700 shadow-xl transition duration-150 ease-in-out"
                    onClick={props.onSubmit}
                  >
                    Sign up
                  </button>
                </div>
                <div className=" text-center mx-auto relative py-3 px-5  text-lg">
                  Have you an account ?
                  <Link to="/en/signin"
                    className="font-bold  text-purple-600 hover:text-purple-700 transition duration-150 ease-in-out">
                    Sign in
                  </Link>
                </div>
              </div>
            </form>
          </div>

        </div>

      </div>
    </>
  )
}
