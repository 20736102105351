import { useContext, useState } from "react"
import React, { Component } from 'react';
import { IconButton, Typography, useTheme } from "@mui/material"
import { Link } from "react-router-dom"
import { useMode, tokens, themeSettings } from "../../theme"
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined"
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined"
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined"
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined"
import CalendarTodayOutlined from "@mui/icons-material/CalendarTodayOutlined";
import { SettingsContext } from "../context/settingsContext";
 
const Sidebare = (props) => {
    const basename = 'https://ceil.univ-msila.dz/en'
    const { mode, toggleColorMode } = useMode()
    const theme = themeSettings(mode)
    const colors = theme.palette
    const { isCollapsed, setIsCollapsed } = useContext(SettingsContext)
    const [selected, setSelected] = useState("Dashboard")

    return (
        colors &&
        <div
            style={{ backgroundColor: colors.background.sidebar }}
            className={` fixed left-0 top-0 z-[60] h-[100vh]  bg-slate-900 transition-["width"]  ${isCollapsed ? 'w-[50px] delay-300 bg-transparent' : 'w-[250px]  '}`}>
            <div
                className=" w-full bg-slate-950">
                <div
                    onClick={() => setIsCollapsed(!isCollapsed)}
                    icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
                    className={isCollapsed ? 'p-0 flex' : 'p-0 flex'}
                    style={{ background: colors.background.sidebarHead }}

                >
                    {!isCollapsed && (
                        <div
                            className=" h-[70px] flex justify-between items-center ml-4 flex-auto"
                        >
                            <div className="flex justify-between flex-auto">
                                <p
                                    style={{ color: colors.text.sidebar }}
                                >Ceil Msila
                                </p>
                                <p
                                    style={{ color: colors.text.sidebar }}
                                ><small>Admin console</small>
                                </p>
                            </div>

                            <IconButton
                                style={{ color: colors.text.sidebar }}
                                onClick={() => setIsCollapsed(!isCollapsed)}
                            >
                                <MenuOutlinedIcon />
                            </IconButton>
                        </div>
                    )}
                    {isCollapsed && (
                        <div
                            className=" h-[70px] flex justify-center items-center flex-auto"
                        >
                            <IconButton
                                style={{ color: colors.text.sidebar }}
                                onClick={() => setIsCollapsed(!isCollapsed)}
                            >
                                <MenuOutlinedIcon />
                            </IconButton>
                        </div>
                    )}
                </div>
            </div>
            <div>
                <div>
                    {!isCollapsed && (
                        <div >
                            <div className="h-[100px] flex justify-center items-center" >
                                <Link to="/en">
                                    <img
                                        src={`${basename}/assets/logo.jpg`}
                                        alt="profile"
                                        width="70px"
                                        height="70px"
                                        style={{ cursor: "pointer", borderRadius: "50%" }}
                                    />
                                </Link>
                            </div>
                        </div>
                    )}
                    <div
                        className={`h-[calc(100vh_-_170px)]  pb-5 transition-['display'] ${isCollapsed ? "hidden" : "overflow-auto delay-1000"}`}
                    >
                        <Item
                            title="Dashboard"
                            to="/en/admin/dashboard"
                            icon={<HomeOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="Demands"
                            to="/en/admin/demands"
                            icon={<HomeOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Typography
                            variant="h6"
                            color={colors.text.sidebarSub}
                            m="15px 0 5px 20px">
                            Users
                        </Typography>
                        {
                            props.user.role === 'admin' &&
                            <>
                                <Item
                                    title="Users"
                                    to="/en/admin/users"
                                    icon={<PeopleOutlinedIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                                <Item
                                    title="Teachers"
                                    to="/en/admin/teachers"
                                    icon={<ContactsOutlinedIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                            </>
                        }


                        <Item
                            title="Students"
                            to="/en/admin/students"
                            icon={<ContactsOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />

                        <Typography
                            variant="h6"
                            color={colors.text.sidebarSub}
                            m="15px 0 5px 20px">
                            Pedagogy
                        </Typography>
                        {
                            // props.user.role === 'admin' &&
                            <Item
                                title="Groups"
                                to="/en/admin/pedagogy/groups"
                                icon={<PeopleOutlinedIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                        }
                        <Item
                            title="Courses"
                            to="/en/admin/pedagogy/courses"
                            icon={<PeopleOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="Presence"
                            to="/en/admin/pedagogy/presence"
                            icon={<PeopleOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="Examain"
                            to="/en/admin/pedagogy/examain"
                            icon={<PeopleOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        {
                            props.user.role === 'admin' &&
                            <>
                                <Typography
                                    variant="h6"
                                    color={colors.text.sidebarSub}
                                    m="15px 0 5px 20px">
                                    Settings
                                </Typography>
                                <Item
                                    title="Sessions"
                                    to="/en/admin/settings/Sessions"
                                    icon={<PeopleOutlinedIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                                <Item
                                    title="Roles"
                                    to="/en/admin/settings/roles"
                                    icon={<PeopleOutlinedIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />

                                <Item
                                    title="Permissions"
                                    to="/en/admin/settings/permissions"
                                    icon={<PeopleOutlinedIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                                <Item
                                    title="Levels"
                                    to="/en/admin/settings/levels"
                                    icon={<PeopleOutlinedIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                                <Item
                                    title="Langues"
                                    to="/en/admin/settings/langues"
                                    icon={<PeopleOutlinedIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                            </>
                        }
                        <Typography
                            variant="h6"
                            color={colors.text.sidebarSub}
                            m="15px 0 5px 20px">
                            Others
                        </Typography>
                        <Item
                            title="Calendar"
                            to="/en/admin/Calendar"
                            icon={<CalendarTodayOutlined />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="Actuality"
                            to="/en/admin/appearance"
                            icon={<CalendarTodayOutlined />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                    </div>
                </div>
            </div>

        </div>

    )
}


const Item = ({ title, to, icon, selected, setSelected }) => {
    const theme = useMode()
    const colors = themeSettings(theme.mode).palette ? themeSettings(theme.mode).palette : null

    return (
        colors &&
        <Link to={to}>
            <button
                style={{ color: colors.text.sidebar }}
                onClick={() => setSelected(title)}
                className={`p-2 pl-4 w-full flex `}
            >
                {icon}
                <p className={`ml-4 font-light `}>{title}</p>
            </button>
        </Link >
    )
}

export default Sidebare