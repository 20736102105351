import React, { createContext, useState } from "react";
import _R from "../../components/BaseUrl"


export const EditContext = createContext(false)

export const EditProvider = ({ children }) => {
    const [editable_data, setEditable_data] = useState(null);
    const [edit, setEdit] = useState(false);
    const [picturePreview, setPicturePreview] = useState(null)
    const [file, setFile] = useState(null)
    const [inscriptions, setInscriptions] = useState(null)
    const [me, setMe] = useState(null)
    const [accountInfo, setAccountInfo] = useState(null)
    const [langues, setLangues] = useState([])


    const setData = (data) => {
        try {

            if (editable_data) {
                let d = editable_data
                let find = false
                d.fields.forEach((element, index) => {
                    if (element.name == data.fields[0].name) {
                        d.fields[index].value = data.fields[0].value
                        find = true
                    }
                })
                if (!find)
                    d.fields.push(data.fields[0])

                console.log(d)
                setEditable_data(d)
            } else {
                console.log(data)
                setEditable_data(data)
            }

        } catch (error) {
            console.log(error)
        }

    }
    //----------------------
    const setme=(d)=>{
        setMe(d)
    }
    const setlangues=(d)=>{
        setLangues(d)
    }
    const setpicturePreview=(d)=>{
        setPicturePreview(d)
    }
    const setfile=(d)=>{
        setFile(d)
    }
    const setinscriptions=(d)=>{
        setInscriptions(d)
    }
    const setedit=(d)=>{
        setEdit(d)
    }
    const setaccountInfo=(d)=>{
        setAccountInfo(d)
    }
    //----------------------
    const initialize = () => {
        setEditable_data(null)
    }

    return (
        <EditContext.Provider 
        value={{ me, setme,inscriptions, setinscriptions,file, setfile,picturePreview,setpicturePreview,edit,setedit,editable_data, setData,accountInfo,setaccountInfo, initialize,langues,setlangues }}>
            {children}
        </EditContext.Provider>
    );
};


