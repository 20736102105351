import Header from "../../components/Header"
import { Box } from "@mui/material"
import React, { Component } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { LineChart } from "@mui/x-charts";
import { PieChart } from "@mui/x-charts";
import { Gauge } from '@mui/x-charts/Gauge';
const Dashboard = () => {
    return (
        <div>
            <div className=" bg-slate-800 p-3 border-l border-t border-slate-700 shadow-[2px_2px_5px_1px_#00000040]">
                <Header title={'DASHBOARD'} subtitle={`welcome`} />
            </div>
            <div className="mt-5">
                <div className="flex">
                    <div className=" basis-1/2 border p-2 m-2 border-slate-800 ">
                        <BarChart
                            series={[
                                { data: [35, 44, 24, 34] },
                                { data: [51, 6, 49, 30] },
                                { data: [15, 25, 30, 50] },
                                { data: [60, 50, 15, 25] },
                            ]}
                            height={290}
                            xAxis={[{ data: ['Q1', 'Q2', 'Q3', 'Q4'], scaleType: 'band' }]}
                            margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
                        />
                    </div>
                    <div className=" basis-1/2 border  p-2 m-2 border-slate-800">
                        <LineChart
                            xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
                            series={[
                                {
                                    data: [2, 5.5, 2, 8.5, 1.5, 5],
                                },
                            ]}
                            height={300}
                        />
                    </div>

                </div>
                <div className="flex">
                    <div className=" basis-1/2 border p-2 m-2 border-slate-800">
                        <PieChart
                            series={[
                                {
                                    data: [
                                        { id: 0, value: 10, label: 'series A' },
                                        { id: 1, value: 105, label: 'series B' },
                                        { id: 2, value: 20, label: 'series C' },
                                    ],
                                },
                            ]}
                            height={300}
                        />
                    </div>
                    <div className=" basis-1/2 border  p-2 m-2 border-slate-800">
                        <Gauge width={100} height={100} value={60} />
                        <Gauge width={100} height={100} value={60} startAngle={-90} endAngle={90} />
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Dashboard