import { Box,useTheme,Typography,IconButton,Button,Chip  } from "@mui/material"
import Header from "../../components/Header"
import { tokens } from "../../theme";
//import {DataGrid, GridToolbar} from "@mui/x-data-grid"
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import axios from "axios"
import { Link } from "react-router-dom";
import { useState,useEffect } from "react";
import React, { Component }  from 'react';

 
const Roles=() => {
    useEffect(()=>{
        axios.get('/getRoles').then((res)=>{
            console.log(res.data)
            setRoles(res.data)
        })
      },[])
    const [roles,setRoles] = useState([])
    const theme=useTheme()
    const colors=tokens(theme.palette.mode)
    const columns=[
        {field:"id" ,headerName:"ID"},
        {field:"name" ,headerName:"Name",flex:1},
        {field:"permissions" ,headerName:"Permissions",flex:2,cellClassName: "overflow",
            renderCell: ({ row: { perm } }) =>{
                return (
                    <Box sx={{whiteSpace: "unset",display:"flex",flexWrap: "wrap",padding: "3px"}}>
                       { perm.map(p=>{
                            return (
                                <Chip sx={{m:"0 3px 3px 0"}} label={p.permission_name} color="info" size="small"  />
                            )
                        })}
                    </Box>
                ) 
            }
        },
        {field: "action", headerName: "Action",flex: 1,
          renderCell: ({row:{id}}) => {
            return (
              <Box
                m="0 auto"
                p="5px"
                display="flex"
                justifyContent="center"
              >
                <Link to ={`/settings/roles/show/${id}`}>
                    <IconButton color="info" >
                        <VisibilityOutlinedIcon /> 
                    </IconButton>
                </Link>
                <Link to ={`/settings/roles/edit/${id}`} params={{ testvalue: "hello" }}>
                    <IconButton color="success" >
                        <ModeEditOutlinedIcon /> 
                    </IconButton>
                </Link>
                <IconButton color="error" >
                    <DeleteOutlineOutlinedIcon /> 
                </IconButton>
              </Box>
            );
          }
        }
    ]
    return (
        <Box m="20px">
            <Header title="ROLES" subtitle="Manage roles" />
            <Box textAlign="right" display="flex" flexDirection= "row-reverse">
            <Link to="/settings/roles/add">
            <Button variant="contained" color="success" startIcon={<AddCircleOutlineOutlinedIcon />} sx={{color:colors.gray[100],background:colors.greenAccent[800]}}>
                Create Role
            </Button>
            </Link>
            </Box>
            <Box 
                m="40px 0 0 0"
                height="75vh"
            >
              {/*  <DataGrid
                    sx={{ "& .MuiDataGrid-row":{
                        
                        maxHeight:"inherit !important"
                    },
                        "& .MuiDataGrid-cell":{
                            overflow:"unset !important",
                            whiteSpace:"unset !important",
                            maxHeight:"inherit !important",
                            p:"3px"
                        }
                    }}
                    rows={roles}
                    columns={columns}
                    components={{Toolbar: GridToolbar}}
                />*/}
            </Box>
        </Box>
    )
}
export default Roles