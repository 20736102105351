import React from 'react'

function Item(props) {
    return (
        <div className="md:grid md:grid-cols-12 md:gap-6 items-center overflow-hidden">
            {/* Image */}
            <div className={`max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 ${props.order} h-96`}  data-aos="fade-up">
                <img className="max-w-full mx-auto md:max-w-none" src={props.image} width="540" height="405" alt="Features 01" />
            </div>
            {/* Content */}
            <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6 h-full" data-aos="fade-left">
                <div className="md:pr-4 lg:pr-12 xl:pr-16">
                    <h3 className="h3 mb-3">{props.bigTitle}</h3>
                    <h6 className="mb-3 text-sm text-gray-700 ">{props.title}</h6>
                    <p className="text-xl text-gray-400 mb-4 text-justify">{props.text}</p>
                   
                </div>
            </div>
        </div>
    )
}

export default Item