import React, {Component } from 'react';
import QRCode from "react-qr-code";

export default class StudentCard extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className='print hidden p-3'>
                <div className="w-full max-w-sm  rounded-sm shadow overflow-hidden flex-row flex ">
                    <div className="p-4 bg-gray-100 basis-1/5">
                        <QRCode
                            className="w-24 h-24 mx-auto mb-4 "
                            value={this.props.student.qr}
                            viewBox={`0 0 256 256`}
                        />
                        <p className="text-center text-gray-500 text-sm">Scan the QR code to see more about this student</p>
                    </div>
                    <div className="pt-4 pl-2 basis-4/5 relative">
                        <img className='absolute top-0 left-0 w-full h-full opacity-20' src={`${window.location.origin}/ceild.png`} alt="" />
                        <div className=' p-2'>
                            <img className="w-24 h-24 object-cover  drop-shadow text-black" src={`${window.location.origin}/images/users/${this.props.student.picture}`} alt="Student" />
                        </div>
                        <div className="pl-4">
                            <h2 className="text-xl font-medium text-gray-800">{this.props.student.name}</h2>
                            <p className="text-gray-600">{this.props.student.birthday}</p>
                            <p className="text-gray-600">{this.props.student.address}</p>
                            <p className="text-gray-600">{this.props.student.language}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


