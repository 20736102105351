import React, { useContext, useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import Header from './partials/Header';
import Sliders from './partials/Sliders';
import HeroHome from './partials/HeroHome';
import FeaturesBlocks from './partials/FeaturesBlocks';
import FeaturesZigZag from './partials/FeaturesZigzag';
import Testimonials from './partials/Testimonials';
import Footer from './partials/Footer';
import 'aos/dist/aos.css';
import './css/style.css';
import AOS from 'aos';
import Notification from '../global/Notification';
import { AuthContext } from '../context/authContext';
import { SettingsContext } from '../context/settingsContext';
import CompleteValide from './partials/CompleteValide';
import { RiUserSettingsFill } from "react-icons/ri";
import _R from "../../components/api";
import { BsShieldFillCheck } from 'react-icons/bs'
import Loading from "../layouts/Loading"
import { images_path } from '../../paths';

function Home() {
  const { isAuth, user, login, loginError } = useContext(AuthContext)
  const { items, setItems, load, setLoad } = useContext(SettingsContext)
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [checkLogin, setCheckLogin] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showNotification, setShowNotification] = useState(false);
  const handleChange = (type) => {
    console.log(items);
    switch (type.label) {
      case 'email':
        setEmail(type.value);
        break;
      case 'password':
        setPassword(type.value);
        break;
      default:
        break;
    }
  }

  const handleLogin = () => { 
    setCheckLogin(true)
    login(email, password)
    setCheckLogin(false)
  }

  useEffect(() => {
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 600,
      easing: 'ease-out-sine',
    });
  });

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto'
    window.scroll({ top: 0 })
    document.querySelector('html').style.scrollBehavior = ''
  }, [location.pathname]);

  useEffect(() => {
    _R.get('/settings').then((response) => {
      setItems(response.data)
      setLoading(false)
    })
  }, [])

  const handleVerifietMail = () => {
    setLoading(true)
    setShowNotification(false)
    _R.post('/verifietMail', { id: user.user.id }).then(res => {
      console.log(res.data)
      setShowNotification(true)
      setLoading(false)
    })
  }




  return (
    !loading && items ?
      <div className=" bg-gray-200 text-gray-900  flex flex-col min-h-screen">
        <Header />
        <section>
          <div className="mb-4 text-center py-6 " style={{ background: `#0f172a url(${images_path}heade.png) no-repeat scroll 28% 10%`, borderRadius: "0px 0px 23% 23%/30%" }} >
            <div className='max-w-6xl mx-auto py-5'>
              <div className='flex md:flex-row flex-col text-center md:text-left justify-between items-center'>
                <div className='text-2xl md:text-3xl mx-2 font-bold'>
                  <h1 className="text-white " data-aos="fade-up">{items.header[0].content}</h1>
                  <p className="text-teal-400 mb-4" data-aos="fade-up" data-aos-delay="200">{items.header[1].content}</p>
                </div>
                <div >
                  <img className="w-80 fill-current text-purple-600" src={`${images_path}${items.header[2].content}`} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <main className="mx-0 lg:mx-auto  w-full lg:max-w-6xl  ">

          {
            !user &&
            <div className='text-center my-4 md:hidden'>
              <h6 className='text-xl md:text-md'>
                Don't have an account?
                <Link to={'/en/signup'} className='text-blue-500 font-bold ml-1'>
                  Sign Up
                </Link>
              </h6>
            </div>
          }
          <section className=' my-4 bg-white'>
            <div className='flex lg:flex-row flex-col  p-4 '>
              <div className="md:basis-1/3 w-full flex flex-col mr-4 lg:mb-0  mb-4">
                {
                  !isAuth ?
                    <div className='bg-gray-100 relative w-full  mx-auto p-3 mb-4 relative  h-full flex flex-col justify-around ' style={{ borderTop: '40px solid #3730a3' }}>
                      <h6 className='font-bold '>Authentication :</h6>
                      {loginError && <p className=' right-0 bg-red-500 text-sm text-center font-semibold text-white py-2 mb-2 absolute top-[2px] w-1/2'>{loginError}</p>}
                      <div className='my-1 '>
                        <input
                          onChange={(e) => handleChange({ label: 'email', value: e.target.value })}
                          className='w-full border border-slate-300 rounded-md p-2 font-semibold text-lg md:text-sm' type="email" name="email" id="email" placeholder='Email' />
                      </div>
                      <div className='my-1 '>
                        <input
                          onChange={(e) => handleChange({ label: 'password', value: e.target.value })}
                          className='w-full border border-slate-300 rounded-md p-2 font-semibold text-lg md:text-sm' type="password" name="password" id="password" placeholder='Password' />
                      </div>
                      <div className='mt-1 text-right'>
                        {
                          !checkLogin ?
                            <button
                              className='py-2 px-10 bg-green-600 hover:bg-green-700 text-white text-sm shadow-sm rounded-sm'
                              type="button"
                              onClick={() => handleLogin()}>
                              Sign in
                            </button>
                            :
                            <button
                              className='py-2 px-10 bg-green-400 text-white text-sm shadow-sm rounded-sm'
                              type="button" disabled={true}
                            >
                              Checking...
                            </button>
                        }

                      </div>
                    </div>
                    :
                    <div className='w-full  mx-auto p-3 mb-4 relative bg-gray-100 h-full text-center'>
                      <div className='w-3/4 mx-auto'>
                        <img lass="mx-auto" src={`${images_path}welcome1.png`} alt="" />
                      </div>
                      <h4 className='font-bold text-xl font-black  ml-[20%] md:ml-0  text-left'>Welcome 👋</h4>
                      <div className='flex flex-col'>
                        <h6 className='font-bold text-[3rem] ml-[20%]  md:ml-0 text-[#31c57a] text-center items-center leading-9'>{user.user.firstname}</h6>
                        <p className='font-bold text-[1rem]  md:ml-0 text-green-700 text-center items-center leading-9'>Your address mail : <span>{user.user.email}</span></p>
                        {user.user.status ?
                          <div className='flex'>
                            <Link to={user.role != 'admin' ? '/en/me' : '/en/admin/dashboard'} className='text-white text-[24px] bg-teal-700 p-5 my-5 mx-auto hover:bg-teal-800 transition-all duration-100'><RiUserSettingsFill /></Link>
                          </div>
                          :
                          <div className="m-2 p-2 bg-[#ffdc8a]">
                            <p className="mb-2 text-lg font-bold ">
                              You must verifiet your address mail
                            </p>
                            <p className="mb-2 text-lg font-bold text-center ">
                              يرجى تأكيد بريدك الإلكتروني
                            </p>
                            <p className="mb-2 text-sm   text-center ">
                              سيتم إرسال رسالة تأكيد إلى بريدك الإلكتروني
                            </p>
                            <button
                              className=' py-2 px-10 bg-yellow-400 hover:bg-yellow-500 text-white text-sm shadow-sm rounded-sm'
                              type="button"
                              onClick={() => handleVerifietMail()}
                            >

                              <BsShieldFillCheck className='text-center mx-auto text-yellow-700 h-4 w-4 m-2' />
                              Send me a verification message
                            </button>
                          </div>

                        }
                      </div>
                    </div>
                }
                <HeroHome video={items.main.video} />
              </div>
              <div className="md:basis-2/3 w-full sm:h-[27rem] h-[20rem] flex">
                <Sliders images={items.main.slider} autoPlay={true} interval={4000} infiniteLoop={true} />
              </div>
            </div>
          </section>

          <FeaturesBlocks />
          <FeaturesZigZag />
          <Testimonials />
        </main>
        <Footer />
        {showNotification &&
          <Notification
            info={
              {
                type: 'suc',
                message: 'Email verification sent successfully Please, Check your email '
              }

            }
          />
        }

      </div>
      :

      <div>
        <Loading />
      </div>
  );
}

export default Home;
