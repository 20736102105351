import React, { useContext, useState } from "react";
import { EditContext } from "../scenes/context/editContext"

const TextInfo = (props) => {
    const { setData } = useContext(EditContext)
    const [val, setVal] = useState(props.value)
    return (
        <div className={`${props.flex} p-1 flex-initial`}>
            <div className={`${props.edit ? 'border-lime-300 shadow-[0_0_10px_1px_rgb(44,251,12,0.47)]' : 'border-teal-500'}  border py-2 px-4 rounded-md ${props.css} ${props.value == 'Not yet' ? 'bg-red-500' : 'bg-black'} bg-opacity-20 flex justify-between items-center   `}>
                <div className="basis-1/4 lg:flex-initial">
                    <label htmlFor="" className=" text-teal-500 text-xs text-opacity-90 font-semibold ">{props.label}</label>
                </div>
                <div className="flex-1">
                    { 
                        !props.edit ?
                            (typeof val != "boolean") ?
                                (
                                    <p className={`ml-2 text-lg  text-left ${props.theme ? "text-white" : "text-slate-300"}`}>{`${val}`}</p>
                                )
                                :
                                <p className={`ml-2 text-lg  text-left ${props.theme ? "text-white" : "text-slate-300"}`}>{`${val ? 'Yes' : 'No'}`}</p>
                            :
                            <input
                                className=" ml-1 text-lg text-slate-300  text-center border-none bg-transparent focus:outline-none p-0 my-0 w-full"
                                type={(props.label).toLowerCase() == 'birthday' ? 'date' : 'text'}
                                name={(props.label).toLowerCase()} id=""
                                defaultvalue={val}
                                onBlur={(e) => {
                                    setVal(e.target.value)
                                    setData({
                                        fields: [{
                                            name: props.name ?? undefined,
                                            value: e.target.value
                                        }],
                                        model: props.model ?? undefined
                                    })
                                }}
                            />
                    }
                </div>
            </div>
        </div>
    )
}
export default TextInfo;