import axios from "axios";


const _R =  axios.create({
  baseURL: 'https://ceil.univ-msila.dz:6799/api',
  headers: {
    "X_CEIL_TOKEN": localStorage.getItem('ceil_token')
}
})
export default _R





