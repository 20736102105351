import { Box } from "@mui/material";
import Header from "../../components/Header";
import React, { useEffect, useState, useRef, useCallback, useContext } from "react";
import Table from "../../components/Table";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { Link } from "react-router-dom";
import { Modal } from "flowbite-react";
import _R from "../../components/api";
import Loading from "../layouts/Loading";
import { FilterContext } from "../context/filterContext"

const Students = (props) => {
    const tableStudentsRef = useRef(null);
    const measuredRef = useCallback(node => {
        if (node !== null) {
            setNoode(node);
        }
    }, []);
    useEffect(() => {
        _R.get('/getStudents').then((res) => {
            console.log(res.data)
            setFilterStudents(res.data)
        })
    }, [])
    const { filter } = useContext(FilterContext)
    const [noode, setNoode] = useState(null)
    const [filterStudents, setFilterStudents] = useState(null)
    const [showModel, setShowModel] = useState(false)
    const [file, setFile] = useState(null)

    const [columns, setColumns] = useState([
        { field: "id", use: "ID", type: 'integer', origin: 'integer' },
        { field: "firstname", use: "First Name", type: 'string', origin: 'string' },
        { field: "lastname", use: "Last Name", type: 'string', origin: 'string' },
        { field: "email", use: "Email", type: 'string', origin: 'string', color: "text-yellow-300" },
        { field: "phone", use: "Phone", type: 'hidden', origin: 'string' },
        { field: "langue", use: "Language", type: 'string', origin: 'string' },
        { field: "group", use: "Group", type: 'string', origin: 'string' },
        { field: "test", use: "Mark test", type: 'hidden', origin: 'hidden' },
        { field: "action", use: "Action", type: 'button', origin: 'button' }
    ])

    const handleUpload = (e) => {
        console.log(e.target.files[0])
        setFile(e.target.files[0])
    }

    const handleSetGroup = async () => {
        var formData = new FormData()
        formData.append('file', file)
        const result = await _R.post('/setGroup', formData)
        window.location.reload()
    }

    return (
        filterStudents ?
            <div className="m-5">
                <div className="bg-slate-800 p-3 border-l border-t border-slate-700 shadow-[2px_2px_5px_1px_#00000040]">
                    <Header title="STUDENTS" subtitle="Manage all students .." />
                    <div className="text-right">
                        <Link onClick={() => setShowModel(true)} to="" className="btn bg-green-600 hover:bg-green-700 p-1 text-green-100 text-md mx-2" >
                            Set groups
                        </Link>
                        <DownloadTableExcel
                            filename="students table"
                            sheet="students"
                            currentTableRef={noode}
                        >
                            <button className=" btn bg-blue-600 hover:bg-blue-700 p-1 text-blue-100 text-md"> Export excel </button>
                        </DownloadTableExcel>
                    </div>
                    <div className="flex items-end justify-between">
                        <div className="flex justify-start">
                            {[['FirstName', 1, true], ['LastName', 2, true], ['Email', 3, true], ['Phone', 4, false], ['Language', 5, true]].map((item) => {
                                return (
                                    <div className="ml-2 text-slate-400 border border-slate-700 p-2 rounded-sm ">
                                        <span className="mr-1">{item[0]}</span>
                                        <input type="checkbox" name="" id="" defaultChecked={item[2]} onClick={(e) => {
                                            const col = [...columns]
                                            col[item[1]].type = e.target.checked ? col[item[1]].origin : 'hidden'
                                            setColumns(col)
                                        }}
                                            className=' focus:outline-none clear-both ' />
                                    </div>
                                )
                            })
                            }

                        </div>
                        {
                            filterStudents &&
                            <div>
                                <p className="text-slate-500 ml-2">
                                    {filterStudents.length} - students
                                </p>
                            </div>
                        }
                    </div>
                </div>
                <div ref={measuredRef}>
                    <Table
                        model='students'
                        rows={filterStudents}
                        columns={columns}
                        setRows={setFilterStudents}
                        filter={filter}
                        action={[
                            {
                                "button": "view",
                                "link": '/en/admin/students/show/'
                            },
                        ]}
                    />
                </div>
                <>
                    <Modal
                        show={showModel}
                        size="md"
                        popup={true}
                        onClose={() => setShowModel(false)}
                    >
                        {<Modal.Header className="bg-slate-800" />}
                        <Modal.Body className="bg-slate-700 text-gray-400">
                            <div className=" space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8">
                                <h3 className="text-lg font-medium pt-5 ">
                                    Select the level and let the application determine the group and time according to the chosen time.
                                </h3>
                                <div>
                                    <input onChange={(e) => handleUpload(e)} type="file" name="file" id="" />
                                </div>

                                <div className="w-full text-right">
                                    <button onClick={(e) => handleSetGroup(e)} className="btn bg-green-600 text-gray-100 hover:bg-green-700 ">
                                        Validate
                                    </button>
                                </div>

                            </div>
                        </Modal.Body>
                    </Modal>
                </>

            </div> :
            
        <div>
        <Loading />
        </div>
    )
}

export default Students
