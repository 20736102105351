import { Box, Chip } from "@mui/material"
import axios from "axios"
import { useState } from "react"
import { useEffect } from "react"
import Header from "../../components/Header"
import React, { Component }  from 'react';

const Permissions = () =>{
    useEffect(()=>{
        axios.get("/getPermissions").then(res=>{
            console.log(res.data)
            setPerm(res.data)
        })
    },[])
    const [perm,setPerm]=useState([])
    return (
        <Box m="20px">
            <Header title="PERMISSIONS" subtitle="All permissions" />
            <Box  sx={{whiteSpace: "unset",display:"flex",flexWrap: "wrap",padding: "10px"}}>
                {
                    perm.map(ele=>{
                        return (
                            <Chip sx={{m:"0 3px 3px 0"}} label={ele.name} color="info" size="small"  />
                        )
                    })
                }
            </Box>
        </Box>
    )

}
export default Permissions