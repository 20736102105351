import React, { useContext, useState, useEffect } from "react";
import { EditContext } from "../../context/editContext"

const TextInp = ({ label, value, css = "basis-1/3", type = "text", values = null, edit = false, name, ...props }) => {

    const { setData } = useContext(EditContext)
    const [currentValue, setCurrentValue] = useState(value)
    useEffect(() => {

        setCurrentValue(value)
    }, [currentValue])

    const handleEdit = (e) => {
        setCurrentValue(e.target.value)
        setData({
            fields: [{
                name: name,
                value: e.target.value
            }]
        })
    }
    if (edit)
        return (
            <div className={`${css} border-b-2 border-green-600 bg-green-100 px-2 pt-2 m-2 flex items-center`}>
                <div className="label text-green-500 text-sm">
                    <label>{label}:</label>
                </div>
                <div className="inputGroup pl-3 flex-auto">
                    {
                        type == 'select' ?
                            <select
                                name={name}
                                onChange={(e) => handleEdit(e)}
                                className="text-lg fond-[600] text-white w-full bg-[#00000030] m-0 p-0 pl-2 mb-2 border-none"
                                defaultValue={currentValue}>
                                {
                                    values.map((item) => {
                                        return (
                                            <option value={item.key}>{item.value}</option>
                                        )
                                    }
                                    )
                                }
                            </select>
                            :
                            <input
                                name={name}
                                className="text-lg fond-[600] text-white bg-[#00000030] w-full p-0 pl-2 mb-2 border-none  "
                                type={type}
                                defaultValue={currentValue}
                                onBlur={(e) => handleEdit(e)}
                            />



                    }
                    {name == 'email' &&
                        <div>
                            <label className="text-xs text-red-300">Be careful ! if you change your address email you must verify it</label>
                        </div>
                    }
                </div>
            </div>
        )

    return (
        <div className={`${css} border-b-2 border-sky-600 bg-sky-100 px-2 pt-2 m-2 flex items-center`}>
            <div className="label text-sky-500 text-sm">
                <label htmlFor="">{label}:</label>
            </div>
            <div className="inputGroup pl-2">
                <p className=" lg:text-lg  font-[700] text-sky-700">{type == 'select' ? (currentValue != null ? values[currentValue].value : 'not defined') : currentValue}</p>
            </div>
        </div>
    )
}
export default TextInp