import React from 'react';
import Header from './blocks/Header';
import Block from './blocks/Block';
import {BsFillEaselFill,BsMegaphoneFill,BsWechat} from 'react-icons/bs'
function FeaturesBlocks() {
  return (
    <section  className='my-4 bg-white'>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12">
          <Header />
          <div className="max-w-sm mx-auto grid gap-8 md:grid-cols-2 lg:grid-cols-3 lg:gap-16 items-start md:max-w-2xl lg:max-w-none h-full	" data-aos-id-blocks>
            <Block bg='bg-lime-600' icon={<BsFillEaselFill className='h-14 w-14 text-lime-200'/>} data-aos="fade-up" data-aos-delay="100" data-aos-anchor="[data-aos-id-blocks]" title={'PRESENTATION'} description={'The Ministry of Higher Education and Scientific Research, having expressed the desire to remedy the high failure rate of first-year higher education students, which is partly due to their low level in French, has decided to strengthen the system of Intensive Language Teaching Centers (CEIL) within Algerian universities. The CEIL is a public institution of the Ministry of Higher Education and Scientific Research.'} />
            <Block bg='bg-amber-600' icon={<BsMegaphoneFill className='h-14 w-14 text-amber-200'/>}  data-aos="fade-up" data-aos-delay="200" data-aos-anchor="[data-aos-id-blocks]" title={'TRAINING OFFER'} description={`The Center for Intensive Language Teaching in M'Sila offers students support courses in language "a bridge" to facilitate learning and assimilation of the courses given during their university course. Emphasis is therefore placed on the development of the four skills necessary for communication in languages.`}/>
            <Block bg='bg-sky-600' icon={<BsWechat className='h-14 w-14 text-sky-200'/>}  data-aos="fade-up" data-aos-delay="300" data-aos-anchor="[data-aos-id-blocks]" title={'AVAILABLE LANGUAGES'} description={`CEIL offers language training in accordance with the Common European Framework of Reference for English, French, German, Spanish, Turkish Levels A1 A2 B1 B2 and English Pro (Medical English, Business English and Administration) as well as Professional French.`}/>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeaturesBlocks;
