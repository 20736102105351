import { Box} from "@mui/material";
import Header from "../../components/Header";
import { Link } from "react-router-dom"
import { useEffect, useState, useCallback,useContext } from "react";
import _R from "../../components/api";
import React from 'react';
import Loading from "../layouts/Loading";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { FilterContext } from "../context/filterContext"
import Table from "../../components/Table";


const Teachers = () => {

    const measuredRef = useCallback(node => {
        if (node !== null) {
            setNoode(node);
        }
    }, [])

    useEffect(() => {
        _R.get('/getTeachers').then((res) => {
            console.log(res.data)
            setFilterUsers(res.data)
        })
    }, [])

    const { filter } = useContext(FilterContext)
    const [noode, setNoode] = useState(null)
    const [filterUsers, setFilterUsers] = useState(null)

    const columns = [
        { field: "id", use: "ID", type: "integer" },
        { field: "firstname", use: "First Name", type: "string" },
        { field: "lastname", use: "Last Name", type: "string" },
        { field: "email", use: "Email", type: "string", color: "text-yellow-300" },
        { field: "phone", use: "Phone", type: "string" },
        { field: "birthday", use: "Birthday", type: "date" },
        { field: "birthplace", use: "Birthplace", type: "string" },
        { field: "address", use: "Address", type: "string" },
        { field: "role", use: "Role", type: "string" },
        { field: "action", use: "Action", type: "button" }
    ]

    return (
        filterUsers ?
            <Box m="20px">
                <Header title="TEACHERS" subtitle="Manage all teachers .." />
                <Box textAlign="right">
                    <Link to="/admin/users/add" className="btn bg-green-600 hover:bg-green-700 p-1 text-green-100 text-md mx-2">
                        Add user
                    </Link>
                    <DownloadTableExcel
                        filename="users table"
                        sheet="users"
                        currentTableRef={noode}
                    >
                        <button className=" btn bg-blue-600 hover:bg-blue-700 p-1 text-blue-100 text-md"> Export excel </button>
                    </DownloadTableExcel>
                </Box>
                <div ref={measuredRef}>
                    <Table
                        model='teachers'
                        rows={filterUsers}
                        columns={columns}
                        setRows={setFilterUsers}
                        filter={filter}
                        action={[
                            {
                                "button": "view",
                                "link": '/admin/users/show/'
                            },

                        ]}
                    />
                </div>
            </Box> :
            
        <div>
        <Loading />
        </div>
    )
}

export default Teachers
