import React, { useRef, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Header from '../partials/Header';
import PageIllustration from '../partials/PageIllustration';
import _R from '../../../components/api'
import Notification from '../../global/Notification'

function NewPassword() {
    const { id, token } = useParams()
    const navigate = useNavigate()
    const newpass = useRef()
    const confirmpass = useRef()
    const [showNotification, setShowNotification] = useState(false);
    const [info, setInfo] = useState({
        type: '',
        message: ''
    }
    )
    const handleResetPassword = () => {
        console.log(confirmpass);
        let form = {
            newpass: newpass.current.value,
            confirmpass: confirmpass.current.value,
            id,
            token
        }
        setShowNotification(false)

        _R.post('/change-password', form).then(res => {
            if (!res.data.status) {
                setInfo({
                    type: 'err',
                    message: 'Password or confirm password error'
                })
                setShowNotification(true)
            } else {
                setInfo({
                    type: 'suc',
                    message: 'Your password has been changed successfully'
                })
                setShowNotification(true)
                setTimeout(() => {
                    navigate('/en/signin')
                }, 3000)

            }
        })
    }

    return (
        <div className="flex flex-col min-h-screen overflow-hidden">

            {/*  Site header */}
            <Header />

            {/*  Page content */}
            <main className="grow">

                {/*  Page illustration */}
                <div className="relative max-w-6xl mx-auto h-0 pointer-events-none" aria-hidden="true">
                    <PageIllustration />
                </div>

                <section className="relative">
                    <div className="max-w-6xl mx-auto px-4 sm:px-6">
                        <div className="pt-32 pb-12 md:pt-40 md:pb-20">

                            {/* Page header */}
                            <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                                <h1 className="h1 mb-4">Set new password?</h1>
                                <p className="text-xl text-gray-400">We'll email you instructions on how to reset it.</p>
                            </div>

                            {/* Form */}
                            <div className="max-w-sm mx-auto">
                                <form>
                                    <div className="flex flex-wrap -mx-3 mb-4">
                                        <div className="w-full px-3">
                                            <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="email">New password</label>
                                            <input ref={newpass} id="newpass" type="text" className="form-input w-full text-gray-600" required />
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap -mx-3 mb-4">
                                        <div className="w-full px-3">
                                            <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="email">Confirm password</label>
                                            <input ref={confirmpass} id="newpass" type="text" className="form-input w-full text-gray-600" required />
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap -mx-3 mt-6">
                                        <div className="w-full px-3">
                                            <button type='button' className="btn text-white bg-purple-600 hover:bg-purple-700 w-full"
                                                onClick={() => handleResetPassword()}
                                            >Change my Password</button>
                                        </div>
                                    </div>
                                </form>
                                <div className="text-gray-400 text-center mt-6">
                                    <Link to="/en/signin" className="text-purple-600 hover:text-gray-200 transition duration-150 ease-in-out">Cancel</Link>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

            </main>
            {showNotification &&
                <Notification
                    info={info}
                />
            }

        </div>
    );
}

export default NewPassword;