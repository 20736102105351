import { Box } from "@mui/material";
import React, { useEffect, useRef, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import _R from "../../components/api";
import Header from "../../components/Header";
import TextInfo from "../../components/TextInfo";
import Loading from "../layouts/Loading"
import { AuthContext } from "../context/authContext"
import { EditContext } from "../context/editContext"
import { SettingsContext } from "../context/settingsContext"
import Select from 'react-select';

const optionsYN = [
    {
        value: 0,
        label: 'No',
    }, {
        value: 1,
        label: 'Yes',
    }
]

const optionsTime = [
    {
        value: 1,
        label: '08:00 - 10:00',
    }, {
        value: 2,
        label: '10:00 - 12:00',
    }, {
        value: 3,
        label: '12:00 - 14:00',
    }, {
        value: 4,
        label: '14:00 - 16:00',
    }, {
        value: 5,
        label: '17:00 - 19:00',
    }, {
        value: 6,
        label: 'Saturday 08:00 - 15:30',
    }, {
        value: 7,
        label: 'Thursday 08:00 - 15:30',
    }, {
        value: 8,
        label: 'Saturday and Thursday 08:00 - 12:00',
    },
]

const ShowUser = () => {
    const nav = useNavigate()
    const upload = useRef(null)
    const { user } = useContext(AuthContext);
    const { load, setload } = useContext(SettingsContext);
    const { editable_data, initialize, setData } = useContext(EditContext);
    const { id } = useParams();
    const [user_, setUser_] = useState(null)
    const [edit, setEdit] = useState(false)
    const [picture, setPicture] = useState(null)
    const [picturePreview, setPicturePreview] = useState(null)
    const [selectedOption, setSelectedOption] = useState(null);
    const observation = useRef()


    //--------------
    const [typeUser, setTypeUser] = useState('student')
    const [langue, setLangue] = useState(1)
    const [passTest, setPassTest] = useState(0)
    const [status, setStatus] = useState(0)
    const [times, setTimes] = useState(null)
    const [isUniv, setIsUniv] = useState(0)
    //--------------

    const [languages, setLanguages] = useState(null)

    const handleconfirmDemand = async () => {
        let form = {
            typeUser, status, langue, passTest, times, isUniv, id
        }
        const token = localStorage.getItem('ceil_token')

        setUser_(null)
        try {
            const res = await _R.post('/complete-registration', form, {
                headers: {
                    "X_CEIL_TOKEN": token
                }
            })

            console.log(form)
            nav(`/admin/users`)


        } catch (error) {
            console.log(error.response.data)
        }

    }

    const handleChange = (type) => {
        switch (type.label) {
            case 'typeUser':
                setTypeUser(type.value)
                break;
            case 'langue':
                setLangue(type.value)
                break;
            case 'passTest':
                setPassTest(type.value)
                break;
            case 'status':
                setStatus(type.value)
                break;
            case 'times':
                setTimes(type.value)
                break;
            case 'isUnivStudent':
                setIsUniv(type.value)
                break;
            default:
                break;
        }
    }


    useEffect(() => {
        _R.get('/getUser', { params: { id } }).then(response => {

            setUser_(response.data)
            setPicture(`/en/images/users/${response.data.picture}`)
            console.log(response.data.picture)
        })
        _R.get('/alllanguages').then(response => {
            console.log(response.data)
            setLanguages(response.data)

        })
    }, [])

    const handleEdit = () => {
        setEdit(true)
    }

    const createForm = () => {
        let data = {
            id,
            fields: {}
        }
        let promises = []
        return new Promise(res => {
            editable_data.fields.forEach(f => {
                promises.push(
                    new Promise((resolve, reject) => {
                        data["fields"][f.name] = f.value
                        resolve()
                    })
                )
            })
            Promise.all(promises).then(() => {
                if (picturePreview) {
                    data["fields"].picture = upload.current.target.files[0]
                }

                console.log(data)
                res(data)
            })
        })

    }

    const handleSave = () => {
        setUser_(null)
        createForm().then(data => {
            console.log(data)
            _R.post('/updateUser', data).then(response => {
                console.log(response.data)
                setUser_(response.data)
                setEdit(false)
                initialize()
            })
        })

    }
    const handleSendResponse = (accept = false) => {
        let link = accept ? '/acceptAccount' : '/rejectAccount'
        _R.post(link, { id, text: observation.current.value }).then(response => {
            console.log(response.data)
            window.location.reload()
        })

    }
    const handleApprouve = () => {
        let status = user_.document_status == 1 ? 0 : 1
        _R.post('/setUserApprouved', { id, status }).then(response => {
            window.location.reload()
        }).catch(err => {
            console.log(err.response.data)
        })
    }
    const handleSelection = (t) => {
        let _times = []
        //
        t.forEach(element => {
            _times.push(element.value)

        });
        handleChange({ label: 'times', value: (_times) })
    }


    return (
        user_ ?
            <>
                <Box m="20px">
                    <Header title={'User Information'} />
                    <div className="text-right">
                        {
                            edit ?
                                <button className='ml-4 btn-sm text-white bg-red-800 hover:bg-red-900' onClick={() => handleSave()}>Save</button>
                                :
                                <button className='ml-4  btn-sm text-white bg-green-800 hover:bg-green-900' onClick={() => handleEdit()}>Edit</button>
                        }
                    </div>
                    <div className="max-w-7xl mx-auto">
                        <h6 className="text-lg text-green-500 m-2 mt-5 font-bold">General informations </h6>
                        <div className="flex  items-center flex-col	 lg:flex-row">
                            <div className="flex p-2">
                                <div className="p-3 rounded-full overflow-hidden border border-slate-500 cursor-pointer hover:bg-black w-[150px] h-[150px] "
                                    onClick={() => {
                                        edit && upload.current.click()
                                    }}
                                >
                                    <img className="rounded-full w-full h-full" src={picturePreview ? picturePreview : picture} alt="" />
                                </div>
                                <input ref={upload} type="file" name="file" id="file" className="hidden" onChange={(e) => {
                                    const objectUrl = URL.createObjectURL(e.target.files[0])
                                    setPicturePreview(objectUrl)
                                }} />
                            </div>
                            <div className="flex-auto px-3 ">
                                <div className="flex-1">
                                    <div className="flex flex-col  lg:flex-row ">
                                        <TextInfo model={'user'} name={'firstname'} edit={edit} label={'First Name'} value={user_.firstname} flex={'basis-1/4'} />
                                        <TextInfo model={'user'} name={'lastname'} edit={edit} label={'Last Name'} value={user_ && user_.lastname} flex={'basis-1/4'} />
                                        <TextInfo label={'Email'} value={user_ && user_.email} flex={'basis-2/4'} />
                                    </div>
                                    <div className="flex flex-col lg:flex-row">
                                        <TextInfo model={'user'} name={'birthday'} edit={edit} label={'Birthday'} value={user_ && user_.birthday} flex={'basis-1/3'} />
                                        <TextInfo model={'user'} name={'birthplace'} edit={edit} label={'Birthplace'} value={user_ && user_.birthplace} flex={'basis-1/3'} />
                                        <TextInfo label={'Phone'} value={user_ && user_.phone} flex={'basis-1/3'} />
                                    </div>
                                    <div className="flex flex-col lg:flex-row">
                                        <TextInfo model={'user'} name={'address'} edit={edit} label={'Address'} value={user_ && user_.address} flex={' basis-1/1 w-full'} />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    {
                       user_ && user_.document_status == 2 ?
                       <div className="max-w-7xl mx-auto">
                                <div className="flex flex-col pb-3 lg:flex-row relative items-start">
                                    <div className={`flex-auto p-1`}>
                                        <div className="w-full ">
                                            <label htmlFor="">Observation: </label>
                                            <textarea className="w-full text-gray-800" ref={observation} name="observation" id="observation" cols="30" rows="1"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col pb-3 lg:flex-row relative items-start">
                                    <div className={`basis-1/2 p-1`}>
                                        <div className="w-full ">
                                            <button onClick={() => handleSendResponse(true)} className="btn bg-green-800 hover:bg-green-950 w-full">Accept</button>
                                        </div>
                                    </div>
                                    <div className={`basis-1/2 p-1`}>
                                        <div className="w-full ">
                                            <button onClick={() => handleSendResponse()} className="btn bg-red-900 hover:bg-red-950 w-full">Reject</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            user_ && user_.document_status == 1 && <>
                                <div className="flex-col p-2 my-2 lg:flex-row relative text-center text-red-300 bg-[#d9595936] ">
                                    <div className={`basis-auto p-1`}>
                                        <div className="w-full ">
                                            <h4>Demand rejected</h4>
                                            <p>{user_.observation}</p>
                                        </div>
                                    </div>

                                </div>
                            </>
                    }
                   
                </Box>
{
            /*    <div className="m-8 max-w-7xl mx-auto">
                    <h6 className="text-lg text-green-500 m-2 mt-5 font-bold">Make a demand </h6>
                    <div className="flex px-3">
                        <EditDrop onChange={handleChange} idenf={'langue_id'} description='Langue' values={languages} />
                        <EditDrop onChange={handleChange} idenf={'status'} description='Reregistration' values={optionsYN} />
                        <EditDrop onChange={handleChange} idenf={'passTest'} description='Pass Test' values={optionsYN} />
                        <EditDrop onChange={handleChange} idenf={'isUnivStudent'} description='university Student' values={optionsYN} />
                    </div>
                    <div>
                        <div className=" col-span-6 sm:col-span-6 relative">
                            <label htmlFor="" className="block text-sm font-medium text-gray-200">
                                Set Times ordered :
                            </label>
                            <Select
                                isMulti
                                classNamePrefix="sel"
                                className=" mt-1 rounded-sm  border-gray-200 bg-white text-gray-800 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-md"
                                onChange={handleSelection}
                                options={optionsTime}
                            />
                            <label htmlFor="" className=" absolute text-red-400 text-sm font-bold -bottom-5"></label>
                        </div>
                    </div>
                    <div className="pt-4 text-right px-3">
                        <button className='ml-4 btn-sm text-white bg-green-800 hover:bg-green-900' onClick={() => handleconfirmDemand()}>Confirm</button>
                    </div>
                </div>*/
}
            </>
            :
            
        <div>
        <Loading />
        </div>
    )
}
const EditDrop = ({ idenf, description, values = false, onChange }) => {
    useEffect(() => {

    }, [])
    return (
        <div className="basis-1/3 p-1">
            <div className="flex items-center bg-slate-900 border rounded-md h-[50px] py-2 px-4 border-lime-300 shadow-[0_0_10px_1px_rgb(44,251,12,0.47)]">
                <label htmlFor={idenf} className="mr-1 text-teal-500 text-xs text-opacity-90 font-semibold">{description}?</label>
                <select
                    name={idenf} id={idenf} className="flex-auto bg-transparent text-gray-100 border-none focus:outline-none "
                    onChange={(e) => {
                        onChange({ label: idenf, value: e.target.value })
                    }}

                >
                    {
                        values &&
                        values.map((val, index) => {
                            return (
                                <option value={val.value} className="bg-white-500  text-gray-800 hover:opacity-60 p-2">{val.label}</option>
                            )
                        })
                    }

                </select>
            </div>
        </div>
    )
}
export default ShowUser; 