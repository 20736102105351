import React, { useContext, useState } from 'react';
import TitleSection from './utils/TitleSection';
import FormUpdateDemand from './FormUpdateDemand';
import _R from '../../../components/api';
import { EditContext } from "../../context/editContext"
import Notification from '../../global/Notification';
import { useNavigate } from "react-router-dom";



export default function FormComplete(props) {
  const { me, editable_data} = useContext(EditContext)
  const navigate = useNavigate()

  const [showNotification, setShowNotification] = useState(false);
  const [error, setError] = useState('')

  const createForm = (formData) => {

    return new Promise(res => {
      formData.append('id',me.inscription_id)
      let data = {
        id: me.inscription_id,
        fields: {}
      }
      let promises = []
      if (editable_data) {
        editable_data.fields.forEach(f => {
          promises.push(
            new Promise((resolve, reject) => {
              data["fields"][f.name] = f.value
              formData.append(`${f.name}`, f.value);
              console.log(f.name, f.value)
              console.log(formData)
              resolve(formData)
            })
          )
        })
        Promise.all(promises).then(() => {
          res(formData)
        })
      }
      else {
        res(formData)
      }
    })
  }

  const handleConfirm = async () => {
    const formData = new FormData();
    let data = await createForm(formData)
      console.log(data)
     setShowNotification(false)
     const token = localStorage.getItem('ceil_token')
     try {
       const res = await _R.post('/UpdateDemand', data, {
         headers: {
           "X_CEIL_TOKEN": token
         }
       })
       console.log(res.data)
       navigate('/en/me') 
     } catch (error) {
       setError(error.response.data.message)
       setShowNotification(true)
       console.log(error.response.data)
     }
   
  }

  return (
    <>
      <div className=" sm:mt-0 p-4 bg-white rounded-sm shadow-sm">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          {/* title */}
          <TitleSection
            title={`Edit`}
            desc={'Please choose carefully .'}
            titleTextColor="text-gray-600"
            descTextColor="text-gray-500"
          />
          <div className="mt-5 md:col-span-2 md:mt-0 text-gray-800">
            <form action="#" method="POST">
              <div className=" sm:rounded-sm">

                <FormUpdateDemand typeDemand={props.typeDemand}  error={error} />

                <div className={`px-4 py-3 text-right sm:px-6 flex justify-end`}>

                  <button
                    type="button"
                    onClick={() => {
                      handleConfirm()
                    }
                    }
                    className="inline-flex justify-center rounded-md border border-transparent bg-green-600 py-3 px-10 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Next
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {showNotification &&
        <Notification
          info={
            {
              type: 'err',
              message: error
            }

          }
        />
      }
    </>
  )
}
