import { Autocomplete, Box, Button, TextField, Typography, useTheme } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import { AccountCircle, AlternateEmail } from "@mui/icons-material";
import React, { useEffect, useContext, useState } from "react";
import _R from "../../components/api";
import { useNavigate } from "react-router-dom";
import { NotificationContext } from "../context/notificationContext"


const Adduser = () => {
  const {setNotification} = useContext(NotificationContext)

  const navigate = useNavigate()
  useEffect(() => {
    _R.get('/getRoles').then((res) => {
      console.log(res.data)
      setRoles(res.data)
    })
  }, [])
  const [roles, setRoles] = useState([])
  const [userRole, setUserRole] = useState([])
  const [newDate, setnewDate] = useState("01/01/1980")
  const [profileImage, setProfileImage] = useState("../assets/defaultProfile.png")
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)
  const isNonMobile = useMediaQuery("(min-width:800px)");

  const handleFormSubmit = (values) => {
    values.role = userRole
    _R.post("/adduser", values).then(res => {
      setNotification({ status: true, message: 'user was created !', type: 'suc' })
      navigate('/admin/users')
      console.log(res);
    })
  };

  const handleChangeSelect = (e, value) => {
    setUserRole(value)
    // console.log(value)
  }

  const handleChangee = (newV, values) => {

    values.birthday = newV
  }
  const FieldText = ({ name, label, grid, values, handleChange, type = "text" }) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
        <AccountCircle sx={{ color: `${colors.primary[300]}`, mr: 1, my: 0.5 }} />
        <TextField
          fullWidth
          onChange={handleChange}
          defaultValue={values[name]}
          name={name}
          label={label}
          type={type}
          variant="standard"
          sx={{
            "& .MuiFormLabel-root": { color: `${colors.gray[200]} !important` },
            "& .Mui-focused::after": { borderBottom: `1px solid ${colors.primary[400]} !important` },
          }}
        />
      </Box>)

  }

  return (
    <Box m="20px">
      <Header title="CREATE USER" subtitle="Create a New User Profile" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
      //validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                m: "20px",
              }}
            >
              <Box sx={{ gridColumn: "span 2", gridRow: "1 / 3 ", margin: "0 auto" }}>
                <img style={{ borderRadius: "50%", background: "#fff", opacity: ".6" }} width="150px" height="150px" src={profileImage} alt="" />
              </Box>
              <Box sx={{ gridColumn: "span 2" }}>
                <FieldText
                  name="firstname"
                  label="First Name"
                  values={values}
                  handleChange={handleChange}
                />
              </Box>
              <Box sx={{ gridColumn: "span 2" }}>
                <FieldText
                  name="lastname"
                  label="Last Name"
                  values={values}
                  handleChange={handleChange}
                />
              </Box>
              <Box sx={{ gridColumn: "span 2" }}>
                <FieldText
                  name="email"
                  label="Email"
                  values={values}
                  handleChange={handleChange}
                />
              </Box>
              <Box sx={{ gridColumn: "span 2" }}>
                <div className="flex items-center border-b-[1px]">
                  <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-19wdp2w" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="AccountCircleIcon"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 4c1.93 0 3.5 1.57 3.5 3.5S13.93 13 12 13s-3.5-1.57-3.5-3.5S10.07 6 12 6zm0 14c-2.03 0-4.43-.82-6.14-2.88C7.55 15.8 9.68 15 12 15s4.45.8 6.14 2.12C16.43 19.18 14.03 20 12 20z"></path></svg>
                  <label htmlFor="" className="mr-2">Date of Birth</label>
                  <input onChange={(e) => { values.birthday = e.target.value }} className="flex-auto bg-transparent border-none" type="date" name="birthday" id="" />
                </div>
              </Box>


              <Box sx={{ gridColumn: "span 2" }}>
                <FieldText
                  name="birthplace"
                  label="Place of Birth"
                  values={values}
                  handleChange={handleChange}
                />
              </Box>
              <Box sx={{ gridColumn: "span 2" }}>
                <FieldText
                  name="phone"
                  label="Phone"
                  values={values}
                  handleChange={handleChange}
                />
              </Box>
              <Box sx={{ gridColumn: "span 2" }}>
                <FieldText
                  name="address"
                  label="Address"
                  values={values}
                  handleChange={handleChange}
                />
              </Box>
              <Box sx={{ gridColumn: "span 2" }}>
                <FieldText
                  name="wilaya"
                  label="Wilaya"
                  values={values}
                  handleChange={handleChange}
                />
              </Box>

              {
                //--------------------------- 
              }
              <Box sx={{ gridColumn: "span 2" }}>
                <Typography>
                </Typography>
                <Autocomplete
                  multiple
                  options={roles}
                  getOptionLabel={(option) => option.name}
                  sx={{ display: "flex", alignItems: 'flex-end' }}
                  onChange={handleChangeSelect}
                  renderInput={(params) => (
                    <>
                      <AlternateEmail sx={{ color: `${colors.primary[300]}`, mr: 1, my: 0.5 }} />
                      <TextField
                        {...params}
                        name="roles"
                        variant="standard"
                        value={values.id}
                        label="Roles"
                        placeholder="Role"
                      />
                    </>
                  )}
                />
              </Box>
              <Box sx={{ gridColumn: "span 2" }}>
                <FieldText
                  name="password"
                  label="Password"
                  type="password"
                  values={values}
                  handleChange={handleChange}
                />
              </Box>

              {
                //--------------------------- 
              }

            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="success" variant="contained">
                Create New User
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      
    </Box>

  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  firstName: yup.string().required("required"),
  lastName: yup.string().required("required"),
  email: yup.string().email("invalid email").required("required"),
  /*  contact: yup
     .string()
     .matches(phoneRegExp, "Phone number is not valid")
     .required("required"),
   address1: yup.string().required("required"),
   address2: yup.string().required("required"),*/
});
const initialValues = {
  firstname: "",
  lastname: "",
  email: "",
  address: "",
  phone: "",
  wilaya: "",
  birthday: "",
  birthplace: "",
  password: ""
};

export default Adduser;