import React from 'react'

function Header() {
  return (
    <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2 mb-4">Investing in your progress</h2>
            <p className="text-xl text-gray-400">We believe learning with a tutor is life changing. That's why we match tutors  with learners, and empower them to create personalized classes with tools and learning materials. This is how we deliver progress, create engagement and keep our learners motivated.</p>
          </div>
  )
}

export default Header