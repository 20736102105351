import React from 'react'

function Header() {
    return (
        <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
            <div className="inline-flex text-sm font-semibold py-1 px-3 m-2 text-green-600 bg-green-200 rounded-full mb-4">Reach goals that matter</div>
            <h1 className="h2 mb-4">Unlock your potential with the best language tutors</h1>
            <p className="text-xl text-gray-400">Keep track of your learning progress. Improve your speaking and vocabulary with our Learning plans.</p>
        </div>
    )
}

export default Header