import React, { useState, useContext } from 'react';
import Form from '../partials/forms/Form';
import axios from 'axios'
import { Link, Navigate } from "react-router-dom";
import { AuthContext } from '../../context/authContext';
import { isString } from 'formik';
import _R from '../../../components/api'


function SignUp() {
  const { isAuth, setIsAuth, setUser } = useContext(AuthContext);
  const [firstname, setFirstname] = useState(null)
  const [lastname, setLastname] = useState(null)
  const [phone, setPhone] = useState(1)
  const [birthday, setBirthday] = useState(null)
  const [birthplace, setBirthplace] = useState(null)
  const [address, setAddress] = useState(null)
  const [email, setEmail] = useState(null) 
  const [password, setPassword] = useState(null)
  const [confirm_password, setConfirm_password] = useState(null)
  const [errors, setErrors] = useState(null)
  const [localError, setLocalError] = useState(null)
  const handleChange = (type) => {

    switch (type.label) {
      case 'firstName':
        setFirstname(type.value)
        break;
      case 'lastName':
        setLastname(type.value)
        break;
      case 'phone':
        setPhone(type.value)
        break;
      case 'birthDay':
        setBirthday(type.value)
        break;
      case 'birthPlace':
        setBirthplace(type.value)
        break;
      case 'address':
        setAddress(type.value)
        break;
      case 'email':
        setEmail(type.value)
        break;
      case 'password':
        setPassword(type.value)
        break;
      case 'confirm_password':
        setConfirm_password(type.value)
        break;
      default:
        break;
    }

  }
  const submitting = async () => {
    try {
      const res = await _R.post('/signup', { firstname, lastname, email, birthday, address, password, phone, birthplace, confirm_password })
      localStorage.setItem("ceil_token", res.data.token)
      setUser(res.data.userInfo)
      setIsAuth(true)
    } catch (error) {
      let errors = error.response.data.errors
      if (!isString(error.response.data.errors)) {
        setLocalError(null)
        setErrors(errors[0])
      } else {
        setErrors(null)
        setLocalError(errors)
      }


    }
  }
  return (
    (!isAuth) ?
      <div className="bg-white text-gray-600 min-h-screen md:min-w-full overflow-hidden ">
        <main className="text-gray-800 px-4 md:px-2 max-w-5xl mx-auto mb-5">
          <div className="text-center py-5 mt-5 text-gray-600 rounded-t-md">
              <Link to={'/en'}>
                <img className=" w-20  h-20 fill-current opacity-80 m-auto" src="./ceild.png" alt="" />
              </Link>
              <p className='text-2xl font-semibold mt-2'>Sign up to <span className='text-blue-700 font-bold text-3xl'> CEIL</span> </p>
          </div>
          <div className="">
            <Form onSubmit={submitting} onChange={handleChange} error={errors} localError={localError} />
          </div>
        </main>
      </div>
      :
      <Navigate to="/en" />
  );
}

export default SignUp;