import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import FormComplete from './components/FormComplete';
import { AuthContext } from '../context/authContext';
import ReactToPrint from 'react-to-print';
import Ticket from './components/Ticket';
import { EditContext } from "../context/editContext"
import _R from '../../components/api'

function CompletePage() {

    const { user } = useContext(AuthContext)
    const componentRef = useRef()
    const [finich, setFinich] = useState(false)
    const { me } = useContext(EditContext)
    const [thisInsc, setThisInsc] = useState({})
    const [load, setLoad] = useState(true)

    useEffect(() => {
        console.log(user)
        const setinscr = (data) => {
            return new Promise((resolve) => {
                setThisInsc(data)
                resolve()
            })
        }
        const getIns = async () => {
            const res = await _R.get('/actuInscription', { params: { id: user.user.student_id } })
            const data = await setinscr(res.data)

        }

        if (user.student) {
            getIns().then(() => {
                setLoad(false)
                console.log(thisInsc)
                console.log(load)
            })
        } else {
            setLoad(false)
        }

    }, [])
    return (

        load ?
            <>
                <div style={{ display: 'grid', placeItems: 'center' }} className='text-gray-500 text-xl font-bold'>Waiting...</div>
            </>
            :

            <main className="text-gray-800 px-2 py-5 max-w-5xl mx-auto">
                <div className=" text-center  p-4 border-b bg-gradient-to-r from-[#005b68] from-40% to-[#005034] rounded-md ">
                    <h1 className="h3 mb-2 text-white">Welcome Back ! </h1>
                    <h1 className="text-md   text-gray-200 px-5">We have always accompanied students in their university course</h1>
                </div>
                <div className='text-gray-800 py-5'>
                    {
                        (Object.keys(thisInsc).length == 0 && !finich) ?
                            <FormComplete typeDemand={false} setFinich={() => setFinich(true)} />
                            :
                            ((thisInsc.document_status == 1 || thisInsc.document_status == 2)) ?
                                thisInsc.document_status == 2 ?
                                    <div className='text-center p-2 bg-yellow-300'>
                                        <h4 className='text-yellow-700'>Pending</h4>
                                        <p>Please wait while your request is reviewed</p>
                                    </div>
                                    :
                                    <>
                                        <div>
                                            <h6>Your demand is rejected</h6>
                                            <p>for this reasons {thisInsc.observation}</p>
                                        </div>
                                    </>

                                :

                                <div className='flex flex-col justify-center'>
                                    <h1 className='text-4xl font-bold text-gray-800 text-center pb-5'>Congratulations!</h1>
                                    <div className='text-center pb-5'>
                                        <ReactToPrint
                                            trigger={() => <button className=' btn-sm text-white bg-blue-800 hover:bg-blue-900'>Print</button>}
                                            content={() => componentRef.current}

                                        />
                                        {
                                            <Ticket
                                                ref={componentRef}
                                                inscription_id={user.user.inscription_id}
                                            />
                                        }
                                    </div>
                                    <div >
                                        <h4 className='text-xl font-bold text-gray-600 pb-5'>Students are required to provide the following documents:</h4>
                                        <ul className='text-lg  text-gray-500 list-decimal'>
                                            <li className='pb-3 ml-5'>the registration voucher (Print it by the button above or check your email)</li>
                                            <li className='pb-3 ml-5'>02 Photos</li>
                                            <li className='pb-3 ml-5'>A copy of the registration certificate or student card</li>
                                            <li className='pb-3 ml-5'>A copy of the identity card.</li>
                                            <li className='pb-3 ml-5'>Registration fees</li>
                                        </ul>
                                    </div>
                                </div>
                    }
                </div>
            </main>

    );
}

export default CompletePage;