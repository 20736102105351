import axios from "axios";
import React, { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import _R from "../../components/BaseUrl"


export const AuthContext = createContext(false)

export const AuthProvider = ({ children }) => {
    const nav = useNavigate()
    const [isAuth, setIsAuth] = useState(null);
    const [user, setUser] = useState(null);
    const [loginError, setLoginError] = useState(null);

    const login = async (email, password) => {
        try {
            if (!email) {
                setLoginError("Email can't be empty");
            } else {
                if (!password) {
                    setLoginError("Password can't be empty");
                } else {
                    const res = await _R.post("/login", { email, password })
                    localStorage.setItem("ceil_token", res.data.token)
                    setUser(res.data.userInfo)
                    setIsAuth(true);
                }
            }

        } catch (error) {
            setLoginError(error.response.data.message)
            console.log(error.response.data)
        }

    };

    const logout = () => {
        localStorage.removeItem("ceil_token")
        setIsAuth(false);
        nav('/')
    };

    return (
        <AuthContext.Provider value={{ isAuth, setIsAuth, user, setUser, login, logout, loginError }}>
            {children}
        </AuthContext.Provider>
    );
};


