import { Box } from "@mui/material";
import React, { useEffect, useState, useCallback, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import _R from "../../components/api";
import Header from "../../components/Header";
import TextInfo from "../../components/TextInfo";
import Loading from "../layouts/Loading"
import TableStudents from "../../components/TableStudents"
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { Modal } from "flowbite-react";
import Dropd from "../landing_page/utils/Dropd";
import { FilterContext } from "../context/filterContext"

const optionsTime = [
    {
        value: 1,
        label: '08:00 - 10:00',
    }, {
        value: 2,
        label: '10:00 - 12:00',
    }, {
        value: 3,
        label: '12:00 - 14:00',
    }, {
        value: 4,
        label: '14:00 - 16:00',
    }, {
        value: 5,
        label: '17:00 - 19:00',
    }, {
        value: 6,
        label: 'Saturday 08:00 - 15:30',
    }, {
        value: 7,
        label: 'Thursday 08:00 - 15:30',
    }, {
        value: 8,
        label: 'Saturday and Thursday 08:00 - 12:00',
    },
]

const ShowGroup = () => {

    const measuredRef = useCallback(node => {
        if (node !== null) {
            setNoode(node);
        }
    }, [])
    const form = useCallback(node => {
        if (node !== null) {
            setNodeForm(node);
        }
    }, [])

    const columns = [
        { field: "id", use: "ID", type: "integer" },
        { field: "examain_id", use: "ExamainID", type: "hidden", show: true },
        { field: "firstname", use: "First Name", type: "string", show: true },
        { field: "lastname", use: "Last Name", type: "string", show: true },
        { field: "email", use: "Email", type: "string", color: 'text-yellow-400 font-bold' },
        { field: "dateAdd", use: "Created_date", type: "date" },
        { field: "timeAdd", use: "Created_time", type: "date" },
        { field: "action", use: "Action", type: "button" }
    ]
    const { filter } = useContext(FilterContext)
    const [noode, setNoode] = useState(null)
    const [nodeForm, setNodeForm] = useState(null)
    const { id } = useParams();
    const [group, setGroup] = useState(null)
    const [groupId, setGroupId] = useState(null)
    const [inscId, setInscId] = useState(null)
    const [times, setTimes] = useState([])
    const [groups, setGroups] = useState([])
    const [loading, setLoading] = useState(true)
    const [filterStudents, setFilterStudents] = useState(null)
    const [showModel, setShowModel] = useState(false)
    const [showModelDelete, setShowModelDelete] = useState(false)
    const [marksModel, setMarksModel] = useState(false)
    const [file, setFile] = useState(null)
    const [typeTest, setTypeTest] = useState(null)

    useEffect(() => {
        _R.get('/getGroupInfo', { params: { id } }).then((res) => {
            console.log(res)
            setGroup(res.data.group)
            setFilterStudents(res.data.users)
            setLoading(false)

        })
    }, [])

    const setModel = (inscription_id, times) => {
        console.log(times)
        setLoading(true)
        setInscId(inscription_id)
        setTimes(times)
        _R.get('/getOtherGroups', { params: { id } }).then((res) => {
            console.log(res.data)
            setGroups(res.data)
            setShowModel(id)
            setLoading(false)
        })
    }

    const handleDelete = () => {
        _R.post('/unsetStudentGroup', { id: showModelDelete }).then((res) => {
            window.location.reload()
            console.log(res.data)
        })
    }

    const handleChange = () => {
        if (groupId) {
            setLoading(true)
            _R.post('/updateStudentGroup', { group: groupId, inscription_id: inscId }).then((res) => {
                window.location.reload()
            })
        }
    }

    const filterGroups = (data) => {
        return data
    }

    const handleUpload = (e) => {
        console.log(e.target.files[0])
        setFile(e.target.files[0])
    }

    const handleSetMarks = async () => {
        var formData = new FormData()
        formData.append('file', file)
        formData.append('type', typeTest)
        try {
            const result = await _R.post('/setMarks', formData)
            window.location.reload()
        } catch (error) {
            console.log(error)
        }

    }

    return (
        group && !loading ?
            <Box m="20px">
                <Header title={'Group Information'} />
                <div className="  mt-10 px-3 overflow-auto max-w-7xl mx-auto overflow-hidden" style={{ maxHeight: "calc(100vh - 180px)" }} >
                    <h6 className="text-lg text-green-500 m-2 mt-4 font-bold">Group informations </h6>
                    <div className="flex-1">
                        <div className="flex flex-col  lg:flex-row ">
                            <TextInfo label={'Name'} value={group.name} flex={'basis-1/3'} />
                            <TextInfo label={'Language'} value={group.langue} flex={'basis-1/3'} />
                            <TextInfo label={'Level'} value={group.level} flex={'basis-1/3'} />
                        </div>
                        <div className="flex flex-col lg:flex-row">
                            <TextInfo label={'Time'} value={group.time} flex={'basis-1/3'} />
                            <TextInfo label={'Room'} value={group.room} flex={'basis-1/3'} />
                            <TextInfo label={'Number of students'} value={group.count} flex={'basis-1/3'} />
                        </div>
                    </div>
                    <h6 className="text-lg text-green-500 m-2 mt-5 font-bold">Group students </h6>
                    <DownloadTableExcel
                        filename="students group table"
                        sheet="students group"
                        currentTableRef={noode}
                    >
                        <button className=" btn bg-blue-600 hover:bg-blue-700 p-1 text-blue-100 text-md"> Export excel </button>
                    </DownloadTableExcel>
                    <DownloadTableExcel
                        filename="students table"
                        sheet="students"
                        currentTableRef={nodeForm}
                    >
                        <button className=" btn bg-blue-600 hover:bg-blue-700 p-1 text-blue-100 text-md ml-2"> Export formulaire </button>
                    </DownloadTableExcel>
                    <button className="p-4 bg-yellow-500 text-white text-sm border-none hover:bg-yellow-600 ml-2"
                        onClick={() => setMarksModel(true)}
                    >
                        Set marks
                    </button>
                    <div ref={form} className="hidden">
                        <table className="table w-full " >
                            <thead className=" bg-slate-700  text-left sticky -top-5 text-slate-400 z-10">
                                <tr >
                                    {
                                        columns.map((column, index) => (
                                            column.show && <th
                                                key={index}
                                                scope="col"
                                                className={`px-2 py-3 text-left text-sm font-medium text-slate-400 uppercase tracking-wider hover:cursor-pointer border border-slate-600 `}
                                            >
                                                {column.use}
                                            </th>
                                        ))
                                    }
                                    {
                                        (['speaking_mark', 'reading_mark', 'listening_mark', 'written_mark', 'evaluation_mark']).map((column, index) => (
                                            <th
                                                key={index}
                                                scope="col"
                                                className={`px-2 py-3 text-left text-sm font-medium text-slate-400 uppercase tracking-wider hover:cursor-pointer border border-slate-600 `}
                                            >
                                                {column}
                                            </th>
                                        ))
                                    }

                                </tr>
                            </thead>
                            <tbody className=" text-sm text-slate-100">
                                {
                                    filterStudents.map((row, index) => (
                                        <tr key={index} className='hover:bg-[#ffffff20]'>
                                            <td>{row.examain_id}</td>
                                            <td>{row.firstname}</td>
                                            <td>{row.lastname}</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <div ref={measuredRef} className="flex-1">
                        <p className="hidden">students list of {group.name}</p>
                        <TableStudents
                            rows={filterStudents}
                            columns={columns}
                            setRows={setFilterStudents}
                            filter={filter}
                            action={[
                                {
                                    "button": "view",
                                    "link": '/en/admin/students/show/'
                                },
                                {
                                    "button": "update",
                                    "edit": setModel
                                },
                                {
                                    "button": "test",
                                    "link": '/en/admin/pedagogy/examain/show/'
                                },
                                {
                                    "button": "delete",
                                    "delete": setShowModelDelete
                                },

                            ]}
                        />
                    </div>
                </div>
                <>
                    <Modal
                        show={showModel}
                        size="3xl"
                        popup={true}
                        onClose={() => setShowModel(null)}
                    >
                        {<Modal.Header className="bg-slate-800" />}
                        <Modal.Body className="bg-slate-700 text-gray-400">
                            <div className=" space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8">
                                <h3 className="text-lg font-medium pt-5 ">{`Select the group you want to move the student to.`}</h3>
                                <div className="flex flex-col">
                                    <Dropd
                                        label={'Choose a Group'}
                                        id="group"
                                        name="group"
                                        class={'sm:col-span-6 text-black mb-2'}
                                        options={filterGroups(groups)}
                                        onChange={(e) => setGroupId(e.value)}
                                    />
                                    <div className="  border-teal-500  border py-2 px-4 rounded-lg ">
                                        <label htmlFor="" className=" text-slate-400 text-xs">Times</label>
                                        <ul className=" mt-2 ml-2 text-sm text-slate-200 list-decimal">
                                            {
                                                times.map((option, index) => {
                                                    return (
                                                        <li key={index} className="mb-1 text-sm text-slate-300 list-decimal">
                                                            {optionsTime[option - 1].label}
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>

                                {groups.length > 0 &&
                                    <div className="w-full text-right">
                                        <button onClick={() => handleChange()} className="btn bg-green-600 text-gray-100 hover:bg-green-700 ">
                                            Move
                                        </button>
                                    </div>}
                            </div>
                        </Modal.Body>
                    </Modal>
                </>

                <>
                    <Modal
                        show={showModelDelete}
                        size="3xl"
                        popup={true}
                        onClose={() => setShowModelDelete(null)}
                    >
                        <Modal.Header className="bg-slate-800" />
                        <Modal.Body className="bg-slate-700 text-gray-400">
                            <div className=" space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8">
                                <h3 className="text-lg font-medium pt-5 ">{`The student will be removed from this group and his/her validation in the session will be cancelled.`}</h3>
                                <p>{`Do you want to continue.`}</p>
                                <div className="w-full text-right">
                                    <button onClick={() => handleDelete()} className="btn bg-yellow-500 text-gray-100 hover:bg-yellow-600 ">
                                        Delete
                                    </button>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </>

                <>
                    <Modal
                        show={marksModel}
                        size="3xl"
                        popup={true}
                        onClose={() => setMarksModel(null)}
                    >
                        {<Modal.Header className="bg-slate-800" />}
                        <Modal.Body className="bg-slate-700 text-gray-400">
                            <div className=" space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8">
                                <h3 className="text-lg font-medium pt-5 ">{`Select the type of test.`}</h3>
                                <div className="flex flex-col">
                                    <Dropd
                                        label={'Choose a type'}
                                        id="group"
                                        name="group"
                                        class={'sm:col-span-6 text-black mb-2'}
                                        options={types}
                                        onChange={(e) => setTypeTest(e.value)}
                                    />

                                </div>
                                <div>
                                    <input onChange={(e) => handleUpload(e)} type="file" name="file" />
                                </div>

                                {
                                    <div className="w-full text-right">
                                        <button onClick={(e) => handleSetMarks(e)} className="btn bg-green-600 text-gray-100 hover:bg-green-700 ">
                                            validate
                                        </button>
                                    </div>}
                            </div>
                        </Modal.Body>
                    </Modal>
                </>
            </Box>
            :
            
        <div>
        <Loading />
        </div>
    )

}

const types = [
    {
        value: 'oral_mark',
        label: 'Oral test'
    },
    {
        value: 'written_mark',
        label: 'Written test'
    },
    {
        value: 'evaluation_mark',
        label: 'Evaluation test'
    }
]

export default ShowGroup; 
