import { Box } from "@mui/material";
import Header from "../../components/Header";
import React, { useEffect, useState, useCallback, useContext, useRef } from "react";
import Table from "../../components/Table";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { Link } from "react-router-dom";
import { Modal } from "flowbite-react";
import _R from "../../components/api";
import Loading from "../layouts/Loading";
import { FilterContext } from "../context/filterContext"
import HeaderSection from "../global/headerSection";

const Examain = (props) => {
    const measuredRef = useCallback(node => {
        if (node !== null) {
            setNoode(node);
        }
    }, []);
    const form = useCallback(node => {
        if (node !== null) {
            setNodeForm(node);
        }
    }, [])
    useEffect(() => {
        _R.get('/getStudents').then((res) => {
            console.log(res.data)
            setFilterStudents(res.data)
        })
    }, [])
    const { filter } = useContext(FilterContext)
    const [nodeForm, setNodeForm] = useState(null)
    const [noode, setNoode] = useState(null)
    const [filterStudents, setFilterStudents] = useState(null)
    const [showModel, setShowModel] = useState(false)
    const [file, setFile] = useState(null)

    const [columns, setColumns] = useState([
        { field: "id", subfield: "", use: "ID", type: 'integer', show: true },
        { field: "inscription_id", subfield: "", use: "inscription_id", type: 'hidden' },
        { field: "firstname", subfield: "", use: "First Name", type: 'string', show: true },
        { field: "lastname", subfield: "", use: "Last Name", type: 'string', show: true },
        { field: "group", subfield: "", use: "Group", type: 'string' },
        { field: "examin", subfield: "speaking_mark", use: "Speaking", type: 'string', color: "text-yellow-400", show: true },
        { field: "examin", subfield: "reading_mark", use: "Reading", type: 'string', color: "text-yellow-400", show: true },
        { field: "examin", subfield: "listening_mark", use: "Listening", type: 'string', color: "text-yellow-400", show: true },
        { field: "examin", subfield: "oral_mark", use: "Oral", type: 'string', color: "text-yellow-300" },
        { field: "examin", subfield: "written_mark", use: "Written", type: 'string', color: "text-yellow-300", show: true },
        { field: "examin", subfield: "evaluation_mark", use: "Evaluation", type: 'string', color: "text-yellow-300", show: true },
        { field: "examin", subfield: "result", use: "Result", type: 'string', color: "text-green-400" },
        { field: "examin", subfield: "avis", use: "Avis", type: 'string', color: "text-green-400" },
        { field: "action", subfield: "", use: "Action", type: 'button' }
    ])

    const handleUpload = (e) => {
        console.log(e.target.files[0])
        setFile(e.target.files[0])
    }



    const handleChange = (e, id, type, index) => {
        console.log(id)
        let url = "/setExamain"
        let data = e.target.innerText
        try {
            _R.post(url, { id, type, data }).then(resp => {
                let d = [...filterStudents]
                d[index].examin = resp.data
                setFilterStudents(d)
                console.log(resp.data)
            })
        } catch (error) {
            console.log(error)
        }
    }
    const handleSetMarks = async () => {
        var formData = new FormData()
        formData.append('file', file)
        // formData.append('type', typeTest)
        try {
            const result = await _R.post('/setMarks', formData)
            window.location.reload()
        } catch (error) {
            console.log(error)
        }

    }
    const showHideColumns = [['FirstName', 1, true], ['LastName', 2, true], ['Language', 4, true]]

    return (
        filterStudents ?
            <Box m="20px">
                <HeaderSection
                    table={{
                        name: "Examain",
                        headerTitle: "Examain",
                        headerSubTitle: "Manage all students .."
                    }}
                    download={true}
                    noode={noode ? [{ node: noode, title: "Export Excel", sheet: "examins", filename: "examins" }, { node: nodeForm, title: "Export Excel form", sheet: "formulaire", filename: "formulaire" }] : null}
                    links={[{
                        setShowMode: setShowModel,
                        label: ' file Marks'
                    }]}
                    showHideColumns={{
                        showHideColumns: showHideColumns,
                        setColumns: setColumns,
                        columns: columns
                    }}
                    count={{ label: 'Demand', filterStudents }}
                />
                {
                    /*
                    <Box textAlign="right">
                        <Link onClick={() => setShowModel(true)} to="" className="btn bg-green-600 hover:bg-green-700 p-1 text-green-100 text-md mx-2" >
                            file Marks
                        </Link>
                        <DownloadTableExcel
                            filename="students table"
                            sheet="students"
                            currentTableRef={noode}
                        >
                            <button className=" btn bg-blue-600 hover:bg-blue-700 p-1 text-blue-100 text-md mx-2"> Export excel </button>
                        </DownloadTableExcel>
                        <DownloadTableExcel
                            filename="students table"
                            sheet="students"
                            currentTableRef={nodeForm}
                        >
                            <button className=" btn bg-blue-600 hover:bg-blue-700 p-1 text-blue-100 text-md"> Export formulaire </button>
                        </DownloadTableExcel>
                    </Box>
                    */
                }
                <div ref={form} className="hidden">
                    <table className="table w-full " >
                        <thead className=" bg-slate-700  text-left sticky -top-5 text-slate-400 z-10">
                            <tr >
                                {
                                    columns.map((column, index) => (
                                        column.show &&
                                        <th
                                            key={index}
                                            scope="col"
                                            className={`px-2 py-3 text-left text-sm font-medium text-slate-400 uppercase tracking-wider hover:cursor-pointer border border-slate-600 `}
                                        >
                                            {column.use}
                                        </th>
                                    ))
                                }
                            </tr>
                        </thead>
                        <tbody className=" text-sm text-slate-100">
                            {
                                filterStudents.map((row, index) => (
                                    <tr key={index} className='hover:bg-[#ffffff20]'>
                                        <td>{row.examin ? row.examin.id : null}</td>
                                        <td>{row.firstname}</td>
                                        <td>{row.lastname}</td>
                                        <td>{row.birthday}</td>
                                        <td>{row.birthplace}</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                <div ref={measuredRef}>
                    <Table
                        model='examain'
                        rows={filterStudents}
                        columns={columns}
                        setRows={setFilterStudents}
                        filter={filter}
                        change={handleChange}
                        action={[
                            {
                                "button": "view",
                                "link": '/en/admin/pedagogy/examain/show/'
                            },
                        ]}
                    />
                </div>
                <>
                    <Modal
                        show={showModel}
                        size="md"
                        popup={true}
                        onClose={() => setShowModel(false)}
                    >
                        {<Modal.Header className="bg-slate-800" />}
                        <Modal.Body className="bg-slate-700 text-gray-400">
                            <div className=" space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8">
                                <h3 className="text-lg font-medium pt-5 ">
                                    Select the level and let the application determine the group and time according to the chosen time.
                                </h3>
                                <div>
                                    <input onChange={(e) => handleUpload(e)} type="file" name="file" id="" />
                                </div>

                                <div className="w-full text-right">
                                    <button onClick={(e) => handleSetMarks(e)} className="btn bg-green-600 text-gray-100 hover:bg-green-700 ">
                                        Validate
                                    </button>
                                </div>

                            </div>
                        </Modal.Body>
                    </Modal>
                </>

            </Box> :
            
        <div>
        <Loading />
        </div>
    )
}

export default Examain
