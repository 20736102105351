import React, { useContext } from "react";
import Header from "../landing_page/partials/Header";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../context/authContext";

const HomeLayout = (props) => {
    const { user } = useContext(AuthContext)
    return (
        ( user.role == null) ?
            <div>
                <Header />
                {props.element}
            </div> :
            <Navigate to={'/en'} />

    )
}

export default HomeLayout