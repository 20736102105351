import React, { useEffect, useState } from 'react'
import _R from '../../components/BaseUrl'
import { useParams,useNavigate } from "react-router-dom";

function Verify() {

    let { id, token } = useParams()
    const [res, setRes] = useState('')
    const navigate = useNavigate();

    const verify = async () => {
        try {
           let res = await _R.post('/verify', { id, token })
            console.log(res.data.message)
            //navigate("/signin");
            window.location.href = 'https://ceil.univ-msila.dz/signin'
          
        } catch (error) {
            console.log("Error")
            console.log(error.response.data)
            setRes(error.response.data.message)
        }
    }

    useEffect( () => {
        verify()
    }, [res])

    return (
        <div>{res}</div>
    )
}

export default Verify