import React, { useContext, useEffect, useState } from 'react';
import TitleSection from './utils/TitleSection';
import FormCompleteStudent from './FormCompleteStudent';
import _R from '../../../components/api';
import { AuthContext } from '../../context/authContext';
import { EditContext } from "../../context/editContext"
import Notification from '../../global/Notification';
import { useNavigate } from "react-router-dom";



export default function FormComplete(props) {
  const {  setme, editable_data, langues, setData } = useContext(EditContext)
  const navigate = useNavigate()
  const [error, setError] = useState('')

  const [showNotification, setShowNotification] = useState(false);
  const { setUser } = useContext(AuthContext)
  useEffect(() => {
     
      let f=[{
        name: 'langue_id',
        value: 1
      },{
        name: 'passTest',
        value: 0
      },{
        name: 'isUnivStudent',
        value: 0
      },{
        name: 'status',
        value: 0
      },{
        name: 'times',
        value: null
      }]
     
      setData({
        fields: f,
        model: 'demand'
      })
    

  }, [])

  const createForm = (formData) => {

    return new Promise(res => {
      
      let promises = []
      if (editable_data) {
        editable_data.fields.forEach(f => {
          promises.push(
            new Promise((resolve, reject) => {
              formData.append(`${f.name}`, f.value);
              resolve(formData)
            })
          )
        })
        Promise.all(promises).then(() => {
          res(formData)
        })
      }
      else {
        res(formData)
      }
    })
  }

  const handleConfirm = async () => {
    const formData = new FormData();
    let data = await createForm(formData)
    console.log(data)
    setShowNotification(false)
    const token = localStorage.getItem('ceil_token')
    try {
      const res = await _R.post('/complete-registration', formData, {
        headers: {
          "X_CEIL_TOKEN": token
        }
      })
      console.log(res.data.userInfo)
      setUser(res.data.userInfo)
      setme(res.data.userInfo.user)
      window.location.reload()
      
    } catch (error) {
      setError(error.response.data.message)
      setShowNotification(true)
      console.log(error.response.data)
    }
  }


  return (
    <>
      <div className=" sm:mt-0 p-4 bg-white rounded-sm shadow-sm">
        <div className="md:grid md:grid-cols-3 md:gap-6">

          {/* title */}

          <TitleSection
            title={props.lastSession ? `Re-Registration` : `New Registration`}
            desc={'Please choose carefully .'}
            titleTextColor="text-gray-600"
            descTextColor="text-gray-500"
          />
          {

            props.lastSession &&
            <div className='px-5 text-green-500'>
              <p className=''>{langues ? langues.find(l => { return l.value == props.lastSession.inscription.langue_id }).label : ''}</p>
              {/*
                <p className=''>Old Level : {props.lastSession.level}</p>
          */}
            </div>

          }
          <div className="mt-5 md:col-span-2 md:mt-0 text-gray-800">
            <form action="#" method="POST">
              <div className=" sm:rounded-sm">

                <FormCompleteStudent typeDemand={props.typeDemand}  error={error} />

                <div className={`px-4 py-3 text-right sm:px-6 flex justify-end`}>

                  <button
                    type="button"
                    onClick={() => {
                      handleConfirm()
                    }
                    }
                    className="inline-flex justify-center rounded-md border border-transparent bg-green-600 py-3 px-10 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Next
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {showNotification &&
        <Notification
          info={
            {
              type: 'err',
              message: error
            }

          }
        />
      }
    </>
  )
}
