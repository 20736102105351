import React,{useContext} from 'react';

import feature1 from './../images/feature1.jpg'
import feature2 from './../images/feature2.jpg'
import feature3 from './../images/feature3.jpg'
import Header from './features/Header';
import Item from './features/Item';
import { SettingsContext } from '../../context/settingsContext';
import { images_path } from '../../../paths';

function FeaturesZigzag() {
  const { items } = useContext(SettingsContext)

  return (
    <section  className='my-4  bg-white'>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 ">
          <Header />
          <div className="grid gap-20">
            {
              items.main.zigzag.map((item,ind)=>{
                return (
                  <Item key={Math.random(500)} image={`${images_path}${item.file}`} order={(ind % 2) ==0 ? 'rtl':'md:order-1'} title={item.title} bigTitle={item.content} text={item.description} />
                  )
              })
            }
         </div>
        </div>
      </div>
    </section>
  );
}

export default FeaturesZigzag;