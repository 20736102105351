import { Box, Button, TextField, useTheme,Autocomplete  } from "@mui/material";
import { Formik } from "formik"
import * as yup from "yup"
import useMediaQuery from "@mui/material/useMediaQuery"
import Header from "../../components/Header"
import { tokens } from "../../theme"
import {AccountCircle,AlternateEmail} from "@mui/icons-material"
import { useState,useEffect } from "react"
import { useParams } from "react-router-dom"
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined'
import axios from "axios"
import React, { Component }  from 'react';
 
const Editrole = () => {

     let  {id} = useParams();
    useEffect(()=>{
            axios.get('/getPermissions').then(resu=>{
                setPermissions(resu.data)
            }).then(()=>{
                  axios.get('/getRoles').then(res=>{
                    return res.data.filter(el=>el.id == id)[0]
                  }).then(r=>{
                    let arr=[];
                    (r.perm).forEach(element => {
                        arr.push({id:element.permission_id,name:element.permission_name})
                    })
                    initialValues.name = r.name
                    initialValues.description = r.description
                    setRole(r)
                    setPerms(arr)
                })
            })
      },[])
     
    const [role,setRole] = useState({name:"",description:""})
    const [perms,setPerms] = useState([])
    const [permissions,setPermissions] = useState([])
    const theme=useTheme()
    const colors=tokens(theme.palette.mode)
    const isNonMobile = useMediaQuery("(min-width:600px)")
    const handleFormSubmit = (values) => {
      values.id=role.id
        values.permissions=perms
        console.log(values)
        axios.post('/editrole',values).then((res)=>{
          console.log(res)
        })
       // 
    }
    const handleChangeSelect = (e,value) => {
      console.log(value)
        setPerms(value)
    }
    const handleChangeInput = (e,values) => {
      
      values[e.target.name]=e.target.value
     // console.log(values)
    }
    const FieldText = ({name,label,values,handleChange,type="text",defaultValue}) =>{
               return  (
               <Box sx={{ display: 'flex' , alignItems: 'flex-end'}}>
                    <AccountCircle sx={{ color: `${colors.primary[300]}`, mr: 1, my: 0.5 }} />
                    <TextField 
                        fullWidth
                        onChange={handleChange}
                        defaultValue={defaultValue}
                        name={name}
                        label={label} 
                        type={type}
                        variant="standard" 
                        sx={{ 
                        "& .MuiFormLabel-root" : { color : `${colors.gray[200]} !important` },
                        "& .Mui-focused::after": { borderBottom : `1px solid ${colors.primary[400]} !important`},
                        }}
                    />
                </Box>)
    }

  return (
    <Box m="20px">
      <Header title="EDIT ROLE" subtitle="Edit role" />
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
      //  validationSchema={checkoutSchema}
      >
        {
        ({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4"},
                m:"20px" ,
              }}
            >
                <Box sx={{ gridColumn: "span 2" }}>
                    <FieldText 
                        name="name"
                        label = "Name"
                        defaultValue={values.name}
                        values={values}
                        handleChange={(e)=>handleChangeInput(e,values)}
                    />
                </Box>
                <Box sx={{ gridColumn:  "span 2" }}>
                    <FieldText 
                        name="description"
                        label = "Description"
                        defaultValue={values.description}
                        values={values}
                        handleChange={(e)=>handleChangeInput(e,values)}
                    />
                </Box>
                <Box sx={{ gridColumn:  "span 2" }}>
                    <Autocomplete
                        multiple
                        value={perms}
                        isOptionEqualToValue={(option, value) => (option.id === value.id)}
                        options={permissions}
                        getOptionLabel={(option) => option.name}
                        sx={{display:"flex", alignItems: 'flex-end'}}
                        onChange={handleChangeSelect}
                        renderInput={(params) => (
                            <>
                                <LockOpenOutlinedIcon  sx={{ color: `${colors.primary[300]}`, mr: 1, my: 0.5 }}  />
                                <TextField
                                    {...params}
                                    name="permissions"
                                    variant="standard"
                                    value={values.id}
                                    label="Permissions"
                                    placeholder="permissions"
                                />
                            </>
                        )}
                    />
                </Box>
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="success" variant="contained">
                Validate
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  name: yup.string().required("required"),
  description: yup.string().required("required"),

});
const initialValues = {
  name: "",
  description: "",
  permissions: "",
  level:0
};

export default Editrole;