import React, { useEffect, useState, useContext, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import _R from "../../components/api";
import Header from "../../components/Header";
import TextInfo from "../../components/TextInfo";
import Loading from "../layouts/Loading";
import { EditContext } from "../context/editContext"
import Select from 'react-select';
import HeaderSection from "../global/headerSection";
import Demand from ".";

String.prototype.toUpperFirstCase = function () {
    return this.charAt(0).toUpperCase() + this.slice(1)
}

const optionsTime = [
    {
        value: 1,
        label: '08:00 - 10:00',
    }, {
        value: 2,
        label: '10:00 - 12:00',
    }, {
        value: 3,
        label: '12:00 - 14:00',
    }, {
        value: 4,
        label: '14:00 - 16:00',
    }, {
        value: 5,
        label: '17:00 - 19:00',
    }, {
        value: 6,
        label: 'Saturday 08:00 - 15:30',
    }, {
        value: 7,
        label: 'Thursday 08:00 - 15:30',
    }, {
        value: 8,
        label: 'Saturday and Thursday 08:00 - 12:00',
    },
]

const optionsYN = [
    {
        value: 0,
        label: 'No',
    }, {
        value: 1,
        label: 'Yes',
    }
]

const Showdemand = (props) => {
    const { editable_data, initialize, setData } = useContext(EditContext);
    const { id } = useParams();
    const [edit, setEdit] = useState(false)
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedOptionEdit, setSelectedOptionEdit] = useState(null);
    const [user, setUser] = useState(null)
    const [languages, setLanguages] = useState(null)
    const observation = useRef()

    useEffect(() => {
        const controller = new AbortController()
        const signal = controller.signal
        var abort = true;
        _R.get('/getUserDemandInfo', { signal, params: { id } }).then(response => {
            console.log(response.data)
            abort && setUser(response.data)
            let _times = []
            response.data.times.forEach(element => {
                _times.push(
                    optionsTime.find(ele => {
                        return ele.value == element
                    })
                )
            });
            setSelectedOption(_times)
            setSelectedOptionEdit(_times)
        })

        return () => {
            abort = false
            controller.abort()
        }
    }, [])

    useEffect(() => {
        const controller = new AbortController()
        const signal = controller.signal
        var abort = true;
        _R.get('/alllanguages', { signal }).then(response => {
            console.log(response.data)
            abort && setLanguages(response.data)
        })
        return () => {
            abort = false
            controller.abort()
        }
    }, [])

    const [isValid, setIsValid] = useState(false)

    const handleSelection = (t) => {
        let _times = []
        //
        t.forEach(element => {
            _times.push(element.value)

        });
        setSelectedOption(_times)
        setData({
            fields: [{
                name: 'times',
                value: _times
            }],
            model: 'demand'
        })
    }

    const handleValidation = async () => {
        const result = await _R.post('/validate', { id }, {
            headers: {
                "X_CEIL_TOKEN": localStorage.getItem('ceil_token')
            }
        })
        console.log(result)
        setIsValid(true)
        
    }

    const handleEdit = () => {
        setSelectedOption(selectedOptionEdit)
        initialize()
        setEdit(true)
    }

    const handleAbort = () => {
        setEdit(false)
    }

    const createForm = (formData) => {

        return new Promise(res => {
            formData.append('id', id)
            let data = {
                id,
                fields: {}
            }
            let promises = []
            if (editable_data) {
                editable_data.fields.forEach(f => {
                    promises.push(
                        new Promise((resolve, reject) => {
                            data["fields"][f.name] = f.value
                            formData.append(`${f.name}`, f.value);
                            console.log(f.name, f.value)
                            console.log(formData)
                            resolve(formData)
                        })
                    )
                })
                Promise.all(promises).then(() => {
                    res(formData)
                })
            }
            else {
                res(formData)
            }
        })
    }

    const handleSendResponse = (accept = false) => {
        let link = accept ? '/acceptDemand' : '/rejectDemand'
        _R.post(link, { id, text: observation.current.value }).then(response => {
            console.log(response.data)
            window.location.reload()
        })

    }

    const handleSave = () => {
        let formData = new FormData()
        setUser(null)
        createForm(formData).then(data => {
            console.log(data)
            _R.post('/updateDemand', data).then(response => {
                console.log(response.data)
                setUser(response.data)
                setEdit(false)
                initialize()
            })
        })

    }

    return (
        
        (!user) ?
        <div className="h-[calc(100vh_-_200px)]">
                <Loading />
            </div>
            :

            <div>
                <div className=" bg-slate-800 p-3 border-l border-t border-slate-700 shadow-[2px_2px_5px_1px_#00000040]">
                    <Header title="Demand" subtitle={`Demand details`} />
                    <div className="mt-3">
                        {
                            edit ?
                                <>
                                    <button className='ml-4 btn-sm text-white bg-red-800 hover:bg-red-900' onClick={() => handleSave()}>Save</button>
                                    <button className='ml-4 btn-sm text-white bg-yellow-500 hover:bg-yellow-700' onClick={() => handleAbort()}>Cancel</button>
                                </>
                                :
                                <button className='ml-4  btn-sm text-white bg-green-800 hover:bg-green-900' onClick={() => handleEdit()}>Edit</button>
                        }
                        <Link to={`/en/admin/users/show/${user.id}`} className='ml-4 btn-sm text-white bg-sky-800 hover:bg-sky-900'>User Info</Link>
                    </div>
                </div>
                <div className="my-8 px-3 ">
                    <div className="flex flex-col pb-3 lg:flex-row items-stretch">
                        <div className="basis-2/3">
                            <div className="flex flex-col pb-3 lg:flex-row ">
                                <TextInfo label={'First Name'} value={(user.firstname).toUpperCase()} flex={'basis-1/2'} />
                                <TextInfo label={'Last Name'} value={(user.lastname).toUpperFirstCase()} flex={'basis-1/2'} />
                            </div>
                            <div className="flex flex-col pb-3 lg:flex-row">
                                <TextInfo label={'Email'} value={user.email} flex={'basis-2/3'} />
                                {
                                    !edit ?
                                        <TextInfo label={'Langue'} value={(user.langue).toUpperFirstCase()} flex={'basis-1/3'} />
                                        :
                                        <EditDrop idenf={'langue_id'} description='Langue' values={languages} _value={(user.langue_id)} />

                                }
                            </div>
                            <div className="flex flex-col pb-3 lg:flex-row">
                                {
                                    !edit ?
                                        <>
                                            <TextInfo label={'Reinsc ?'} value={user.status} flex={'basis-1/3'} />
                                            <TextInfo label={'Pass test ?'} value={(user.passTest)} flex={'basis-1/3'} />
                                            <TextInfo label={'university Student'} value={(user.isUnivStudent)} flex={'basis-1/3'} />
                                        </>
                                        :
                                        <>
                                            <EditDrop idenf={'status'} description='Reregistration' values={optionsYN} _value={user.status} />
                                            <EditDrop idenf={'passTest'} description='Pass Test' values={optionsYN} _value={(user.passTest)} />
                                            <EditDrop idenf={'isUnivStudent'} description='university Student' values={optionsYN} _value={(user.isUnivStudent)} />
                                        </>
                                }
                            </div>
                            <div className="flex flex-col pb-3 lg:flex-row relative">
                                <TextInfo label={'Date Inscription'} value={user.created_at} flex={'basis-1/2'} />
                                <TextInfo label={'Time Inscription'} value={user.created_time} flex={'basis-1/2'} />
                            </div>
                            {
                                !edit &&
                                <>
                                    {
                                        (user.validate) ?
                                            <p className=" text-green-200 text-opacity-40 text-sm w-full ">Confirmed</p>
                                            :
                                            user.certificat_status == 0 ?
                                                <div className="flex pb-3 relative items-center ">
                                                    <TextInfo label={'Validation'} value={user.validate ? 'Valid' : 'Not Valid'} flex={'basis-full p-1'} css={`${isValid ? 'bg-green-500 bg-opacity-30' : 'bg-red-500 bg-opacity-20'}`} />

                                                </div>
                                                :
                                                user.certificat_status == 2 ?
                                                    <>
                                                        <div className="flex flex-col pb-3 lg:flex-row relative items-start">
                                                            <div className={`flex-auto p-1`}>
                                                                <div className="w-full ">
                                                                    <label htmlFor="">Observation: </label>
                                                                    <textarea className="w-full text-gray-800" ref={observation} name="observation" id="observation" cols="30" rows="1"></textarea>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-col pb-3 lg:flex-row relative items-start">
                                                            <div className={`basis-1/2 p-1`}>
                                                                <div className="w-full ">
                                                                    <button onClick={() => handleSendResponse(true)} className="btn bg-green-800 hover:bg-green-950 w-full">Accept</button>
                                                                </div>
                                                            </div>
                                                            <div className={`basis-1/2 p-1`}>
                                                                <div className="w-full ">
                                                                    <button onClick={() => handleSendResponse()} className="btn bg-red-900 hover:bg-red-950 w-full">Reject</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className="flex flex-col pb-3 lg:flex-row relative items-start">
                                                            <div className={`basis-auto p-1`}>
                                                                <div className="w-full ">
                                                                    Demamd rejected : {user.observation}
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </>
                                    }
                                </>}
                        </div>
                        <div className="basis-1/3 md:ml-5">
                            <div className="flex flex-col pb-3 lg:flex-row h-full">
                                <div className={`md:flex-1 p-2 flex`}>
                                    {
                                        !edit ?
                                            <div className="  border-teal-500  border py-2 px-4 rounded-lg flex-auto">
                                                <label htmlFor="" className=" text-slate-400 text-xs">Times</label>
                                                <ul className=" mt-2 ml-2 text-sm text-slate-200 list-decimal">
                                                    {
                                                        user.times.map((option, index) => {
                                                            return (
                                                                <li key={index} className="mb-1 text-sm text-slate-300 list-decimal">
                                                                    {optionsTime[option - 1].label}
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                            :
                                            <div className=" col-span-6 sm:col-span-6 relative">
                                                <label htmlFor="" className="block text-sm font-medium text-gray-200">
                                                    Set Times ordered :
                                                </label>
                                                <Select
                                                    isMulti
                                                    classNamePrefix="sel"
                                                    className=" mt-1 rounded-sm  border-gray-200 bg-white text-gray-800 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-md"
                                                    defaultValue={selectedOption}
                                                    onChange={handleSelection}
                                                    options={optionsTime}
                                                />
                                                <label htmlFor="" className=" absolute text-red-400 text-sm font-bold -bottom-5"></label>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        user.validate == 0 ?
                    
                    <div className={`basis-1/3 p-1`}>
                        <button onClick={() => handleValidation()} className="btn rounded-md bg-green-600 hover:bg-green-700 w-full">Validate</button>
                    </div>
                    :
                    <></>
                    }
                </div>
            </div>

        
    
    )
}
export default Showdemand;

const EditDrop = ({ idenf, description, values = false, _value }) => {
    const { editable_data, setData } = useContext(EditContext)
    const [val, setVal] = useState(null)
    console.log(_value)

    return (
        <div className="basis-1/3 p-1">
            <div className="flex items-center bg-slate-900 border rounded-md h-[50px] py-2 px-4 border-lime-300 shadow-[0_0_10px_1px_rgb(44,251,12,0.47)]">
                <label htmlFor={idenf} className="mr-1 text-teal-500 text-xs text-opacity-90 font-semibold">{description}?</label>
                <select
                    name={idenf} id={idenf} className="flex-auto bg-transparent text-gray-100 border-none focus:outline-none "
                    onChange={(e) => {
                        setVal(e.target.value)
                        setData({
                            fields: [{
                                name: idenf,
                                value: e.target.value
                            }],
                            model: 'demand'
                        })
                    }}
                    defaultValue={_value}
                >
                    {
                        values &&
                        values.map((val, index) => {
                            return (
                                <option value={val.value} className="bg-white-500  text-gray-800 hover:opacity-60 p-2">{val.label}</option>
                            )
                        })
                    }

                </select>
            </div>
        </div>
    )
}

const EditDrop1 = ({ idenf, description }) => {
    return (
        <div className="basis-1/3 p-1">
            <label class="relative inline-flex items-center mb-4 cursor-pointer flex">
                <input type="checkbox" value="f" class="sr-only peer" />
                <label className="mr-3 text-teal-500 text-xs text-opacity-90 font-semibold">Pass Test ?</label>
                <div onClick={() => {
                }
                }
                    className="w-11 h-6 bg-red-600 rounded-full peer relative peer-checked:after:translate-x-full peer-checked:after:border-green-400 after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"></div>
            </label>
        </div>
    )
}