import React, { useState } from "react";
import Dropd from "../../utils/Dropd";
import Select from 'react-select';

const options = [
    {
        value: 'student',
        label: 'Student',
    }
]

const optionsIsUnivS = [
    {
        value: 0,
        label: 'No',
    },
    {
        value: 1,
        label: 'Yes',
    }
]

const optionsStatus = [
    {
        value: 0,
        label: 'New Registration',
    },
    {
        value: 1,
        label: 'Re-registration',
    }
]

const FormCompleteStudent = (props) => {

    return (
        <>
            <div className="px-4 py-5 sm:p-6">
                <div className="grid grid-cols-6 gap-6">
                    <Dropd
                        label={'You are?'}
                        id="typeUser"
                        name="typeUser"
                        class={'sm:col-span-6'}
                        options={options}
                        onChange={props.onChange}
                    />
                    <Dropd
                        label={'Are you a university student?'}
                        id="isUnivStudent"
                        name="isUnivStudent"
                        class={'sm:col-span-6'}
                        options={optionsIsUnivS}
                        onChange={props.onChange}
                    />
                    {
                        /*
                        <Dropd
                        label={'Type of registration'}
                        id="status"
                        name="status"
                        class={'sm:col-span-6'}
                        options={optionsStatus}
                        onChange={props.onChange}
                        />
                        */
                    }
                </div>
            </div>
        </>
    )
}

export default FormCompleteStudent;