import Dashboard from "./scenes/dashboard"
import Users from "./scenes/users"
import Students from "./scenes/students"
import Teachers from "./scenes/teachers"
import Adduser from "./scenes/users/add"
import Roles from "./scenes/roles"
import Addrole from "./scenes/roles/add"
import Showrole from "./scenes/roles/show"
import Editrole from "./scenes/roles/edit"
import Levels from "./scenes/levels"
import Permissions from "./scenes/permissions"
import Langues from "./scenes/langues"
import { Routes, Route, Outlet, Navigate } from "react-router-dom"
import Home from "./scenes/landing_page/home"
import { useState, useContext } from "react"
import Apps from "./scenes/layouts"
import { AuthContext } from "./scenes/context/authContext"
import { EditProvider } from "./scenes/context/editContext"
import { FilterProvider } from "./scenes/context/filterContext"
import { SettingsContext } from "./scenes/context/settingsContext"
import { useEffect } from "react"
import React from 'react';
import SignIn from './scenes/landing_page/pages/SignIn'
import Post from './scenes/landing_page/pages/Post'
import ResetPassword from './scenes/landing_page/pages/ResetPassword'
import NewPassword from './scenes/landing_page/pages/newPassword'
import SignUp from './scenes/landing_page/pages/SignUp'
import CompletePage from "./scenes/landing_page/pages/CompletPage"
import HomeLayout from "./scenes/layouts/homeLayout"
import Me from "./scenes/layouts/me"
import MeIndex from "./scenes/me"
import NewDemand from "./scenes/me/NewDemand"
import EditDemand from "./scenes/me/edit"
import ReRegistre from "./scenes/me/reregistre"
import ShowCourse from "./scenes/me/show"
import Demand from "./scenes/demands"
import Groups from "./scenes/groups"
import ShowGroup from "./scenes/groups/show"
import ShowExamain from "./scenes/examain/show"
import Showdemand from "./scenes/demands/show"
import ShowStudent from "./scenes/students/show"
import ShowUser from "./scenes/users/show"
import Loading from "./scenes/layouts/Loading"
import StudentCard from "./scenes/students/pdf/StudentCard"
import Element from "./scenes/appearance"
import Examain from "./scenes/examain"
import Verify from "./scenes/mail"
import UpdateElementor from "./scenes/appearance/edit"
import TicketA from "./scenes/landing_page/pages/TicketA"
import _R from './components/BaseUrl'

const adminPermission = ['users', 'students', 'dashboard', 'groups', 'demands', 'teachers', 'appearance', 'examains']
const agentPermission = ['students', 'dashboard', 'demands', 'teachers', 'appearance', 'examains', 'groups']

function ProtectedRouteDashboard(props) {
    return (
        (props.user && (props.user.role == 'admin' || props.user.role == 'agent')) ?
            <Outlet />
            :
            <Navigate to="/en" />
    )
}

function ProtectedRouteVerifiet({ user }) {

    return (
        user && (user.user.status) ?
            <Outlet />
            :
            <Navigate to="/en" />
    )
}

function ProtectedRoutePermission(props) {
    const { permissions } = useContext(SettingsContext)

    const perm = props.user.role == 'admin' ? adminPermission : agentPermission

    return (
        (perm.includes(props.permission)) ?
            <FilterProvider>
                <EditProvider>
                    <Apps user={props.user} Com={<Outlet />} />
                </EditProvider>
            </FilterProvider >
            :
            <Navigate to="/en" />
    )
}

function ProtectedRouteAuth({ auth }) {
    const { load, setLoad } = useContext(SettingsContext)

    return (
        (auth) ?
            <Outlet />
            :
            <Navigate to="/en/signin" />
    )
}
function ProtectedRouteNoAuth({ auth }) {
    const { load, setLoad } = useContext(SettingsContext)

    return (
        (!auth) ?
            <Outlet />
            :
            <Navigate to="/en" />
    )
}

function App() {

    const { isAuth, setIsAuth, user, setUser } = useContext(AuthContext)
    const { permissions, setPermissions, load, setLoad } = useContext(SettingsContext)
    //const [load, setLoad] = useState(true)

    useEffect(() => {
        let promises = []
        let isMounted = true
        const controller = new AbortController()
        const checkAuth = async () => {
            try {
                const res = await _R.get("/isAuth", {
                    signal: controller.signal,
                    headers: {
                        "X_CEIL_TOKEN": localStorage.getItem('ceil_token')
                    }
                })
                isMounted && setIsAuth(true)
                isMounted && setUser(res.data.userInfo)
                localStorage.setItem('ceil_token', res.data.token)
                isMounted && setLoad(false)
                promises.push(() => {
                    return new Promise((resolve, reject) => {
                        console.log(load)
                        resolve(false)
                    })
                })
            }
            catch (err) {
                console.log(err)
                isMounted && setIsAuth(false)
                isMounted && setUser(null)
                isMounted && setLoad(false)
                promises.push((re) => {
                    return new Promise((resolve, reject) => {
                        resolve(false)
                    })
                })
            }
        }
        checkAuth().then(() => {
            Promise.all(promises).then(() => {
                setLoad(false)
                console.log(load)
            })
        })
        return () => {
            controller.abort()
            isMounted = false
        }
    }, []);

    return (
        (isAuth !== null) ?

        <Routes> 
            <Route exact element={<ProtectedRouteNoAuth auth={isAuth} />}>
                <Route exact path="/en/signin" element={<SignIn />} />
                <Route exact path="/en/signup" element={<SignUp />} />
                <Route exact path="/en/reset-password" element={<ResetPassword />} />
            </Route>
            <Route exact path="/en/new-password/:id/:token" element={<NewPassword />} />
            <Route exact path="/en/verifyMail/:id/:token" element={<Verify />} />
            <Route exact path="/en" element={<Home />} />
            <Route exact path="/en/posts/:title" element={<Post />} />
            <Route exact element={<ProtectedRouteAuth auth={isAuth} />}>
                <Route exact element={<ProtectedRouteVerifiet user={user} />}>
                    <Route exact path="/en/me" element={<EditProvider><Me com={<MeIndex />} /></EditProvider>} />
                    <Route exact path="/en/me/makeDemand" element={<EditProvider><Me com={<NewDemand />} /></EditProvider>} />
                    <Route exact path="/en/me/editDemand" element={<EditProvider><Me com={<EditDemand />} /></EditProvider>} />
                    <Route exact path="/en/me/re-registre/:id" element={<EditProvider><Me com={<ReRegistre />} /></EditProvider>} />
                    <Route exact path="/en/me/:id" element={<EditProvider><Me com={<ShowCourse />} /></EditProvider>} />
                </Route>
                <Route exact path="/en/complete-registration" element={<HomeLayout element={<CompletePage />} />} />
                <Route exact path="/en/admin" element={<ProtectedRouteDashboard user={user} />}>
                    {
                        //check if is a manager account
                        data.map((element, index) => {
                            return (
                                <Route key={index + 1} element={<ProtectedRoutePermission permission={element.permission} user={user} />}>
                                    {
                                        element.routes.map((r, ind) => {
                                            return (
                                                <Route key={ind + 1} exact path={r.path} element={r.element} />
                                            )
                                        })
                                    }
                                </Route>
                            )
                        })
                    }
                </Route>
            </Route>
        </Routes>
        :
        (<Loading color=' bg-amber-700'
            />)

    )
}
export default App;

const data = [
    {
        routes: [{
            path: "teachers",
            element: <Teachers />
        }],
        permission: "teachers"
    },
    {
        routes: [{
            path: "appearance",
            element: <Element />
        }, {
            path: "appearance/update",
            element: <UpdateElementor />
        }],
        permission: "appearance"
    },
    {
        routes: [{
            path: "settings/langues",
            element: <Langues />
        }],
        permission: "langues"
    },
    {
        routes: [{
            path: "settings/permissions",
            element: <Permissions />
        }],
        permission: "permissions"
    },
    {
        routes: [{
            path: "settings/levels",
            element: <Levels />
        }],
        permission: "levels"
    },
    {
        routes: [
            {
                path: "settings/roles",
                element: <Roles />
            },
            {
                path: "settings/roles/add",
                element: <Addrole />
            },
            {
                path: "settings/roles/show/:id",
                element: <Showrole />
            },
            {
                path: "settings/roles/edit/:id",
                element: <Editrole />
            },

        ],
        permission: "roles"
    },
    {
        routes: [
            {
                path: "students",
                element: <Students />
            },
            {
                path: "students/show/:id",
                element: <ShowStudent />
            },
            {
                path: "card/:name/:language/:address",
                element: <StudentCard />
            }

        ],
        permission: "students"
    },
    {
        routes: [
            {
                path: "demands",
                element: <Demand />
            },
            {
                path: "demands/show/:id",
                element: <Showdemand />
            },
            {
                path: "demands/ticket/:inscription_id",
                element: <TicketA />
            }

        ],
        permission: "demands"
    },
    {
        routes: [
            {
                path: "pedagogy/groups",
                element: <Groups />
            },
            {
                path: "pedagogy/groups/show/:id",
                element: <ShowGroup />
            }

        ],
        permission: "groups"
    },
    {
        routes: [
            {
                path: "pedagogy/examain",
                element: <Examain />
            },
            {
                path: "pedagogy/examain/show/:id",
                element: <ShowExamain />
            },


        ],
        permission: "examains"
    },
    {
        routes: [
            {
                path: "users",
                element: <Users />
            },
            {
                path: "users/show/:id",
                element: <ShowUser />
            },
            {
                path: "users/add",
                element: <Adduser />
            }

        ],
        permission: "users"
    },
    {
        routes: [
            {
                path: "dashboard",
                element: <Dashboard />
            }
        ],
        permission: "dashboard"
    },
    {
        routes: [
            {
                path: "./",
                element: <Dashboard />
            }
        ],
        permission: "dashboard"
    }
]

