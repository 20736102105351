import React, { createContext, useState } from "react";
import _R from "../../components/BaseUrl"

const DEFAULT_HEADER = {}

export const SettingsContext = createContext(false)


export const SettingsProvider = ({ children }) => {
    const [settings, setSettings] = useState(DEFAULT_HEADER)
    const [items, setItems] = useState(null)
    const [post, setPost] = useState(null)
    const [load, setLoad] = useState(true)
    const [permissions, setPermission] = useState(true)
    const [isCollapsed, setIsCollapsed] = useState(true)

    const initialize = () => {
        setSettings(DEFAULT_HEADER)
    }

    const _setSettings = (model, name, value) => {
        let _settings = settings
        if (settings && settings[model]) {
            _settings[model][name] = value
            setSettings(_settings)
        } else {
            _settings[model] = {}
            _settings[model][name] = value
            setSettings(_settings)
        }
        console.log(settings)
    }
    

    return ( 
        <SettingsContext.Provider
            value={{ items, setItems, settings, _setSettings, initialize, load, setLoad, permissions, setPermission, post, setPost, isCollapsed, setIsCollapsed }}>
            {children}
        </SettingsContext.Provider>
    );
};


