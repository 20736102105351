import React from 'react'

function Block(props) {
    return (
        <div className={`relative flex flex-col items-center border border-white h-full p-5 rounded-md ${props.bg} text-sm md:text-md`} {...props}>
            <div>
                {props.icon}
            </div>
            <h3 className="my-2 text-gray-50 font-bold">{props.title}</h3>
            <p className="text-md text-gray-100 text-justify">{props.description}</p>
        </div>
    )
}

export default Block