import React from 'react'
import App from './App'
import { AuthProvider } from "./scenes/context/authContext"
import { SettingsProvider } from "./scenes/context/settingsContext"
import { BrowserRouter } from 'react-router-dom'


function Main() {
  return (
    <BrowserRouter>
      <AuthProvider >
        <SettingsProvider>
          <App />
        </SettingsProvider>
      </AuthProvider>
    </BrowserRouter>
  )
}

export default Main