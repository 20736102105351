import React, { useState } from 'react';
import Modal from '../utils/Modal';
import HeroImage from '../images/student_reg.png';
import { sliders_path } from '../../../paths';

function HeroHome(props) {
  const [videoModalOpen, setVideoModalOpen] = useState(false);

  return (
      <div className="mx-auto mb-4 md:mb-0">
        <div className="relative">
          <div>
            <div className="relative flex justify-center items-center" data-aos="fade-up" data-aos-delay="200">
              <img className="mx-auto" src={HeroImage}  alt="Hero" />
              <a
                className="absolute group"
                href="#0"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setVideoModalOpen(true);
                }}
                aria-controls="modal"
              >
                <svg
                  className="w-16 h-16 sm:w-20 sm:h-20 hover:opacity-75 transition duration-150 ease-in-out"
                  viewBox="0 0 88 88"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <defs>
                    <linearGradient x1="78.169%" y1="9.507%" x2="24.434%" y2="90.469%" id="a">
                      <stop stopColor="#EBF1F5" stopOpacity=".8" offset="0%" />
                      <stop stopColor="#EBF1F5" offset="100%" />
                    </linearGradient>
                  </defs>
                  <circle fill="url(#a)" cx="44" cy="44" r="44" />
                  <path
                    className="fill-current text-purple-600"
                    d="M52 44a.999.999 0 00-.427-.82l-10-7A1 1 0 0040 37V51a.999.999 0 001.573.82l10-7A.995.995 0 0052 44V44c0 .001 0 .001 0 0z"
                  />
                </svg>
              </a>
            </div>
            <Modal id="modal" ariaLabel="modal-headline" show={videoModalOpen} handleClose={() => setVideoModalOpen(false)} style={{ 'background': 'transparent' }}>
              <div className="relative pb-9/16" style={{
                'height': '100vh',
                'background': '#00000057',
                'display': 'grid',
                'placeItems': 'center'
              }}>
                <video className="absolute w-full h-full" style={{
                  'height': '100vh',
                  background: 'black'
                }} controls>
                  <source src={`${sliders_path}${props.video[0].content}`} type="video/mp4" />
                </video>
              </div>
            </Modal>
          </div>
        </div>
      </div>
  );
}

export default HeroHome;
