import React, { useContext, useEffect, useState } from 'react';
import Header from '../partials/Header';
import PageIllustration from '../partials/PageIllustration';
import HeroHome from '../partials/HeroHome';
import FeaturesBlocks from '../partials/FeaturesBlocks';
import FeaturesZigZag from '../partials/FeaturesZigzag';
import Testimonials from '../partials/Testimonials';
import Newsletter from '../partials/Newsletter';
import Banner from '../partials/Banner';
import Footer from '../partials/Footer';
import _R from "../../../components/api";
import { SettingsContext } from '../../context/settingsContext';
import Loading from "../../layouts/Loading"

function Post() {
  const { items, setItems, load, setload,post } = useContext(SettingsContext)
  const [loading,setLoading] = useState(true)

  useEffect(() => {
    fetch('https://api.ipdata.co?api-key=9d801b7ce9ea86f6bfa1b776726fe2f8db480bd5ed27b293e684d557').then((data => {
      data.json().then((parsed) => {
        console.log(parsed);
        _R.get('/settings', { params: { parsed } }).then((response) => {
          setItems(response.data)
          setLoading(false)
        })
      });

    }))



  }, [])
  return (
    
      loading ? 
      
      <div>
      <Loading />
      </div>
      :
      <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header />
      {/*  Page content */}
      <main className="grow">
        {/*  Page sections */}
        {
          post && 
          Object.keys(post.links).map((caption) =>{
            return (
              <button >{caption}</button>
            )
          })
        }
      </main>
      {/*  Site footer */}
      <Footer />
    </div>
    
    
  );
}

export default Post;