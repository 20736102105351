import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import FormComplete from '../partials/forms/FormComplete';
import { AuthContext } from '../../context/authContext';

function CompletePage() {
    
    const {user}=useContext(AuthContext)
    const printRecu = useRef()
    const handlePrint = () => {
        
    }

    const [finich, setFinich] = useState(false)
    return (
        <main className="text-gray-800 px-2 py-5 max-w-5xl mx-auto">
            <div className=" text-center  p-4 border-b bg-green-500 rounded-t-md ">
                <h1 className="h3 mb-2">Welcome Back ! </h1>
                <h1 className="text-md font-semibold  text-gray-200 px-5">We has always accompanied students in their university course</h1>
            </div>
            <div className='text-gray-800 py-5'>
                {
                    !finich && (user.role == null ) ?
                        <FormComplete setFinich={() => setFinich(true)} />
                        :
                        <div className='flex flex-col justify-center'>
                            <h1 className='text-4xl font-bold text-gray-800 text-center pb-5'>Congratulations!</h1>
                            <div className='text-center pb-5'>
                                <Link onClick={() => handlePrint()} className=' btn-sm text-white bg-blue-800 hover:bg-blue-900'>Print</Link>
                            </div>
                            <iframe id="ifmcontentstoprint" className=''></iframe>
                            <div ref={printRecu}>
                                <h4 className='text-2xl font-bold text-gray-600 pb-5'>Students are required to provide the following documents:</h4>
                                <ul className='text-xl  text-gray-500 list-decimal'>
                                    <li className='pb-3 ml-5'>the registration voucher (Print it by the button above or check your email)</li>
                                    <li className='pb-3 ml-5'>02 Photos</li>
                                    <li className='pb-3 ml-5'>A copy of the registration certificate or student card</li>
                                    <li className='pb-3 ml-5'>A copy of the identity card.</li>
                                    <li className='pb-3 ml-5'>Registration fees</li>
                                </ul>
                            </div>
                        </div>
                }
            </div>
        </main>
    );
}

export default CompletePage;