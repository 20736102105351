import React, { useEffect, useRef, useState } from 'react'
import { BsGlobe2,BsList,BsGrid3X2Gap } from "react-icons/bs";
function Sidebar() {
    const [showside, setShowsidw] = useState(0)
    useEffect(() => {
        setShowsidw(showside == 0 ? '-250px' : 0)
    }, [])
    return (
        <>
            <div className={`md:hidden block fixed top-[0] ${showside ? 'left-[-250px]' : 'left-0'} z-[60] transition-all duration-200 ease-in opacity-90 h-[100vh]`}>
                <div className='relative sidebar w-[250px] bg-black  shadow-[0_0_20px_1px_#00000060] p-4 h-full'>
                    <button
                        className='focus:outline-none transition-all duration-100 ease-in w-[50px] h-[50px]  bg-black absolute right-[-50px] top-0 text-center '
                        onClick={() => {
                            setShowsidw(showside == 0 ? '-250px' : 0)
                        }}
                    >
                        <BsList className='text-[20px] text-white m-auto' />
                    </button>
                    <ul className="mt-5 ml-5">
                        <li className="">
                            <a className="py-1 px-4 block text-gray-400  text-sm hover:text-white flex items-center" href="/">
                                Website <BsGlobe2 className='text-[17x] text-white m-auto mr-0' />
                            </a>
                        </li>
                        <li className="">
                            <a className="py-1 px-4 block text-gray-400 text-sm hover:text-white flex items-center" href="/en/me">
                                Home <BsGrid3X2Gap className='text-[17px] text-white  m-auto mr-0' />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className={`hidden md:block fixed top-0 left-0 z-10 transition-all duration-200 ease-in h-full`}>
                <div className='relative sidebar w-[250px] bg-sky-900  shadow-[0_0_20px_1px_#00000060] p-4 h-full'>
                    <ul className="mt-5 ml-5">
                        <li className="">
                            <a className="py-1 px-7 block text-gray-300 font-semibold text-lg hover:text-white flex items-center" href="/">
                                Website <BsGlobe2 className='text-[20px] text-white m-auto mr-0' />
                            </a>
                        </li>
                        <li>
                            <a className="py-1 px-7 block text-gray-300 font-semibold text-lg hover:text-white flex items-center" href="/en/me">
                                Home <BsGrid3X2Gap className='text-[20px] text-white m-auto mr-0' />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </>


    )
}

export default Sidebar