import React, { Component } from 'react'
import _R from '../../../components/BaseUrl'
import QRCode from "react-qr-code";

String.prototype.toUpperFirstCase = function () {
    return this.charAt(0).toUpperCase() + this.slice(1)
}

const optionsTime = [
    {
        value: 1,
        label: '08:00 - 10:00',
    }, {
        value: 2,
        label: '10:00 - 12:00',
    }, {
        value: 3,
        label: '12:00 - 14:00',
    }, {
        value: 4,
        label: '14:00 - 16:00',
    }, {
        value: 5,
        label: '17:00 - 19:00',
    }, {
        value: 6,
        label: 'Saturday 08:00 - 15:30',
    }, {
        value: 7,
        label: 'Thursday 08:00 - 15:30',
    }, {
        value: 8,
        label: 'Saturday and Thursday 08:00 - 12:00',
    },
]


export default class Ticket extends Component {
    constructor(props) {
        super(props);
        this.state = {
            info: {
                firstname: 'rahaf'
            },
            token: '',
            inscription_id: props.inscription_id 
                }
                //console.log(this.props)
    }

    componentDidMount() {
        if (this.props.inscription_id != 0)
            _R.get('/ticket', { params: { id: this.props.inscription_id } }).then(response => {
                this.setState({ info: response.data.info, token: response.data.token })
            })
    }
    render() {
        return (
            <div className='print hidden p-5'>
                <div className="bg-white rounded-sm  px-8 py-2 border border-gray-400">
                    <div className='text-center mb-4 text-gray-800'>
                        <h5>Mohamed Boudiaf University of M’sila</h5>
                        <h5>جامعة محمد بوضياف - المسيلة</h5>
                        <h5>Intensive Teaching Center of Languages</h5>
                        <h5>مركز التعليم المكثف للغات</h5>
                    </div>
                    <div className="flex flex-row mb-4">
                        <div className=" w-32 h-32 bg-white  flex-shrink-0 grid  " style={{ 'placeItems': 'center' }}>
                            {
                                (this.props.inscription_id != 0) &&
                                <QRCode
                                    className="w-24 h-24"
                                    value={`${this.props.inscription_id}`}
                                    viewBox={`0 0 256 256`}
                                />
                            }
                        </div>
                        <div className="ml-6">
                            <h2 className="text-xl font-bold text-gray-800">{this.state.info.fullname}</h2>
                            <h2 className="text-lg font-bold text-gray-800">{this.state.info.email}</h2>
                            <p className="text-gray-600 font-semibold">Session : <span className='font-medium'>{this.state.info.session_name}</span></p>
                            <p className="text-gray-600 font-semibold">Year : <span className='font-medium'>{this.state.info.session_year}</span></p>
                            <p className="text-gray-600 font-semibold">Langue : <span className='font-medium'>{this.state.info.langue}</span></p>
                            <p className="text-gray-600 font-semibold">Registration fees : <span className='font-medium'>{this.state.info.typeStudent ? '3000 DA' : '4000 DA'}</span></p>
                        </div>
                        <div className="ml-4 pl-4 border-slate-500 text-slate-700  ">
                            <label className="text-md">Chosen times</label>
                            <ul className=" mt-2 ml-2  list-decimal">
                                {this.state.info.times &&
                                    this.state.info.times.map((option, index) => {
                                        return (
                                            <li key={index} className="mb-1 text-xs  list-decimal">
                                                {optionsTime[option - 1].label}
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="border-t border-gray-200 pt-2 mt-4">
                        <h3 className="text-md font-bold mb-2 text-red-600">Important*</h3>
                        <h4 className='text-md font-bold text-gray-600 pb-1'>Students are required to provide the following documents:</h4>
                        <ul className='text-xs  text-gray-500 list-decimal'>
                            <li className='ml-5'>the registration voucher (Print it by the button above or check your email)</li>
                            <li className='ml-5'>02 Photos</li>
                            <li className='ml-5'>A copy of the recent registration certificat</li>
                            <li className='ml-5'>A copy of the identity card.</li>
                            <li className='ml-5'>Receipt of the registration fees</li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}




