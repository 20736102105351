import React from 'react';

const Header = ({title,subtitle,theme='dark'}) =>{


    return (
        <div className={`${theme=='dark' ? "text-white":"text-gray-800"}`}>
            <h2 className="font-bold m-2 ml-0 text-3xl mb-5 ">{title}</h2>
            <p className="font-semibold m-2 ml-0 text-lg mb-5">{subtitle}</p>
        </div>
       

    )
}

export default Header