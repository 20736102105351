import React from 'react'

export default  Notification = (props) => {

    const type=props.info.type
    var classN=''
    var classIcon=''

    switch (type) {
        
        case 'suc':
            classN=' bg-green-800' 
            classIcon=' fa-check-circle' 

        break;
        case 'err':
            classN=' bg-red-800'       
            classIcon=' fa-exclamation-triangle' 

        break;
        case 'rem':
            classN=' bg-yellow-800'       
            classIcon=' fa-trash-alt' 

        break;
        default:
            break;

    } 

    return ( 
        <div className={`fixed flex items-center top-[85px] right-[20px] rounded-lg p-4 text-white ${classN} animate-message opacity-0`}>
            <label style={{paddingRight: '10px',margin: 0,color:'white'}}><i className={`fas ${classIcon }`}></i></label>
            <span>{props.info.message}</span>
        </div>
    )
}
